import { memo } from "react";

import { motion } from "framer-motion";

import * as Styled from './dashboardStyles';

// Components
export const LoadingCard = memo(() => (
  <motion.div
    initial={{ opacity: 0, scale: 0.9 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.9 }}
  >
    <Styled.AssetCard className="loading">
      <Styled.LoadingPulse />
      <Styled.AssetHeader>
        <Styled.AssetSymbol>Loading...</Styled.AssetSymbol>
      </Styled.AssetHeader>
      <Styled.AssetPrice>---.--</Styled.AssetPrice>
    </Styled.AssetCard>
  </motion.div>
));

LoadingCard.displayName = 'LoadingCard';