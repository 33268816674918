import styled from 'styled-components';

export const GemHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: #ffffff;
  border: 1px solid black;
  border-radius: 6px;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

export const GemDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 
    "name price"
    "infoStart info";
  gap: 0.5rem 1rem;
  align-items: baseline;
  margin-bottom: 1rem;
`;

export const GemInfoContainerEnd = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const GemInfoContainerStart = styled.div`
  grid-area: infoStart;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const GemName = styled.h1`
  grid-area: name;
  font-size: 2rem;
  font-weight: 700;
  color: #1f2937;
  margin: 0;
`;

export const GemSymbol = styled.span`
  grid-area: symbol;
  font-size: 1.25rem;
  font-weight: 600;
  color: #6b7280;
  text-transform: uppercase;
`;

export const LastUpdated = styled.p`
  grid-area: lastUpdated;
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
`;

export const GemPrice = styled.p`
  grid-area: price;
  font-size: 2.5rem;
  font-weight: 700;
  color: #059669;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0;
  justify-self: end;
`;

export const GemCreator = styled.p`
  grid-area: creator;
  font-size: 0.875rem;
  color: #6b7280;
  font-style: italic;
  margin: 0;
`;

export const GemStatLabel = styled.p`
  grid-area: watchers;
  font-size: 0.875rem;
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  justify-self: end;
`;

export const GemStatValue = styled.span`
  font-size: 1rem;
  font-weight: 600;
  color: #4b5563;
`;

export const VotingSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;

export const VoteBox = styled.div<{ score: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: ${props => props.score > 0 ? 
    props.theme.colors.score.positive : props.score < 0 ? 
    props.theme.colors.score.negative : props.theme.colors.score.neutral};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    padding: 0.75rem;
  }
`;

export const VoteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #4b5563;
  transition: color 0.2s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
 
  &:hover {
    color: #1f2937;
    transform: scale(1.05);
  }
`;

export const VoteCount = styled.span`
  font-size: 1rem;
  font-weight: 600;
`;

export const ScoreDisplay = styled.div<{ score: number }>`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${props => props.score > 0 ? '#059669' : props.score < 0 ? '#dc2626' : '#4b5563'};
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 1rem;
  margin-bottom: 0.5rem;
`;