import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { useInfoPopup } from '../../hooks/useInfoPopup';
import { useNavigate } from 'react-router-dom';
import { Gem, UserAction, WatchlistItem } from './types';

import { voteGem } from '../../api/gemApi';
import { deleteWatchlist, removeFromWatchlist } from '../../api/watchlistApi';
import { unfollowAllUsers } from '../../api/followingApi';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { fetchUserWatchlist } from '../../api/watchlistApi';

import { formatDate } from './utils';
import { renderTopPrediction } from './components/topPrediction';
import { renderExpectedReturn } from './components/expectedReturn';
import { renderAveragePrediction } from './components/averagePrediction';
import { updateWatchedGem } from './utils/updates';
import { ExtendSidebarButton, SidebarButtons, SidebarContainer } from './components/sidebarStyles';
import WatchlistSkeleton from './watchlist/WatchlistSkeleton';
import ScrollToTopButton from '../../components/common/button/ScrollToTopButton';
import { useGemDataStream } from '../../hooks/useGemDataStream';

import * as Styled from './discoveryPageStyles';

import InfoPopup from '../../components/common/popup/InfoPopup';
import UserActions from './UserActions';
import Following from './Following';
import SimpleHeader from '../../components/simpleHeader/SimpleHeader';
import NoProUserView from './NoProUserView';
import { GemCardSkeleton } from './components/GemCardSkeleton';

const Watchlist = React.lazy(() => import('./watchlist/Watchlist'));
const AddNewGemModal = React.lazy(() => import('./AddNewGemModal'));



const DiscoveryPage: React.FC = () => {
  const { user, authState, following: globalFollowing, setFollowing } = useAuth();
  const { t } = useTranslation();
  const { popups, showErrorPopup, showInfoPopup } = useInfoPopup();
  const { gems, setGems, getDataFromStream } = useGemDataStream();
  
  const navigate = useNavigate();

  const [watchlist, setWatchlist] = useState<WatchlistItem[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isWatchlistLoading, setIsWatchlistLoading] = useState(false);
  const [isWatchActionInProgress, setIsWatchActionInProgress] = useState(false);

  const [localFollowing, setLocalFollowing] = useState<any[]>([]);
  const [actions, setActions] = useState<Omit<UserAction, 'userId'>[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState(() => {
    return localStorage.getItem('gemsFilterPreference') || 'all';
  });
  const filterOptions = ['crypto', 'stock', 'meme-coin', 'etf', 'index', 'fund'];
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('gemsSortPreference') || 'score';
  });

  const [isAddGemModalOpen, setIsAddGemModalOpen] = useState(false);
  
  const [showFollowing, setShowFollowing] = useState<boolean>(() => {
    const stored = localStorage.getItem('showFollowing');
    return stored ? stored === 'true' : true;
  });
  
  const [showUserActions, setShowUserActions] = useState<boolean>(() => {
    const stored = localStorage.getItem('showUserActions');
    return stored ? stored === 'true' : true;
  });
  const [showFirst, setShowFirst] = useState(localStorage.getItem('showFirst') === 'true');



  const handleShowFirstToggle = (newShowFirst: boolean) => {
    setShowFirst(newShowFirst);
    localStorage.setItem('showFirst', newShowFirst.toString());
  };



  const fetchData = useCallback(async () => {
    if (!authState?.isAuthenticated) {
      setIsLoading(false);
      return;
    }
  
    setIsLoading(true);
    try {
      await getDataFromStream();
    } catch (error) {
      console.error('Error fetching data:', error);
      showErrorPopup(t('discovery.errorFetchingData'));
    } finally {
      setIsLoading(false);
    }
  }, [authState, getDataFromStream, showErrorPopup, t]);
  


  useEffect(() => {
    fetchData();
  }, [fetchData]);
  


  useEffect(() => {
    const fetchWatchlist = async () => {
      if (user && gems.length > 0) {
        setIsWatchlistLoading(true);
        try {
          const watchlistResponse = await fetchUserWatchlist(user.id);
          if (watchlistResponse && Array.isArray(watchlistResponse.gems)) {
            const watchlistItems = watchlistResponse.gems.map((item: { gemId: string }) => {
              const gem = gems.find(g => g.id === item.gemId);
              return { id: item.gemId, symbol: gem ? gem.symbol : '' };
            });
            setWatchlist(watchlistItems);
          }
        } catch (error) {
          console.error('Error fetching watchlist:', error);
          showErrorPopup(t('discovery.errorFetchingWatchlist'));
        } finally {
          setIsWatchlistLoading(false);
        }
      }
    };
  
    fetchWatchlist();
  }, [user, gems, showErrorPopup, setIsWatchlistLoading, t]);



  useEffect(() => {
    fetchData();
  }, [fetchData]);



  useEffect(() => {
    if (user && globalFollowing) {
      setLocalFollowing(globalFollowing);
    }
  }, [user, globalFollowing]);



  const handleLocalUnfollow = useCallback((username: string) => {
    setLocalFollowing(prev => prev.filter(u => u !== username));
  }, []);



  const onUnfollowAll = async () => {
    if (!user) {
      showErrorPopup(t('following.popup.loginRequired'));
      return;
    }
    try {
      await unfollowAllUsers(user.id);
      setLocalFollowing([]);
      if (user && globalFollowing) setFollowing([]);
      showInfoPopup(t('following.popup.unfollowAllSuccess'));
    } catch (error) {
      console.error('Error unfollowing all users:', error);
      showErrorPopup(t('following.popup.unfollowError'));
    }
  };



  useEffect(() => {
    localStorage.setItem('gemsSortPreference', sortBy);
    localStorage.setItem('gemsFilterPreference', filterType);
  }, [sortBy, filterType]);



  const handleVote = async (assetId: string, voteType: 'positive' | 'negative') => {
    if (!user) {
      showErrorPopup(t('discovery.loginToVote'));
      return;
    }
    try {
      const voteTypeValue = voteType === 'positive' ? 1 : voteType === 'negative' ? -1 : 0;
      await voteGem(user.id, assetId, voteTypeValue);
      showInfoPopup(t('discovery.popup.voteRegistered'));
      
      // Optionally, update the local state to reflect the new vote
      setGems(prevGems => prevGems.map(gem => 
        gem.id === assetId 
          ? {...gem, votes: {...gem.votes, [voteType]: gem.votes[voteType] + 1}} 
          : gem
      ));
    } catch (error) {
      if (error instanceof Error && error.message.includes('already voted')) {
        showErrorPopup(t('discovery.popup.alreadyVoted'));
      } else {
        showErrorPopup(t('portfolio.popup.refreshError'));
      }
    }
  };



  const handleWatch = async (gem: Gem) => {
    if (!user) {
      showErrorPopup(t('discovery.popup.loginToWatch'));
      return;
    }

    if (isWatchActionInProgress) {
      return;

    } else {
      setIsWatchActionInProgress(true);
      try {
        updateWatchedGem(user.id, watchlist, setWatchlist, setGems, gem, t, showInfoPopup, showErrorPopup);
      } catch (error) {
        console.error('Error updating watched gem.');
      } finally {
        setTimeout(() => {
          setIsWatchActionInProgress(false);
        }, 1000); // 1 second delay
      }
    }
  };



  const handleRemoveFromWatchlist = async (gemId: string) => {
    if (!user) {
      showErrorPopup(t('discovery.popup.loginToWatch'));
      return;
    }
    try {
      await removeFromWatchlist(user.id, gemId);
      setWatchlist(watchlist.filter(item => item.id !== gemId));
      setGems(prevGems => prevGems.map(gem => 
        gem.id === gemId ? { ...gem, watchers: (gem.watchers || 0) - 1 } : gem
      ));
      showInfoPopup(t('discovery.popup.watchlist.updated'));
    } catch (error) {
      console.error('Error updating watchlist.');
      showErrorPopup(t('discovery.errorUpdatingWatchlist'));
    }
  };



  const handleClearWatchlist = useCallback(async () => {
    if (user) {
      try {
        const watchedGemIds = watchlist.map(item => item.id);
        await deleteWatchlist(user.id);
        setWatchlist([]);

        setGems(prevGems => prevGems.map(gem => {
          if (watchedGemIds.includes(gem.id)) {
            return { ...gem, watchers: Math.max((gem.watchers || 0) - 1, 0) };
          }
          return gem;
        }));

      } catch (error) {
        console.error('Error clearing watchlist.');
        showErrorPopup(t('discovery.errorClearingWatchlist'));
      }
    }
  }, [user, watchlist, showErrorPopup, t, setGems]);



  const handleAddNewGem = () => {
    setIsAddGemModalOpen(true);
  };



  const handleGemAdded = (newGem: Gem) => {
    setGems((prevGems) => [...prevGems, newGem]);
    showInfoPopup(t('discovery.popup.gemAdded'));
  };



  const handleGemClick = (gem: { id: string }) => {
    navigate(`/gems/${gem.id}`);
  };



  const sortedGems = useMemo(() => {
    let sortedArray = [...gems];
    switch (sortBy) {
      case 'score':
        sortedArray.sort((a, b) => (b.score - a.score));
        break;
      case 'votes':
        sortedArray.sort((a, b) => (b.totalVotes - a.totalVotes));
        break;
      case 'return':
        sortedArray.sort((a, b) => {
          const expectedReturnA = a.topVotedPrediction?.value ? a.topVotedPrediction.value / a.price : 0;
          const expectedReturnB = b.topVotedPrediction?.value ? b.topVotedPrediction.value / b.price : 0;
          return expectedReturnB - expectedReturnA;
        });
        break;
      case 'watchers':
        sortedArray.sort((a, b) => (b.watchers || 0) - (a.watchers || 0));
        break;
      case 'price':
        sortedArray.sort((a, b) => (b.price - a.price));
        break;
      default:
        break;
    }

    if (searchTerm) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      sortedArray = sortedArray.filter(gem => 
        gem.name.toLowerCase().includes(lowercasedSearchTerm) ||
        gem.symbol.toLowerCase().includes(lowercasedSearchTerm) ||
        gem.tags.some(tag => tag.toLowerCase().includes(lowercasedSearchTerm))
      );
    }

    if (showFirst) {
      const watchedGems = sortedArray.filter(gem => watchlist.some(item => item.id === gem.id));
      const unwatchedGems = sortedArray.filter(gem => !watchlist.some(item => item.id === gem.id));
      sortedArray = [...watchedGems, ...unwatchedGems];
    }

    return sortedArray;
  }, [gems, sortBy, searchTerm, showFirst, watchlist]);



  return (
    <Styled.PageContainer>
      <SimpleHeader dashboard={false} extraMargin={false} />
      {authState?.isAuthenticated ? (
        <>
          <Styled.Header>
            {user && user.isPro &&
              <Styled.ControlsContainer>

                <Styled.MainControls>
                  <Styled.AddNewGemButton onClick={handleAddNewGem}>
                    {t('discovery.addGem.title')}
                  </Styled.AddNewGemButton>
                  <Styled.SearchInput 
                    type="text"
                    placeholder={t('discovery.search')} 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Styled.MainControls>

                <Styled.RowContainer>
                  <Styled.SortSelect value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
                    <option value="score">{t('discovery.sort.score')}</option>
                    <option value="votes">{t('discovery.sort.votes')}</option>
                    <option value="return">{t('discovery.sort.return')}</option>
                    <option value="watchers">{t('discovery.sort.watchers')}</option>
                    <option value="price">{t('discovery.sort.price')}</option>
                  </Styled.SortSelect>

                  <Styled.FilterSelect value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                    <option value="all">{t('discovery.filter.all')}</option>
                    <option value="crypto">{t('discovery.filter.crypto')}</option>
                    <option value="stock">{t('discovery.filter.stock')}</option>
                    <option value="meme-coin">{t('discovery.filter.meme-coin')}</option>
                    <option value="proxy">{t('discovery.filter.proxy')}</option>
                    <option value="index">{t('discovery.filter.index')}</option>
                    <option value="fund">{t('discovery.filter.fund')}</option>
                    <option value="other">{t('discovery.filter.other')}</option>
                  </Styled.FilterSelect>
                </Styled.RowContainer>

              </Styled.ControlsContainer>
            } 
          </Styled.Header>

          <Styled.MainContent>
            {user && user.isPro ? (
              isLoading ? 
                <Styled.GemsGrid>
                  {[...Array(9)].map((_, index) => (
                    <GemCardSkeleton key={index} />
                  ))}
                </Styled.GemsGrid>
                :
                <Styled.GemsGrid>
                  {sortedGems
                    .filter(gem => {
                      if (filterType === 'all') return true;
                      if (filterType === 'other') return !gem.tags.some(tag => filterOptions.includes(tag));
                      if (filterType === 'proxy') return gem.tags.some(tag => tag.includes('proxy'));
                      return gem.tags.includes(filterType);
                    })
                    .map((gem, index) => (
                    <Styled.GemCard key={gem.id} iswatched={watchlist.some(item => item.id === gem.id)}>

                      <Styled.GemCardHeader onClick={() => handleGemClick(gem)}>
                        <Styled.GemRank>#{index + 1}</Styled.GemRank>
                        <Styled.GemName>{gem.name}</Styled.GemName>
                        <Styled.GemSymbol>{gem.symbol}</Styled.GemSymbol>
                      </Styled.GemCardHeader>

                      <Styled.GemCardBody>

                        <Styled.VotingSection>
                          <Styled.VoteButton onClick={(e) => { e.stopPropagation(); handleVote(gem.id, 'positive'); }}>
                            <Styled.VoteIcon>👍</Styled.VoteIcon>
                            <Styled.VoteCount>{gem.votes?.positive}</Styled.VoteCount>
                          </Styled.VoteButton>
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: gem.score > 0 ? '#e6ffe6' : gem.score < 0 ? '#ffe6e6' : '#f0f0f0',
                            padding: '4px 10px',
                            borderRadius: '8px',
                            transition: 'all 0.3s ease'
                          }}>
                            <span style={{ 
                              marginRight: '6px', 
                              fontWeight: 'bold', 
                              color: gem.score > 0 ? '#008000' : gem.score < 0 ? '#ff0000' : '#ffa500'
                            }}>
                              Score:
                            </span>
                            <span style={{
                              fontSize: '1.1em',
                              fontWeight: 'bold',
                              color: gem.score > 0 ? '#008000' : gem.score < 0 ? '#ff0000' : '#ffa500'
                            }}>
                              {gem.score}
                            </span>
                            <span style={{ marginLeft: '6px', fontSize: '1.2em' }}>
                              {gem.score > 0 ? '✨' : gem.score < 0 ? '🔻' : '😐'}
                            </span>
                          </div>
                          <Styled.VoteButton onClick={(e) => { e.stopPropagation(); handleVote(gem.id, 'negative'); }}>
                            <Styled.VoteIcon>👎</Styled.VoteIcon>
                            <Styled.VoteCount>{gem.votes?.negative}</Styled.VoteCount>
                          </Styled.VoteButton>
                        </Styled.VotingSection>

                        {watchlist.some(item => item.id === gem.id) ? (
                          <>
                            {renderTopPrediction(t, isLoading, gem)}
                            {renderExpectedReturn(t, gem)}
                            {renderAveragePrediction(t, gem)}
                          </>
                        ) : (
                          <Styled.GemNavigateContainer>
                            <Styled.GemCardNavigate onClick={() => handleGemClick(gem)}>
                              {t('discovery.gem.goToGem')}
                            </Styled.GemCardNavigate>
                            <Styled.WatchToShowPrediction onClick={() => handleWatch(gem)}>
                              <Styled.WatchIcon>👁️</Styled.WatchIcon>
                              <Styled.WatchText>{t('discovery.gem.watchToShow')}</Styled.WatchText>
                            </Styled.WatchToShowPrediction>
                          </Styled.GemNavigateContainer>
                        )}

                        <Styled.TagSection>
                          {gem.tags && gem.tags.map(tag => (
                            <Styled.Tag key={tag}>{tag}</Styled.Tag>
                          ))}
                        </Styled.TagSection>
                      </Styled.GemCardBody>
 
                      <Styled.GemCardFooter>
                        <Styled.CreatedAt>
                          {t('discovery.created')}: {gem.createdAt ? 
                            formatDate(gem.createdAt, 'createdAt') 
                            : 'N/A'}
                        </Styled.CreatedAt>
                        <Styled.WatchButton 
                          onClick={(e) => { e.stopPropagation(); handleWatch(gem); }}
                          iswatched={String(!!watchlist.find(item => item.id === gem.id))}
                          disabled={isWatchActionInProgress}
                        >
                          {watchlist.find(item => item.id === gem.id) ? t('discovery.unwatch') : t('discovery.watch')} ({gem.watchers || 0})
                        </Styled.WatchButton>
                      </Styled.GemCardFooter>
                    </Styled.GemCard>
                  ))}
                </Styled.GemsGrid>
            ) : (
              <NoProUserView />
            )}

            <SidebarContainer>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {user && user.isPro && (
                  <React.Suspense fallback={<WatchlistSkeleton />}>
                    {isWatchlistLoading ? (
                      <WatchlistSkeleton />
                    ) : (
                      <Watchlist 
                        watchlist={watchlist} 
                        onRemove={handleRemoveFromWatchlist}
                        onClear={handleClearWatchlist}
                        showFirst={showFirst}
                        onShowFirstToggle={handleShowFirstToggle}
                        isLoading={isLoading}
                      />
                    )}
                  </React.Suspense>
                )}

                {user && user.isPro && (
                  <SidebarButtons>
                    <ExtendSidebarButton onClick={() => {
                      setShowFollowing(prevState => {
                        const newState = !prevState;
                        localStorage.setItem('showFollowing', newState.toString());
                        return newState;
                      });
                    }}>
                      {showFollowing ? 'Hide Following' : 'Show Following'}
                    </ExtendSidebarButton>
                    <ExtendSidebarButton onClick={() => {
                      setShowUserActions(prevState => {
                        const newState = !prevState;
                        localStorage.setItem('showUserActions', newState.toString());
                        return newState;
                      });
                    }}>
                      {showUserActions ? 'Hide User Actions' : 'Show User Actions'}
                    </ExtendSidebarButton>
                  </SidebarButtons>
                )}

                {user && user.isPro && (
                  <Styled.InfoListContainer>
                    {showFollowing && (
                      <Following 
                        onUnfollowAll={onUnfollowAll}
                        isLoading={isLoading}
                        localFollowing={localFollowing}
                        onLocalUnfollow={handleLocalUnfollow}
                      />
                    )}

                    {showUserActions && (
                      <UserActions 
                        actions={actions} 
                        setActions={setActions}  
                        collapsed={localStorage.getItem('ownActionsCollapsed') === 'true'}
                      />
                    )}
                  </Styled.InfoListContainer>
                )}

                <ScrollToTopButton />
              </div>
            </SidebarContainer>

          </Styled.MainContent>

          {popups.map((popup) => (
            <InfoPopup
              key={popup.id}
              message={popup.message}
              type={popup.type}
              position={popup.position}
              timeout={popup.duration}
            />
          ))}
          {isAddGemModalOpen && (
            <React.Suspense fallback={<></>}>
              <AddNewGemModal
                isOpen={isAddGemModalOpen}
                onClose={() => setIsAddGemModalOpen(false)}
                onGemAdded={handleGemAdded}
              />
            </React.Suspense>
          )}
          {process.env.NODE_ENV !== 'development' && (
            <>
              <Analytics />
              <SpeedInsights />
            </>
          )}
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <div style={{ fontSize: '48px', marginBottom: '20px' }}>👀</div>
          <p style={{ fontSize: '24px', textAlign: 'center' }}>
            {t('discovery.noProUser.text')}
            <br />
            {t('discovery.noProUser.upgrade')}
          </p>
        </div>
      )}
    </Styled.PageContainer>
  );
};



export default DiscoveryPage;