import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { createPortal } from 'react-dom';



const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
`;



const ModalContainer = styled.div`
  position: fixed;
  top: 1.75rem;
  right: 1.75rem;
  z-index: 9999;
  animation: ${fadeIn} 0.1s ease-out;
  border: 1px solid black;
  border-radius: 6px;
`;



const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;



interface ModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}



const ModalWrapper: React.FC<ModalWrapperProps> = ({ isOpen, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);


  
  if (!isOpen) return null;



  return createPortal(
    <ModalContainer>
      <ModalContent ref={modalRef}>
        {children}
      </ModalContent>
    </ModalContainer>,
    document.body
  );
};



export default ModalWrapper;