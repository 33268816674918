import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const pulseAnimation = keyframes`
  0% { transform: scale(0.95); opacity: 0.5; }
  50% { transform: scale(1); opacity: 0.8; }
  100% { transform: scale(0.95); opacity: 0.5; }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1200px;
  padding: 2rem 2rem 8rem;
  background: #ffffff;
  position: relative;
  
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 20%, rgba(255, 0, 0, 0.05) 0%, transparent 50%),
      radial-gradient(circle at 80% 80%, rgba(0, 0, 0, 0.05) 0%, transparent 50%);
    pointer-events: none;
    z-index: 0;
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const DashboardTitle = styled.h1`
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  margin: 2rem 0;
  color: #000000;
  position: relative;
  letter-spacing: -1px;
  text-transform: uppercase;
`;

export const TitleAccent = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: #ff0000;
  border-radius: 2px;
`;

export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  width: 100%;
  z-index: 1;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const GemsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 1.5rem;
  position: relative;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

export const CategorySection = styled.div`
  background: ${theme.colors.background.paper};
  border-radius: 6px;
  padding: 2rem;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(255, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const CategoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
`;

export const CategoryTitle = styled.h2`
  font-size: 2rem;
  font-weight: 900;
  margin: 0;
  color: #000000;
  position: relative;
  text-transform: uppercase;
  letter-spacing: -0.5px;
`;

export const CategoryUnderline = styled.div`
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #ff0000;
  transform-origin: left;
  transform: scaleX(0.3);
  transition: transform 0.3s ease;
  
  ${CategoryTitle}:hover & {
    transform: scaleX(1);
  }
`;

export const RefreshButton = styled.button`
  background: #ffffff;
  border: 2px solid #ff0000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 0, 0, 0.1);
    transform: scale(0);
    transition: transform 0.3s ease;
    border-radius: 50%;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #ff0000;
    transition: all 0.3s ease;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.2);
    
    &::before {
      transform: scale(1);
    }
    
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    border-color: #cccccc;
    
    svg {
      color: #cccccc;
    }
  }

  &.spinning svg {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;

export const AssetGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  position: relative;
`;

export const AssetCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  padding: 1.5rem;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background: #ff0000;
    transform: scaleY(0);
    transition: transform 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.01),
      0 5px 15px rgba(0, 0, 0, 0.02);
    
    &::before {
      transform: scaleY(1);
    }
  }

  &.loading {
    animation: ${pulseAnimation} 2s ease-in-out infinite;
  }

  &.error {
    border-color: #ff0000;
    background: rgba(255, 0, 0, 0.05);
  }
`;

export const AssetGlow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at 50% 50%, rgba(255, 0, 0, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;

  ${AssetCard}:hover & {
    opacity: 1;
  }
`;

export const LoadingPulse = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );
  animation: shimmer 1.5s infinite;
  
  @keyframes shimmer {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(100%); }
  }
`;

export const AssetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  position: relative;
`;

export const AssetSymbol = styled.h3`
  font-size: 1.5rem;
  font-weight: 900;
  margin: 0;
  color: #000000;
  letter-spacing: -0.5px;
  position: relative;
  
  &.error {
    color: #ff0000;
  }
`;

export const AssetPrice = styled.div`
  font-size: 2rem;
  font-weight: 700;
  color: #000000;
  margin: 0;
  position: relative;
  
  &::before {
    font-size: 1.2rem;
    vertical-align: top;
    margin-right: 2px;
    opacity: 0.7;
  }

  &.error {
    color: #ff0000;
    font-size: 1rem;
  }
`;

export const RetryButton = styled.button`
  background: #ff0000;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  
  &:hover {
    background: #cc0000;
    transform: translateY(-2px);
  }
`;

export const LastUpdated = styled.span`
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: -20px;
  right: 0;
  font-weight: 500;
`;