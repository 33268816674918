import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

export const ModalTitle = styled.h2`
  color: #e74c3c;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(231, 76, 60, 0.1);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  
  @media (max-width: 768px) {
    gap: 0.5rem;
    flex-wrap: nowrap;
  }
`;

export const UserInfoContainer = styled.div`
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
  &:focus {
    outline: none;
    border-color: #e74c3c;
    box-shadow: 0 0 0 1px rgba(231, 76, 60, 0.2);
  }
`;

export const Button = styled.button`
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 1rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s ease;
  width: 100%;
  margin-top: 0.5rem;

  &:hover {
    background-color: #c0392b;
  }

  &:disabled {
    background-color: #e57373;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    white-space: nowrap;
  }
`;

export const SwitchButton = styled.button`
  background: none;
  border: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #e74c3c;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  &:disabled {
    color: #e57373;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.p`
  color: #e74c3c;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 5px;
`;

export const SuccessMessage = styled(ErrorMessage)`
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.1);
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled(FaSpinner)`
  animation: ${spin} 0.5s linear infinite;
  font-size: 1.2rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
  background: none;
  border: none;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.primaryRed};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: #e74c3c;
  }

  @media (max-width: 768px) {
    border-radius: 6px;
  }
`;

export const LoggedInContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const ForgotPasswordLink = styled.button`
  background: none;
  border: none;
  color: #999999;
  cursor: pointer;
  font-size: 0.9rem;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    background-color: #999999;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #e74c3c;
  }

  &:hover::after {
    transform: scaleX(1);
    background-color: #e74c3c;
  }
`;