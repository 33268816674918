import { styled } from "styled-components";

export const FooterWrapper = styled.footer`
  background-color: ${props => `${props.theme.colors.background.paper}CC`};
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: black;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem 0;

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const CompanyInfoSection = styled(FooterSection)`
  @media (min-width: 768px) {
    flex: 1;
    justify-content: flex-start;
  }
`;

export const LinksSection = styled(FooterSection)`
  @media (min-width: 768px) {
    flex: 1;
    justify-content: center;
  }
`;

export const LanguageSection = styled(FooterSection)`
  @media (min-width: 768px) {
    flex: 1;
    justify-content: flex-end;
  }
`;

export const FooterLink = styled.a`
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 6px;
  padding: 0.2rem 0.5rem;
 
  &:hover, &:focus {
    color: red;
    background-color: rgba(255, 192, 203, 0.5);
  }
`;

export const LanguageSelect = styled.select`
  background-color: #FFFFFF;
  border: 1px solid #000000;
  color: #000000;
  padding: 0.25rem;
  font-size: 0.85rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #E0E0E0;
  }
`;

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;