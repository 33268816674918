import { memo } from "react";

import { motion } from "framer-motion";

import * as Styled from './dashboardStyles';

export const ErrorCard = memo(({ error, onRetry }: { error: string; onRetry: () => void }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    <Styled.AssetCard className="error">
      <Styled.AssetHeader>
        <Styled.AssetSymbol className="error">Error</Styled.AssetSymbol>
      </Styled.AssetHeader>
      <Styled.AssetPrice className="error">
        {error}
        <Styled.RetryButton onClick={onRetry}>
          Retry
        </Styled.RetryButton>
      </Styled.AssetPrice>
    </Styled.AssetCard>
  </motion.div>
));

ErrorCard.displayName = 'ErrorCard';