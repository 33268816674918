import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en.json';
import nbTranslations from './locales/nb.json';

const savedLanguage = localStorage.getItem('language');
const browserLanguage = navigator.language.startsWith('nb') ? 'nb' : 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      nb: { translation: nbTranslations },
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'nb'],
    lng: savedLanguage || browserLanguage,
    detection: {
      order: ['localStorage', 'navigator'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;