import React from 'react';
import { ThemeProvider } from 'styled-components';
import App from './App';
import theme from './styles/theme';
import GlobalStyle from './styles/globalStyles';
import { createRoot } from 'react-dom/client';



const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');



document.addEventListener('scroll', () => {
  const scrollPosition = window.scrollY;
  const pageHeight = document.documentElement.scrollHeight - window.innerHeight;
  const wrapper = document.querySelector('.page-wrapper');

  if (wrapper instanceof HTMLElement) {
    if (scrollPosition > pageHeight - 100) {
      wrapper.classList.add('show-effect');
    } else {
      wrapper.classList.remove('show-effect');
    }
  }
});



const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);