import apiService from "./baseApi";



// POST add to waitlist
export const addToWaitlist = async (email: string): Promise<number> => {
  try {
    await apiService.post(`/waitlist/add`, {
      email
    });
    return 200;
  } catch (error) {
    console.error('Error adding to waitlist:', error);
    throw error;
  }
};