import { Gem } from "../types";

import { PredictionSection, PredictionLabel, PredictionValue } from '../discoveryPageStyles';

export const renderAveragePrediction = (t: any, asset: Gem) => {
  if (asset.averagePrediction !== undefined && asset.averagePrediction !== 0 && 
    asset.topVotedPrediction && asset.topVotedPrediction.value !== 0) {
    return (
      <PredictionSection style={{ marginBottom: '0.5rem' }}>
        <PredictionLabel>{t('discovery.avgPrediction')}:</PredictionLabel>
        <PredictionValue>
          ${asset.averagePrediction.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </PredictionValue>
      </PredictionSection>
    );
  }

  return <div style={{ height: '2rem' }} aria-hidden="true"></div>;
};