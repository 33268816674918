import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext';
import { UserAction } from './types';
import { fetchUserActions } from '../../api/userActionsApi';
import { ChevronUp, ChevronDown, RefreshCw } from 'lucide-react';

import * as Styled from './userActionsStyles';
import { SpinnerContainer, SpinnerRing } from '../../components/success/successStyles';

interface UserActionsProps {  
  actions: Omit<UserAction, 'userId'>[];
  setActions: React.Dispatch<React.SetStateAction<Omit<UserAction, 'userId'>[]>>;
  selectedUser?: string;
  source?: string;
  handleCloseModal?: () => void;
  collapsed: boolean;
}

const UserActions: React.FC<UserActionsProps> = ({ 
  actions, 
  setActions, 
  selectedUser, 
  source, 
  handleCloseModal,
  collapsed
}) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(collapsed);



  const loadUserActions = useCallback(async () => {
    if (!user?.id) return;
    setLoading(true);
    try {
      const userActions = await fetchUserActions(selectedUser || user.id);
      setActions(userActions);
    } catch (error) {
      console.error('Error fetching user actions:', error);
    } finally {
      setLoading(false);
    }
  }, [user, setActions, selectedUser]);



  useEffect(() => {
    loadUserActions();
  }, [loadUserActions]);



  const renderActionContent = (action: Omit<UserAction, 'userId'>) => {
    switch (action.actionType) {
      case 'addedNewGem':
        return t('userActions.addedNewGem', { gemId: action.gemId || '' });
      case 'watchedGem':
        return t('userActions.watchedGem', { gemId: action.gemId || '' });
      case 'deletedWatchlist':
        return t('userActions.deletedWatchlist');
      case 'removedFromWatchlist':
        return t('userActions.removedFromWatchlist', { gemId: action.gemId || '' });
      case 'followedUser':
        return t('userActions.followedUser', { username: action.username || '' });
      case 'unfollowedUser':
        return t('userActions.unfollowedUser', { username: action.username || '' });
      case 'unfollowedAllUsers':
        return t('userActions.unfollowedAllUsers');
      case 'gemVote':
        return t('userActions.gemVote', { voteType: action.voteType === 1 ? t('userActions.voted.up') : t('userActions.voted.down'), gemId: action.gemId || '' });
      case 'addedNewPrediction':
        return t('userActions.addedNewPrediction', { gemId: action.gemId || '', gemValue: action.value || 0 });
      case 'votedPrediction':
        return t('userActions.votedPrediction', { voteType: action.voteType === 1 ? t('userActions.voted.up') : t('userActions.voted.down'), gemId: action.gemId || '' });
      default:
        return t('userActions.unknownAction');
    }
  };



  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem('ownActionsCollapsed', JSON.stringify(!isCollapsed));
  };



  const handleRefresh = () => {
    loadUserActions();
  };



  return (
    <Styled.UserActionsContainer>

      <Styled.UserActionsHeader>
        <Styled.UserActionsTitle>{t('userActions.title')}</Styled.UserActionsTitle>
        <Styled.HeaderControls>
          {source === 'following' ? (
            <Styled.CloseButton onClick={handleCloseModal}>&times;</Styled.CloseButton>
          ) : (
            <>
              <Styled.RefreshButton onClick={handleRefresh} disabled={loading}>
                {!loading && <RefreshCw size={16} />}
              </Styled.RefreshButton>
              <Styled.CollapseButton onClick={toggleCollapse}>
                {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
              </Styled.CollapseButton>
            </>
          )}
        </Styled.HeaderControls>
      </Styled.UserActionsHeader>

      {!isCollapsed && (
        loading ? (
          <SpinnerContainer>
            <SpinnerRing />
          </SpinnerContainer>
        ) : actions.length > 0 ? (
          actions.map((action, index) => (
            <Styled.UserActionUnit key={index}>
              <Styled.ActionInfo>
                {renderActionContent(action)}
              </Styled.ActionInfo>
            </Styled.UserActionUnit>
          ))
        ) : (
          <Styled.EmptyActionsMessage>
            {t('userActions.emptyMessage')}
          </Styled.EmptyActionsMessage>
        )
      )}
    </Styled.UserActionsContainer>
  );
};



export default UserActions;