import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const PageContainer = styled.div<{ isempty: string }>`
  background-color: transparent;
  min-height: ${props => props.isempty === 'true' ? '100vh' : ''};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  padding: 1rem;
  box-sizing: border-box;

  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

export const GemHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 6px;
  margin-bottom: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.05), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
  border: 1px solid black;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
  }
`;

export const BackButton = styled(Link)`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #ecfdf5;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  border-radius: 6px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  &:hover {
    background-color: #d1fae5;
  }
`;

export const GemDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    align-items: flex-start;
    width: auto;
  }
`;

export const GemNameSymbolContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;
    align-items: baseline;
  }
`;

export const GemName = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  color: #1e293b;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    text-align: left;
  }
`;

export const GemSymbol = styled.h2`
  font-size: 1rem;
  color: #64748b;
  font-weight: 600;
`;

export const GemPrice = styled.p`
  font-size: 1.25rem;
  font-weight: 700;
  color: black;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const GemStatLabel = styled.p`
  font-size: 0.75rem;
  color: #64748b;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const GemStatValue = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: #1e293b;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const GemSection = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

export const GemChartSection = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SectionTitle = styled.h3`
  font-size: 1.25rem;
  color: #1e293b;
  margin-bottom: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const VotingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    justify-content: flex-end;
    margin-top: 0;
    width: auto;
  }
`;

export const VoteCount = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #64748b;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const VoteBox = styled.div<{ score: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: ${props => props.score > 0 ? '#ecfdf5' : props.score < 0 ? '#fef2f2' : '#f3f4f6'};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    padding: 0.75rem;
  }
`;

export const ScoreDisplay = styled.div<{ score: number }>`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${props => props.score > 0 ? '#059669' : props.score < 0 ? '#dc2626' : '#4b5563'};
  margin: 0 0.5rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin: 0 1rem;
  }
`;

export const VoteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #2d3748;
  transition: color 0.2s, transform 0.2s;

  &:hover {
    color: black;
    transform: scale(1.05);
  }
`;

export const PredictionSummary = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #4a4a4a;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const PredictionSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const PredictionSummaryBox = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 6px;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.01);
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 768px) {
    flex: 1 1 calc(50% - 0.5rem);
    padding: 1.5rem;
  }
`;

export const TopVotedPredictionSummaryBox = styled(PredictionSummaryBox)`
  background-color: ${props => props.theme.colors.lightOrange};
  border: 1px solid ${props => props.theme.colors.primaryOrange};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 180px;

  &::before {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: ${props => props.theme.colors.lightOrange};
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 4px 12px;
    transform: rotate(45deg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(249, 115, 22, 0.2), 0 4px 6px -2px rgba(249, 115, 22, 0.1);
  }
`;

export const NoPredictionsContainer = styled(PredictionSummaryBox)`
  background-color: ${props => props.theme.colors.lightOrange};
  border: 1px solid ${props => props.theme.colors.primaryOrange};
  color: ${props => props.theme.colors.primaryOrangeHover};
  position: relative;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(249, 115, 22, 0.2), 0 4px 6px -2px rgba(249, 115, 22, 0.1);
  }
`;

export const TopVotedPredictionLabel = styled.span`
  color: #9a3412;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const TopVotedPredictionValue = styled.span`
  color: ${props => props.theme.colors.primaryOrange};
  font-size: 2rem;
  font-weight: 700;
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const TopVotedPredictionDate = styled.span`
  color: ${props => props.theme.colors.secondaryOrange};
  font-size: 0.875rem;
  text-align: center;
  margin-top: 0.5rem;
`;

export const TopVotedPredictionUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1rem;
  color: ${props => props.theme.colors.secondaryOrange};
  font-weight: 600;
`;

export const FollowUserButton = styled.button`
  background-color: ${props => props.theme.colors.primaryOrange};
  color: white;
  border: 1px solid black;
  padding: 0.3rem 0.55rem;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${props => props.theme.colors.primaryOrangeHover};
  }
`;

export const AveragePredictionSummaryBox = styled(PredictionSummaryBox)`
  background-color: #f0fdf4;
  border: 1px solid #22c55e;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 180px;

  &::before {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #22c55e;
    color: white;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 4px 12px;
    transform: rotate(45deg);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(34, 197, 94, 0.2), 0 4px 6px -2px rgba(34, 197, 94, 0.1);
  }
`;

export const AveragePredictionLabel = styled.span`
  color: #15803d;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;

export const AveragePredictionValue = styled.span`
  color: #059669;
  font-size: 2rem;
  font-weight: 700;
  margin: 0.75rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const TotalPredictionsCounter = styled.span`
  background-color: #dcfce7;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  align-self: flex-start;

  b {
    color: #15803d;
  }

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const PredictionLabel = styled.span`
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

export const USDValue = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: #ff6b73;
  margin: 0.5rem 0;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

export const PredictionDate = styled.span`
  font-size: 0.75rem;
  color: #64748b;
  margin-top: 0.5rem;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const PredictionUser = styled.span`
  color: #ff6b73;
  font-weight: 700;
  margin-top: 0.5rem;
  font-size: 1.1rem;
`;

export const PredictionGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const PredictionGridItem = styled.div<{ userowned: string }>`
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: ${props => props.userowned === 'true' ? props.theme.colors.lightPink : '#ffffff'};
  box-shadow: ${props => props.userowned === 'true' 
    ? '0 0 8px rgba(255, 105, 180, 0.4)' 
    : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'};
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  }

  @media (min-width: 768px) {
    padding: 1rem;
  }
`;

export const PredictionValueGrid = styled.span`
  font-size: 1rem;
  font-weight: 700;
  color: #ff6b73;
  margin: 0.5rem 0;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const PredictionDates = styled.span`
  font-size: 0.625rem;
  color: #64748b;

  @media (min-width: 768px) {
    font-size: 0.75rem;
  }
`;

export const PredictionUserId = styled.span`
  font-size: 0.8rem;
  color: red;
  margin-top: 0.25rem;
  font-weight: 800;

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }
`;

export const VoteButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const Tag = styled.span`
  background-color: ${props => props.theme.colors.lightPink};
  color: black;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #ff99a3;
  }

  @media (min-width: 768px) {
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0.25rem 0.75rem;
  }
`;

export const CreditedDisplay = styled.span`
  background-color: ${props => props.theme.colors.primaryPinkHover};
  color: white;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-size: 0.875rem;
  margin-left: 0.5rem;
  transition: background-color 0.3s;
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.15s ease-in-out forwards;
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`;