import axios from "axios";
import { UserAction } from "../pages/discovery/types";



const API_URL = process.env.NODE_ENV === 'production' ? 
process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_LOCAL_URL;



export const fetchUserActions = async (userId: string): Promise<Omit<UserAction, 'userId'>[]> => {
  const response = await axios.get<Omit<UserAction, 'userId'>[]>(`${API_URL}/user-actions/${userId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': process.env.REACT_APP_API_KEY
    },
    withCredentials: true
  });

  return response.data;
};