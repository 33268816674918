import { Briefcase, EthernetPort } from "lucide-react";
import styled from "styled-components";


interface TypeToggleProps {
  type: string;
  updateType: (newType: string) => void;
}


const ToggleContainer = styled.div`
  position: relative;
  width: 200px;
  height: 40px;
  background: ${props => props.theme.colors.background.paper};
  border-radius: 6px;
  display: flex;
  padding: 4px;
  cursor: pointer;
  overflow: hidden;
`;


const ToggleBackground = styled.div<{ isRight: boolean }>`
  position: absolute;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  background: ${props => props.theme.colors.primaryPinkHover};
  border-radius: 6px;
  transition: transform 0.3s ease;
  transform: translateX(${props => props.isRight ? 'calc(100%)' : '0'});
`;


const ToggleOption = styled.div<{ isSelected: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1;
  color: ${props => props.isSelected ? 'white' : props.theme.colors.text.primary};
  transition: color 0.3s ease;
`;


const Label = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
`;


export const TypeToggle: React.FC<TypeToggleProps> = ({ type, updateType }) => {
  const handleToggle = () => {
    const newType = type === 'stock' ? 'crypto' : 'stock';
    updateType(newType);
  };


  return (
    <ToggleContainer onClick={handleToggle}>
      <ToggleBackground isRight={type === 'crypto'} />
      <ToggleOption isSelected={type === 'stock'}>
        <Briefcase size={16} />
        <Label>Stock</Label>
      </ToggleOption>
      <ToggleOption isSelected={type === 'crypto'}>
        <EthernetPort size={16} />
        <Label>Crypto</Label>
      </ToggleOption>
    </ToggleContainer>
  );
};


export default TypeToggle;