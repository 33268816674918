import { useState, useEffect } from 'react';
import { Asset } from '../pages/portfolio/types';


const STORAGE_KEY = 'portfolio_data';


interface UsePortfolioStorage {
  portfolioData: Asset[];
  addAsset: (newAsset: Partial<Asset>) => void;
  removeAsset: (assetId: string) => void;
  resetPortfolio: () => void;
  updateAsset: (assetId: string, updates: Partial<Asset>) => void;
}


interface StorageOperationResult {
  success: boolean;
  error?: string;
}


const calculateAllocation = (assets: Asset[]): Asset[] => {
  const totalValue = assets.reduce((sum, a) => sum + (a.currentPrice * a.quantity), 0);
  
  if (totalValue === 0) return assets;

  return assets.map(asset => ({
    ...asset,
    totalValue: asset.currentPrice * asset.quantity,
    allocation: ((asset.currentPrice * asset.quantity) / totalValue) * 100
  }));
};


const createNewAsset = (partialAsset: Partial<Asset>): Asset => ({
  id: Date.now().toString(),
  symbol: partialAsset.symbol?.toUpperCase() || '',
  name: partialAsset.name || '',
  type: partialAsset.type as 'stock' | 'crypto',
  purchasePrice: partialAsset.purchasePrice || 0,
  currentPrice: partialAsset.currentPrice || partialAsset.purchasePrice || 0,
  purchaseDate: partialAsset.purchaseDate || new Date().toISOString(),
  quantity: Number(partialAsset.quantity) || 0,
  priceHistory: partialAsset.priceHistory || [{ price: partialAsset.purchasePrice || 0 }],
  totalValue: (partialAsset.currentPrice || 0) * (Number(partialAsset.quantity) || 0),
  allocation: 0,
  performance: partialAsset.performance || { day: 0, week: 0, month: 0, year: 0 }
});


const safelyParseStoredData = (): Asset[] => {
  try {
    const savedPortfolio = localStorage.getItem(STORAGE_KEY);
    if (!savedPortfolio) return [];

    const parsed = JSON.parse(savedPortfolio);
    if (!Array.isArray(parsed)) return [];

    return parsed;
  } catch (error) {
    console.error('Error parsing portfolio data:', error);
    return [];
  }
};


const safelySaveData = (data: Asset[]): StorageOperationResult => {
  try {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
    return { success: true };
  } catch (error) {
    console.error('Error saving portfolio data:', error);
    return { 
      success: false, 
      error: error instanceof Error ? error.message : 'Unknown error saving data'
    };
  }
};


export const usePortfolioStorage = (): UsePortfolioStorage => {
  const [portfolioData, setPortfolioData] = useState<Asset[]>(safelyParseStoredData);


  useEffect(() => {
    const result = safelySaveData(portfolioData);
    if (!result.success) {
      console.error('Failed to save portfolio data:', result.error);
    }
  }, [portfolioData]);


  const addAsset = (newAsset: Partial<Asset>) => {
    setPortfolioData(prev => {
      if (!newAsset.symbol || !newAsset.type) {
        console.error('Invalid asset data: symbol and type are required');
        return prev;
      }

      const existingAssetIndex = prev.findIndex(
        a => a.symbol.toLowerCase() === newAsset.symbol?.toLowerCase() && 
            a.type === newAsset.type
      );

      if (existingAssetIndex !== -1) {
        const existingAsset = prev[existingAssetIndex];
        const addedQuantity = Number(newAsset.quantity) || 0;
        const newQuantity = Number(existingAsset.quantity) + addedQuantity;

        const updatedAsset: Asset = {
          ...existingAsset,
          quantity: newQuantity,
          totalValue: newQuantity * existingAsset.currentPrice
        };

        const newPortfolio = [
          ...prev.slice(0, existingAssetIndex),
          updatedAsset,
          ...prev.slice(existingAssetIndex + 1)
        ];

        return calculateAllocation(newPortfolio);
      }

      const asset = createNewAsset(newAsset);
      return calculateAllocation([...prev, asset]);
    });
  };


  const removeAsset = (assetId: string) => {
    setPortfolioData(prev => {
      const newPortfolio = prev.filter(asset => asset.id !== assetId);
      return calculateAllocation(newPortfolio);
    });
  };


  const updateAsset = (assetId: string, updates: Partial<Asset>) => {
    setPortfolioData(prev => {
      const newPortfolio = prev.map(asset =>
        asset.id === assetId ? { ...asset, ...updates } : asset
      );
      return calculateAllocation(newPortfolio);
    });
  };


  const resetPortfolio = () => {
    setPortfolioData([]);
    localStorage.removeItem(STORAGE_KEY);
  };


  return {
    portfolioData,
    addAsset,
    removeAsset,
    resetPortfolio,
    updateAsset
  };
};