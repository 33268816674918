import { Asset, PortfolioMetrics } from "./types";


export const formatCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
}).format;


export const testPortfolioData: Asset[] = [
  {
    id: '1',
    type: 'stock',
    symbol: 'MSTR',
    name: 'MicroStrategy Inc.',
    quantity: 5,
    currentPrice: 450.00,
    purchasePrice: 350.00,
    purchaseDate: '2023-06-15',
    totalValue: 2250.00,
    allocation: 15,
    performance: { day: 1.5, week: 3.2, month: 8.4, year: 28.5 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 350 + Math.random() * 100 }))
  },
  {
    id: '2',
    type: 'stock',
    symbol: 'TSLA',
    name: 'Tesla Inc.',
    quantity: 5,
    currentPrice: 230.00,
    purchasePrice: 180.00,
    purchaseDate: '2023-04-20',
    totalValue: 1150.00,
    allocation: 10,
    performance: { day: -0.8, week: 1.5, month: -2.8, year: 22.5 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 180 + Math.random() * 50 }))
  },
  {
    id: '3',
    type: 'stock',
    symbol: 'MSFT',
    name: 'Microsoft Corporation',
    quantity: 8,
    currentPrice: 380.00,
    purchasePrice: 320.00,
    purchaseDate: '2023-03-15',
    totalValue: 3040.00,
    allocation: 12,
    performance: { day: 0.9, week: 3.2, month: 8.5, year: 18.7 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 320 + Math.random() * 60 }))
  },
  {
    id: '4',
    type: 'stock',
    symbol: 'NVDA',
    name: 'NVIDIA Corporation',
    quantity: 4,
    currentPrice: 450.00,
    purchasePrice: 380.00,
    purchaseDate: '2023-05-10',
    totalValue: 1800.00,
    allocation: 8,
    performance: { day: 2.1, week: 4.8, month: 15.2, year: 35.6 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 380 + Math.random() * 70 }))
  },
  {
    id: '5',
    type: 'crypto',
    symbol: 'BTC',
    name: 'Bitcoin',
    quantity: 0.5,
    currentPrice: 43000.00,
    purchasePrice: 40000.00,
    purchaseDate: '2023-01-10',
    totalValue: 21500.00,
    allocation: 30,
    performance: { day: 2.1, week: 5.5, month: 12.8, year: 45.5 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 40000 + Math.random() * 5000 }))
  },
  {
    id: '6',
    type: 'crypto',
    symbol: 'ETH',
    name: 'Ethereum',
    quantity: 2.5,
    currentPrice: 2200.00,
    purchasePrice: 1800.00,
    purchaseDate: '2023-02-20',
    totalValue: 5500.00,
    allocation: 10,
    performance: { day: 1.8, week: 4.2, month: 10.5, year: 38.2 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 1800 + Math.random() * 400 }))
  },
  {
    id: '7',
    type: 'crypto',
    symbol: 'SOL',
    name: 'Solana',
    quantity: 50,
    currentPrice: 95.00,
    purchasePrice: 65.00,
    purchaseDate: '2023-07-05',
    totalValue: 4750.00,
    allocation: 8,
    performance: { day: 3.5, week: 8.2, month: 25.4, year: 85.3 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 65 + Math.random() * 30 }))
  },
  {
    id: '8',
    type: 'crypto',
    symbol: 'DOGE',
    name: 'Dogecoin',
    quantity: 10000,
    currentPrice: 0.08,
    purchasePrice: 0.05,
    purchaseDate: '2023-08-15',
    totalValue: 800.00,
    allocation: 7,
    performance: { day: 2.5, week: 5.8, month: 18.2, year: 35.4 },
    priceHistory: Array.from({ length: 30 }, () => ({ price: 0.05 + Math.random() * 0.03 }))
  }
];

/**
 * Calculate and return portfolio metrics based on current data
 */
export const getPortfolioMetrics = (): PortfolioMetrics => {
  const totalValue = testPortfolioData.reduce((sum, asset) => sum + asset.totalValue, 0);
  const stocksValue = testPortfolioData.filter(a => a.type === 'stock')
    .reduce((sum, asset) => sum + asset.totalValue, 0);
  
  const bestPerformer = testPortfolioData.reduce((best, asset) => 
    asset.performance.month > (best?.performance.month ?? -Infinity) ? asset : best, testPortfolioData[0]);
  
  const worstPerformer = testPortfolioData.reduce((worst, asset) => 
    asset.performance.month < (worst?.performance.month ?? Infinity) ? asset : worst, testPortfolioData[0]);

  return {
    totalValue,
    totalReturn: testPortfolioData.reduce((sum, asset) => 
      sum + ((asset.currentPrice - asset.purchasePrice) * asset.quantity), 0),
    dailyChange: testPortfolioData.reduce((sum, asset) => 
      sum + (asset.performance.day * asset.totalValue) / totalValue, 0),
    monthlyChange: testPortfolioData.reduce((sum, asset) => 
      sum + (asset.performance.month * asset.totalValue) / totalValue, 0),
    yearlyChange: testPortfolioData.reduce((sum, asset) => 
      sum + (asset.performance.year * asset.totalValue) / totalValue, 0),
    stockAllocation: (stocksValue / totalValue) * 100,
    cryptoAllocation: ((totalValue - stocksValue) / totalValue) * 100,
    bestPerformer: `${bestPerformer.symbol} (${bestPerformer.performance.month.toFixed(1)}%)`,
    worstPerformer: `${worstPerformer.symbol} (${worstPerformer.performance.month.toFixed(1)}%)`
  };
};