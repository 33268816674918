import React from 'react';
import {
  PageContainer
} from '../discovery/discoveryPageStyles';
const IntroGemsPage: React.FC = () => {

  return (
    <PageContainer>
      Dette er en intro page for gems.
    </PageContainer>
  );
};

export default IntroGemsPage;