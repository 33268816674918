import React, { useState } from 'react';
import styled from 'styled-components';
import { FaStar } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import UpgradeModal from '../../../pages/upgrade/components/UpgradeModal';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border: 2px solid ${props => props.theme.colors.bitcoinOrangeHover};
  border-radius: 6px;
  background-color: transparent;
  color: ${props => props.theme.colors.bitcoinOrange};
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  min-width: 120px;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, ${props => props.theme.colors.bitcoinOrange} 0%, transparent 70%);
    transform: scale(0);
    transition: transform 0.5s ease-out, opacity 0.3s ease-out;
    opacity: 0;
  }

  &:hover::before {
    transform: scale(1);
    opacity: 0.3;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 153, 0, 0.3);
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.bitcoinOrange};
    transform: translateY(-3px) scale(1.05);
    box-shadow: 0 10px 20px rgba(255, 153, 0, 0.3);
  }

  &:active {
    transform: translateY(-1px) scale(1.02);
    box-shadow: 0 5px 10px rgba(255, 153, 0, 0.2);
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1.2em;
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: rotate(180deg) scale(1.2);
  }
`;

const UpgradeWrapper = styled.div`
  padding-right: 2rem;
  perspective: 1000px;
`;

const UpgradeButton: React.FC = () => {
  const { t } = useTranslation();
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);

  const handleUpgradeClick = () => {
    setIsUpgradeModalOpen(true);
  };

  return (
    <UpgradeWrapper>
      <Button onClick={handleUpgradeClick}>
        <FaStar />
        {t('upgrade.button')}
      </Button>

      <UpgradeModal
        isopen={isUpgradeModalOpen}
        onClose={() => setIsUpgradeModalOpen(false)}
      />
    </UpgradeWrapper>
  );
};

export default UpgradeButton;