import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaGem, FaUserFriends, FaChartLine, FaPiggyBank } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';

import {
  IntroSectionStyle,
  IntroContent,
  IntroTitle,
  IntroDescription,
  FeatureList,
  FeatureItem,
  FeatureIcon,
  ButtonContainer,
  StyledNavButton,
  StyledMoveButton,
  StyledPortfolioButton,
  FOMOText,
  PandaContainer,
  PandaImage,
  AdditionalInfo,
} from './introSectionStyles';



const IntroSection: React.FC = () => {
  const { t } = useTranslation();
  const { authState } = useAuth();



  const handleMove = () => {
    window.scrollTo({
      top: 1300,
      behavior: 'smooth'
    });
  };



  return (
    <IntroSectionStyle
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <IntroContent>
        <IntroTitle>
          {t('landingPage.introSection.title')}
        </IntroTitle>
        <IntroDescription>
          {t('landingPage.introSection.description')}
          <br />
          <br />
          {t('landingPage.introSection.description2')}
        </IntroDescription>
        <FeatureList>
          <FeatureItem>
            <FeatureIcon><FaGem /></FeatureIcon>
            {t('landingPage.introSection.features.discovery')}
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon><FaUserFriends /></FeatureIcon>
            {t('landingPage.introSection.features.community')}
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon><FaChartLine /></FeatureIcon>
            {t('landingPage.introSection.features.predictions')}
          </FeatureItem>
          <FeatureItem>
            <FeatureIcon><FaPiggyBank /></FeatureIcon>
            {t('landingPage.introSection.features.allLevels')}
          </FeatureItem>
        </FeatureList>

        <ButtonContainer>
          <StyledNavButton to={'/gems'}>
            {t('landingPage.introSection.cta')}
          </StyledNavButton>
          {!authState?.isAuthenticated ? 
            <StyledMoveButton onClick={handleMove}>
              {t('landingPage.introSection.learnMore')}
            </StyledMoveButton>
            :
            <StyledPortfolioButton to={'/portfolio'}>
              {t('landingPage.introSection.freePortfolio')}
            </StyledPortfolioButton>
          }
        </ButtonContainer>

        <AdditionalInfo
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          {t('landingPage.introSection.communityContribution')}
        </AdditionalInfo>
        <FOMOText
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.7, duration: 0.8 }}
        >
          {t('landingPage.introSection.fomo')}
        </FOMOText>

      </IntroContent>
      <PandaContainer
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: 0.3, duration: 0.8 }}
      >
        <PandaImage src="/icons/panda_big/sit_1.png" alt="Trailvis Mascot" />
      </PandaContainer>
    </IntroSectionStyle>
  );
};



export default IntroSection;