import styled from 'styled-components';
import theme from '../../styles/theme';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  min-height: 100vh;
  background-color: ${theme.colors.background.primary};

  @media (min-width: 768px) {
    padding: 0.5rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
  background-color: #f0f2f5;

  @media (min-width: 768px) {
    margin-bottom: 2rem;
  }
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const RowContainer = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
`;

export const AddNewGemButton = styled.button`
  background-color: rgba(230, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 10px 30px;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  @media (max-width: 768px) {
    flex: 1;
  }
  
  &:hover {
    background-color: ${theme.colors.darkRedHover};
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  max-width: 900px;
  padding: 0.5rem;
  border: 1px solid #000;
  border-radius: 6px;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  transition: border-color 0.3s;
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex: 3;
  }

  &:focus {
    outline: none;
    border-color: #ffb3ba;
  }

  @media (min-width: 768px) {
    flex: 1;
    padding: 0.75rem;
    font-size: 1rem;
  }
`;

export const SortSelect = styled.select`
  width: 100%;
  min-width: 120px;
  padding: 0.5rem;
  border: 1px solid #000;
  border-radius: 6px;
  background-color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #ffb3ba;
  }

  @media (min-width: 768px) {
    width: auto;
    padding: 0.75rem;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    color: black;
  }
`;

export const FilterSelect = styled(SortSelect)``;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 2rem;
  }
`;

export const GemsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1600px;
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 975px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1625px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const GemCard = styled.div<{ iswatched?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border: ${props => props.iswatched ? '2px solid #FFD700' : '1px solid #E0E0E0'};
  max-width: 400px;
  max-height: 450px;
  height: 100%;
  min-width: 300px;

  ${props => props.iswatched && `
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.4);
  `}

  &:hover {
    box-shadow: ${props => props.iswatched 
      ? '0 10px 20px rgba(255, 215, 0, 0.3)' 
      : '0 10px 20px rgba(0, 0, 0, 0.1)'};
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const GemCardHeader = styled.div`
  background-color: #ffb3ba;
  padding: 0.75rem;
  color: #4a4a4a;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

export const GemRank = styled.span`
  font-size: 1rem;
  font-weight: bold;
  margin-right: 0.5rem;
  color: #333333;
`;

export const GemNavigateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const GemCardNavigate = styled.button`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;
  min-height: 34px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const GemName = styled.h2`
  font-size: 1rem;
  margin: 0;
  flex: 1;
  color: #333333;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  max-height: 3em;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
`;

export const GemSymbol = styled.p`
  font-size: 0.95rem;
  opacity: 0.9;
  margin: 0;
  font-weight: 700;
  color: #333333;
  background-color: ${theme.colors.primaryPinkHover};
  padding: 4px 8px;
  border-radius: 12px;
`;

export const GemCardBody = styled.div`
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.background.paper};
`;

export const GemCardContent = styled.div`
  flex-grow: 1;
`;

export const VotingSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
`;

export const VoteButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.2rem;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const VoteIcon = styled.span`
  font-size: 1.2rem;
`;

export const VoteCount = styled.span`
  font-size: 0.8rem;
  color: #4a4a4a;
`;

export const ExpectedReturn = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  color: #4a4a4a;
  white-space: nowrap;
  margin-top: 0.5rem;
  margin-right: 0.5rem;
`;

export const ExpectedReturnValue = styled.span`
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 800;
  color: ${theme.colors.moneyGreen};
`;

export const TopPredictionSection = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TopPredictionLabel = styled.span`
  font-size: 0.8rem;
  color: #4a4a4a;
  white-space: nowrap;
  margin-right: 0.5rem;
`;

export const TopPredictionValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0.5rem;
`;

export const PredictionPrice = styled.span`
  font-size: 1.1rem;
  font-weight: 800;
  color: #4a4a4a;
`;

export const PredictionTimeRange = styled.span`
  font-size: 0.8rem;
  color: #6a6a6a;
`;

export const PredictionUsername = styled.span`
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: ${theme.colors.primaryOrange};
  font-weight: bold;
  white-space: nowrap;
  align-items: center;
`;

export const PredictionSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  padding-top: 0.75rem;
`;

export const PredictionLabel = styled.span`
  font-size: 0.8rem;
  color: #4a4a4a;
  margin-right: 0.5rem;
  white-space: nowrap;
`;

export const PredictionValue = styled.span`
  font-size: 1.1rem;
  font-weight: 800;
  color: #4a4a4a;
`;

export const WatchToShowPrediction = styled.button`
  flex: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const WatchIcon = styled.span`
  font-size: 1rem;
  margin-right: 0.5rem;
`;

export const WatchText = styled.span`
  color: #555;
  font-size: 0.8rem;
`;

export const TagSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const Tag = styled.span`
  background-color: #f0f2f5;
  color: #4a4a4a;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.7rem;
`;

export const GemCardFooter = styled.div`
  padding: 0.75rem;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  border-top: 1px solid #e0e0e0;
  margin-top: auto;
  flex-wrap: nowrap;
  justify-content: space-between;

  @media (min-width: 768px) {
    justify-content: space-between;
    align-items: center;
  }
`;

export const WatchButton = styled.button<{ iswatched: string, disabled: boolean }>`
  background-color: ${props => props.iswatched === 'true' ? '#d3d3d3' : '#baffc9'};
  color: #4a4a4a;
  border: 1px solid #000;
  padding: 0.4rem 0.8rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    background-color: #5adf7e;
  }

  &:disabled {
    background-color: #d3d3d3;
  }
`;

export const CreatedAt = styled.span`
  font-size: 0.7rem;
  color: #4a4a4a;
  white-space: nowrap;
`;

export const LoadingPrediction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10px;
  padding: 1rem;
`;

export const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const InfoListContainer = styled.div`
  @media (max-width: 768px) {
    gap: 1rem;
    display: flex;
    flex-direction: row;
  }
`;

export const MainControls = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;