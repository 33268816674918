import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0.75rem;
    max-width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -0.5px;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 2rem;
  padding: 0 0.5rem;
  width: 100%;

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    align-items: stretch;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const InputGroup = styled.div`
  flex: 1;
  min-width: 120px;
  width: 100%;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-bottom: 2rem;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const Label = styled.div`
  font-size: 0.875rem;
  color: #6B7280;
`;

export const Value = styled.div<{ color?: string }>`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${props => props.color || 'inherit'};

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

export const SubValue = styled.div`
  font-size: 0.875rem;
  color: #4B5563;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const MetricGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const MetricCard = styled.div`
  text-align: center;
`;

export const MetricValue = styled.div<{ color: string }>`
  font-weight: bold;
  font-size: 1.125rem;
  color: ${props => props.color};
`;

export const MetricLabel = styled.div`
  font-size: 0.75rem;
  color: #6B7280;
`;

export const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ChartTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const PieChartContainer = styled.div`
  height: 20rem;
  width: 80%;

  @media (max-width: 768px) {
    height: 12rem;
  }
`;

export const AssetSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

export const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.125rem;
  }
`;

export const AssetRow = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const AssetInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: 120px;
`;

export const ChartContainer = styled.div`
  flex: 1;
  height: 4rem;
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const AssetDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  min-width: 0;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const DetailColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const DetailLabel = styled.div`
  font-size: 0.75rem;
  color: #6B7280;
`;

export const DetailValue = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
`;

export const TrendIndicator = styled.div<{ positive: boolean }>`
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: ${props => props.positive ? '#10B981' : '#EF4444'};
`;

export const StyledInput = styled.input`
  width: 90%;
  max-width: 200px;
  height: 30px;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  font-size: 0.875rem;
  line-height: 1.25rem;
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #2563EB;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const StyledButton = styled.button<{ variant?: 'default' | 'outline' | 'reset' }>`
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  border-radius: 0.375rem;
  transition: all 150ms ease-in-out;
  min-width: 120px;
  cursor: pointer;
  width: 100%;

  ${props => props.variant === 'reset' && `
    background: transparent;
    color: ${props.theme.colors.primaryRed};
    &:hover {
      background: ${props.theme.colors.background.paper};
    }
  `}

  ${props => props.variant === 'default' && `
    background: ${props.theme.colors.primaryRed};
    color: white;
    &:hover {
      background: ${props.theme.colors.primaryRedHover};
    }
  `}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

export const RadioGroupContainer = styled.div`
  min-width: 200px;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const RadioInput = styled.input.attrs({ type: 'radio' })`
  width: 1rem;
  height: 1rem;
  margin: 0;
  border-radius: 50%;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  cursor: pointer;
  
  &:checked {
    background: #2563EB;
    border-color: #2563EB;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const RadioLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #111827;
  cursor: pointer;
`;

export const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.25rem;
  color: #111827;
`;

export const SummaryCard = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const SectorAllocationCard = styled.div`
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #E5E7EB;
  background: #FFFFFF;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const ToggleContainer = styled.div`
  position: relative;
  background: #f3f4f6;
  border-radius: 999px;
  padding: 2px;
  display: inline-flex;
  cursor: pointer;
  width: fit-content;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: stretch;
  }
`;

export const ToggleOption = styled.button<{ active: boolean }>`
  border: none;
  background: ${props => props.active ? '#fff' : 'transparent'};
  color: ${props => props.active ? '#000' : '#6b7280'};
  padding: 8px 16px;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  cursor: pointer;
  box-shadow: ${props => props.active ? '0 1px 3px rgba(0,0,0,0.1)' : 'none'};
  flex: 1;

  &:hover {
    color: ${props => props.active ? '#000' : '#374151'};
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 13px;
  }
`;