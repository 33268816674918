import React, { useEffect, useState } from 'react';
import { styled, keyframes } from 'styled-components';
import { TrendingUp, TrendingDown, Users, Minus } from 'lucide-react';

interface Gem {
  id: string;
  name: string;
  symbol: string;
  price: number;
  totalVotes: number;
  score: number;
  votes: {
    positive: number;
    negative: number;
  };
  createdAt: Date;
  tags: string[];
  watchers: number;
  fromUsername: string;
  averagePrediction?: number;
  topVotedPrediction?: {
    value: number;
    username: string;
    upvote: number;
    downvote: number;
  }
}

interface ScrollingBannerProps {
  gems: Gem[];
}

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const BannerContainer = styled.div`
  max-width: 100%;
  height: 40px;
  overflow: hidden;
  position: relative;
  margin: 0.5rem 0;
`;

const ScrollingTrack = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const ScrollingContent = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  animation: ${scroll} 60s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
`;

const GemItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;
  color: #333;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  margin-right: 1.2rem;

  &:last-child {
    border-right: none;
  }
`;

const Symbol = styled.span`
  font-weight: 600;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  color: #2563eb;
`;

const Price = styled.span`
  font-weight: 500;
  margin-right: 0.5rem;
  font-size: 0.9rem;
`;

const Stats = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #666;
`;

const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.85rem;
`;

const formatPrice = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  }).format(price);
};

const getScoreColor = (score: number) => {
  if (score > 0) return '#16a34a';
  if (score < 0) return '#dc2626';
  return '#666';
};

const ScrollingBanner: React.FC<ScrollingBannerProps> = ({ gems }) => {
  const [duplicatedGems, setDuplicatedGems] = useState<Gem[]>([]);

  useEffect(() => {
    if (gems.length) {
      setDuplicatedGems([...gems, ...gems]);
    }
  }, [gems]);

  return (
    <BannerContainer>
      <ScrollingTrack>
        <ScrollingContent>
          {duplicatedGems.map((gem, index) => (
            <GemItem 
              key={`${gem.id}-${index}`}
              onClick={() => window.location.href = `/gems/${gem.symbol}`}
              style={{ cursor: 'pointer' }}
            >
              <Symbol>{gem.symbol}</Symbol>
              <Price>{formatPrice(gem.price)}</Price>
              <Stats>
                <StatItem style={{ color: getScoreColor(gem.score) }}>
                  {gem.score > 0 ? <TrendingUp size={14} /> : 
                   gem.score < 0 ? <TrendingDown size={14} /> :
                   <Minus size={14} />}
                  {Math.abs(gem.score)}
                </StatItem>
                <StatItem>
                  <Users size={14} />
                  {gem.watchers}
                </StatItem>
              </Stats>
            </GemItem>
          ))}
        </ScrollingContent>
      </ScrollingTrack>
    </BannerContainer>
  );
};

export default ScrollingBanner;