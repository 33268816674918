import { useState, useEffect, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Clock, Trash2, Plus, AlertCircle } from 'lucide-react';
import theme from '../../../styles/theme';


interface Note {
  id: number;
  text: string;
  timestamp: string;
}


const NoteContainer = styled.div`
  background: ${theme.colors.background.paper};
  border-radius: 6px;
  padding: 24px;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(255, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;
  max-height: 100vh;
  overflow: hidden;
`;


const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 2px solid #f0f0f0;
`;


const NoteHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;


const NoteTitle = styled.h3`
  margin: 0;
  font-size: 24px;
  color: #1a1a1a;
  font-weight: 600;
`;


const NoteCount = styled.span`
  font-size: 14px;
  color: #666;
  background: #f5f5f5;
  padding: 4px 12px;
  border-radius: 12px;
`;


const NoteInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 160px;
  max-height: 200px;
  flex-shrink: 0;
`;


const NoteTextarea = styled.textarea`
  width: 100%;
  height: 120px;
  min-height: 120px;
  max-height: 120px;
  padding: 16px;
  border: 2px solid #eaeaea;
  border-radius: 8px;
  font-size: 15px;
  line-height: 1.6;
  resize: none;
  margin: 0;
  transition: all 0.2s ease;
  font-family: inherit;
  box-sizing: border-box;
 
  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.1);
  }
`;


const NotesList = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 8px;
  min-height: 0;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
    
    &:hover {
      background: #ccc;
    }
  }
`;


const NoteItem = styled.div`
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  
  &:hover {
    background: #fff;
    border-color: #e9ecef;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;


const NoteText = styled.p`
  margin: 0;
  font-size: 15px;
  line-height: 1.5;
  color: #2c3e50;
  white-space: pre-wrap;
  word-break: break-word;
`;


const NoteFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #6c757d;
`;


const TimeStamp = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;


const DeleteButton = styled.button`
  background: none;
  border: none;
  padding: 6px;
  cursor: pointer;
  color: #dc3545;
  opacity: 0.7;
  transition: all 0.2s ease;
  border-radius: 4px;
  
  &:hover {
    opacity: 1;
    background: rgba(220, 53, 69, 0.1);
  }
`;


const SubmitButton = styled.button`
  padding: 12px 24px;
  background: ${theme.colors.primaryPinkHover};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
 
  &:hover {
    background: ${theme.colors.primaryPink};
    transform: translateY(-1px);
  }
  
  &:disabled {
    background: #ccc;
    cursor: default;
    transform: none;
  }
`;


const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 32px;
  color: #6c757d;
  text-align: center;
`;


const DeleteAllButton = styled.button`
  background: none;
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  color: #6c757d;
  transition: all 0.2s ease;
  
  &:hover {
    color: #dc3545;
  }
`;


const NoteTaker = () => {
  const [currentNote, setCurrentNote] = useState('');
  const [notes, setNotes] = useState<Note[]>([]);


  useEffect(() => {
    const savedNotes = localStorage.getItem('dashboardNotes');
    if (savedNotes) {
      try {
        setNotes(JSON.parse(savedNotes));
      } catch (error) {
        console.error('Failed to parse saved notes:', error);
        setNotes([]);
      }
    }
  }, []);


  const handleDeleteAllNotes = () => {
    setNotes([]);
    localStorage.removeItem('dashboardNotes');
  };


  const handleSubmitNote = () => {
    if (!currentNote.trim()) return;
   
    const newNote = {
      id: Date.now(),
      text: currentNote.trim(),
      timestamp: new Date().toISOString()
    };
   
    const updatedNotes = [newNote, ...notes];
    setNotes(updatedNotes);
    setCurrentNote('');
    localStorage.setItem('dashboardNotes', JSON.stringify(updatedNotes));
  };


  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmitNote();
    }
  };


  const handleDeleteNote = (id: number) => {
    const updatedNotes = notes.filter(note => note.id !== id);
    setNotes(updatedNotes);
    localStorage.setItem('dashboardNotes', JSON.stringify(updatedNotes));
  };


  const formatDate = (timestamp: string) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };


  return (
    <NoteContainer>
      <NoteHeader>
        <NoteTitle>Quick Notes</NoteTitle>
        <NoteHeaderRight>
          {notes.length > 0 && (
            <DeleteAllButton 
              onClick={handleDeleteAllNotes}
            >
              Delete All
              <Trash2 size={16} />
            </DeleteAllButton>
          )}
          <NoteCount>{notes.length} note{notes.length !== 1 ? 's' : ''}</NoteCount>
        </NoteHeaderRight>
      </NoteHeader>

      <NoteInputContainer>
        <NoteTextarea
          value={currentNote}
          onChange={(e) => setCurrentNote(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Write your note here... (Press Enter to save)"
          maxLength={500}
        />
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">
            {currentNote.length}/500 characters
          </span>
          <SubmitButton 
            onClick={handleSubmitNote}
            disabled={!currentNote.trim()}
          >
            <Plus size={16} />
            Add Note
          </SubmitButton>
        </div>
      </NoteInputContainer>

      <NotesList>
        {notes.length === 0 ? (
          <EmptyState>
            <AlertCircle size={32} />
            <p>No notes yet.</p>
          </EmptyState>
        ) : (
          notes.map(note => (
            <NoteItem key={note.id}>
              <NoteText>{note.text}</NoteText>
              <NoteFooter>
                <TimeStamp>
                  <Clock size={14} />
                  {formatDate(note.timestamp)}
                </TimeStamp>
                <DeleteButton 
                  onClick={() => handleDeleteNote(note.id)}
                  aria-label="Delete note"
                  disabled={notes.length <= 1}
                >
                  <Trash2 size={16} />
                </DeleteButton>
              </NoteFooter>
            </NoteItem>
          ))
        )}
      </NotesList>
    </NoteContainer>
  );
};


export default NoteTaker;