import { useState, useEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import theme from '../../../styles/theme';

const blinkAnimation = keyframes`
  0%, 100% { 
    background-color: ${theme.colors.background.paper}; 
  }
  50% { 
    background-color: ${theme.colors.lightRed}; 
  }
`;

const PomodoroContainer = styled.div<{ blink: boolean }>`
  background: ${theme.colors.background.paper};
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 170px;
  ${props => props.blink && css`
    animation: ${blinkAnimation} 1s infinite;
  `}
`;

const TimeDisplay = styled.div<{ isactive: boolean }>`
  font-size: 48px;
  font-weight: 500;
  color: #333;
  margin-bottom: 16px;
  background-color: ${props => props.isactive ? '#ffcccc' : 'transparent'};
  border-radius: 6px;
  padding: 16px;
  border: 2px dashed ${theme.colors.primaryRed};
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

const Button = styled.button<{ isactive: boolean }>`
  background-color: ${theme.colors.primaryPinkHover};
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.6rem 1rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: ${theme.colors.primaryPink};
  }
`;

const Pomodoro = () => {
  const [time, setTime] = useState(1500); // 25 minutes in seconds
  const [isActive, setIsActive] = useState(false);
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    
    if (isActive) {
      interval = setInterval(() => {
        setTime(prevTime => {
          if (prevTime <= 1) {
            setIsActive(false);
            setBlink(true);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleStart = () => {
    setIsActive(true);
    setBlink(false);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(1500); // Reset to 25 minutes
    setBlink(false);
  };

  return (
    <PomodoroContainer blink={blink}>
      <TimeDisplay isactive={isActive}>{formatTime(time)}</TimeDisplay>
      <ButtonGroup>
        {!isActive && time !== 0 && (
          <Button isactive={isActive} onClick={handleStart}>
            Start
          </Button>
        )}
        <Button isactive={isActive} onClick={handleReset}>Reset</Button>
      </ButtonGroup>
    </PomodoroContainer>
  );
};

export default Pomodoro;