import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';

const StyledNavItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 120px; 
  color: white; 
  background-color: #4a0e4e;
  text-decoration: none;
  border-radius: 6px;
  border: 1px solid black;
  transition: all 0.3s ease;
  font-weight: bold;
  text-align: center;

  &:hover {
    background-color: #3a0b3e;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  svg {
    font-size: 1.2rem;
    margin-right: 10px;
  }
`;

interface NavItemProps {
  to: string;
  children: React.ReactNode;
}

const SidebarNavItem: React.FC<NavItemProps> = ({ to, children, ...props }) => {
  const navigate = useNavigate();
 
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    navigate(to);
  };

  return (
    <StyledNavItem to={to} onClick={handleClick} {...props}>
      {children}
    </StyledNavItem>
  );
};

export default SidebarNavItem;