export const fakeGems = [
  {
    id: '1',
    name: 'Bitcoin',
    symbol: 'BTC',
    votes: { positive: 150, negative: 50 },
    score: 100,
    watchers: 185,
    topPrediction: { value: 250000, username: 'satoshiDisciple', endTime: '2025-12-31' },
    averagePrediction: 180000,
    tags: ['crypto', 'digital gold', 'decentralized', 'store of value', 'blockchain'],
    createdAt: '2024-01-03',
    type: 'crypto'
  },
  {
    id: '2',
    name: 'Tesla, Inc.',
    symbol: 'TSLA',
    votes: { positive: 130, negative: 70 },
    score: 60,
    watchers: 190,
    topPrediction: { value: 1000, username: 'elonFanatic', endTime: '2025-12-31' },
    averagePrediction: 750,
    tags: ['stock', 'EVs', 'renewable energy', 'AI', 'automotive', 'tech'],
    createdAt: '2024-02-15',
    type: 'stock'
  },
  {
    id: '3',
    name: 'Solana',
    symbol: 'SOL',
    votes: { positive: 110, negative: 70 },
    score: 40,
    watchers: 160,
    topPrediction: { value: 500, username: 'blockchainScaler', endTime: '2025-12-31' },
    averagePrediction: 350,
    tags: ['crypto', 'smart contracts', 'high-performance', 'blockchain'],
    createdAt: '2024-03-21',
    type: 'crypto'
  },
  {
    id: '4',
    name: 'MicroStrategy Incorporated',
    symbol: 'MSTR',
    votes: { positive: 90, negative: 60 },
    score: 30,
    watchers: 140,
    topPrediction: { value: 2000, username: 'bitcoinBalanceSheet', endTime: '2025-12-31' },
    averagePrediction: 1500,
    tags: ['stock', 'business intelligence', 'bitcoin proxy', 'software'],
    createdAt: '2024-04-10',
    type: 'stock'
  },
  {
    id: '5',
    name: 'Random memecoin',
    symbol: 'MEME',
    votes: { positive: 70, negative: 105 },
    score: -35,
    watchers: 120,
    topPrediction: { value: 15000, username: 'vitalikVision', endTime: '2025-12-31' },
    averagePrediction: 12000,
    tags: ['cryptocurrency', 'crypto', 'smart contracts', 'DeFi'],
    createdAt: '2024-05-05',
    type: 'crypto'
  },
  {
    id: '6',
    name: 'NVIDIA Corporation',
    symbol: 'NVDA',
    votes: { positive: 100, negative: 100 },
    score: 0,
    watchers: 100,
    topPrediction: { value: 1500, username: 'aiChipMaster', endTime: '2025-12-31' },
    averagePrediction: 1200,
    tags: ['stock', 'semiconductors', 'AI', 'GPU', 'machine learning'],
    createdAt: '2024-06-18',
    type: 'stock'
  }
];