// SvInput.tsx
import React from 'react';
import styled from 'styled-components';



interface InputProps {
  inputSize?: 'small' | 'medium' | 'large';
  variant?: 'primary' | 'secondary' | 'outline';
  currency?: string;
  placeholder?: string;
}



const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
`;



const CurrencyIcon = styled.span<{ inputSize: InputProps['inputSize'] }>`
  position: absolute;
  left: 10px;
  font-size: ${({ inputSize = 'medium' }) => 
    inputSize === 'small' ? '0.75rem' : 
    inputSize === 'large' ? '1rem' : 
    '0.85rem'
  };
  color: #333333;
  padding-top: 2px;
`;



const StyledInput = styled.input<InputProps>`
  width: 88%;
  padding: 0.75rem 1rem 0.75rem 2.5rem;
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #e74c3c;
    box-shadow: 0 0 0 1px rgba(231, 76, 60, 0.2);
  }
`;



const SvInput: React.FC<InputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({ 
  inputSize = 'medium', 
  variant = 'outline',
  currency,
  ...props 
}) => {
  return (
    <InputWrapper>
      {currency && <CurrencyIcon inputSize={inputSize}>{currency}</CurrencyIcon>}
      <StyledInput inputSize={inputSize} variant={variant} currency={currency} {...props} />
    </InputWrapper>
  );
};



export default SvInput;