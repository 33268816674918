import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvInput from '../../../components/common/input/SvInput';
import { useAuth } from '../../../contexts/AuthContext';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface RegisterFormProps {
  onRegisterSuccess: () => void;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ onRegisterSuccess }) => {
  const { t } = useTranslation();
  const { register } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    if (password !== confirmPassword) {
      setError(t('auth.passwordMismatch'));
      return;
    }
    try {
      await register(email, password, "test_username");
      onRegisterSuccess();
    } catch (error) {
      console.error('Failed to register:', error);
      setError(t('auth.registrationFailed'));
    }
  };

  return (
    <Form onSubmit={handleRegister} id="registerForm">
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <SvInput
        type="email"
        placeholder={t('auth.email')}
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        required
        inputSize="medium"
        variant="outline"
      />
      <SvInput
        type="password"
        placeholder={t('auth.password')}
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        required
        inputSize="medium"
        variant="outline"
      />
      <SvInput
        type="password"
        placeholder={t('auth.confirmPassword')}
        value={confirmPassword}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
        required
        inputSize="medium"
        variant="outline"
      />
    </Form>
  );
};

export default RegisterForm;