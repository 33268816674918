import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';



// Custom useScroll hook
const useScroll = (threshold: number) => {
  const [showFloatingHeader, setShowFloatingHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollThreshold = windowHeight * (threshold / 100);
      setShowFloatingHeader(scrollPosition > scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  return showFloatingHeader;
};



// Styled button component
const StyledButton = styled.button<{ isvisible: string }>`
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  cursor: ${props => props.isvisible === 'true' ? 'pointer' : 'default'};
  color: ${props => props.theme.colors.lightRed};
  border: none;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.primaryPink};
  width: 65px;
  height: 35px;
  font-size: 24px;
  opacity: ${props => props.isvisible === 'true' ? '1' : '0'};
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${props => props.theme.colors.primaryPinkHover};
    color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 768px) {
    bottom: 20px;
    right: 20px;
    left: auto;
    transform: none;
  }
`;



// ScrollToTopButton component
const ScrollToTopButton: React.FC = () => {
  const { t } = useTranslation();
  const isVisible = useScroll(40);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };



  return isVisible ? (
    <StyledButton onClick={scrollToTop} isvisible={isVisible.toString()}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <ChevronUp size={20} />
        <div style={{ fontSize: '12px', fontWeight: 'bold', whiteSpace: 'nowrap', marginLeft: '3px' }}>
          {t('discovery.up')}
        </div>
      </div>
    </StyledButton>
  ) : null;
};



export default ScrollToTopButton;