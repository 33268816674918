const theme = {
  colors: {
    background: {
      primary: '#f0f2f5',
      dark: '#D7CCC8',
      paper: '#FFFFFF',
    },

    text: {
      primary: 'black',
      secondary: 'grey',
    },

    primaryPink: '#ffb3ba',
    primaryPinkHover: '#ff9aa2',
    lightPink: '#ffd7e1',

    primaryOrange: '#f97316',
    primaryOrangeHover: '#ea580c',
    lightOrange: '#fff7ed',
    secondaryOrange: '#9a3412',

    primaryRed: 'red',
    primaryRedHover: '#8B0000',
    lightRed: '#DD4E49',
    darkRed: 'rgba(230, 0, 0, 0.7)',
    darkRedHover: 'rgba(204, 0, 0, 0.9)',
    secondaryRed: '#ff3333',

    primaryYellow: '#FFEB3B',
    primaryYellowHover: '#FDD835',
    lightYellow: '#FFFDE7',

    darkGrey: '#888',
    standardGrey: '#D3D3D3',
    lightGrey: '#F0F0F0',

    bitcoinOrange: '#FFA500',
    bitcoinOrangeHover: '#FFB74D',

    moneyGreen: '#008000',
    moneyGreenHover: '#006400',

    score: {
      positive: '#ecfdf5',
      neutral: '#E0E0E0',
      negative: '#fef2f2',
    }
  },
};

export default theme;