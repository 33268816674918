import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { addToWaitlist } from '../../api/waitlistApi';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from '../../components/header/components/LanguageSwitcher';
import * as Styled from './waitlistStyles';
import AuthButton from '../../components/auth/AuthButton';
import UpgradeButton from '../../components/header/components/Upgrade';

const WaitlistPage: React.FC = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setIsInvalid(true);
      return;
    }
    setIsInvalid(false);
    await addToWaitlist(email);
    setIsSuccess(true);
    setEmail('');
  };

  return (
    <>
      <Styled.PageContainer>
        <Styled.HeaderWrapper>
          <Styled.FloatingLogo>
            <Styled.LogoImage src="/logo/title_3.png" alt="trailvis Logo" />
          </Styled.FloatingLogo>
          <Styled.LanguageSwitcherWrapper>
            <LanguageSwitcher />
            <Styled.LanguageText>{t('waitlist.language')}</Styled.LanguageText>
          </Styled.LanguageSwitcherWrapper>
        </Styled.HeaderWrapper>
        <Styled.ContentWrapper>
          <Styled.Title>{t('waitlist.title')}</Styled.Title>
          <Styled.Subtitle>{t('waitlist.subtitle')}</Styled.Subtitle>
          <Styled.LimitedSpotsBanner>{t('waitlist.limitedSpots')}</Styled.LimitedSpotsBanner>
          {!isSuccess ? (
            <Styled.Form onSubmit={handleSubmit}>
              <Styled.InputWrapper>
                <Styled.InputIcon />
                <Styled.Input
                  type="email"
                  placeholder={t('waitlist.emailPlaceholder')}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setIsInvalid(false);
                  }}
                  isInvalid={isInvalid}
                />
              </Styled.InputWrapper>
              <Styled.Button type="submit">
                <Send size={20} style={{ marginRight: '0.75rem' }} />
                {t('waitlist.submitButton')}
              </Styled.Button>
            </Styled.Form>
          ) : (
            <Styled.SuccessMessage>
              {t('waitlist.successMessage')}
            </Styled.SuccessMessage>
          )}
        </Styled.ContentWrapper>
        <Styled.FAQSection>
          <Styled.FAQItem>
            <Styled.FAQQuestion>{t('waitlist.faq.question1')}</Styled.FAQQuestion>
            <Styled.FAQAnswer>{t('waitlist.faq.answer1')}</Styled.FAQAnswer>
          </Styled.FAQItem>
          <Styled.FAQItem>
            <Styled.FAQQuestion>{t('waitlist.faq.question2')}</Styled.FAQQuestion>
            <Styled.FAQAnswer>{t('waitlist.faq.answer2')}</Styled.FAQAnswer>
          </Styled.FAQItem>
        </Styled.FAQSection>
      <Styled.ContactInfo>{t('waitlist.contact')}</Styled.ContactInfo>
      
      <div style={{ marginTop: '2rem' }}>
        <UpgradeButton />
        <AuthButton source={"waitlist"}/>
      </div>
      
      </Styled.PageContainer>
    </>
  );
};

export default WaitlistPage;