import { useCallback, useState, memo, useEffect, useMemo } from 'react';
import SimpleHeader from '../../components/simpleHeader/SimpleHeader';
import { getDashboardAssets, getDashboardForexDirect } from '../../api/dashboardApi';
import type { CategoryState, CategoryKey, ForexAsset, Asset } from './types';
import type { Gem } from '../discovery/types';
import { AnimatePresence } from 'framer-motion';
import { RefreshIcon } from './RefreshIcon';
import { LoadingCard } from './LoadingCard';
import { ErrorCard } from './ErrorCard';
import { AssetCard } from './AssetCard';
import * as Styled from './dashboardStyles';
import NoteTaker from './widgets/NoteTaker';
import Timer from './widgets/Timer';
import ScrollingBanner from './widgets/ScrollingBanner';
import Pomodoro from './widgets/Pomodoro';
import Petto from './widgets/Petto';



// Configuration
const API_CONFIG = {
  retryAttempts: 3,
  retryDelay: 1000,
  categories: {
    forex: {
      enabled: true,
      rateLimit: 100
    },
    gems: {
      enabled: true,
      rateLimit: 250
    }
  }
} as const;



const createFetchForexRates = (updateState: (category: CategoryKey, updates: Partial<CategoryState>) => void) => 
  async () => {
    try {
      updateState('forex', { isLoading: true, error: null });
      const rates = await getDashboardForexDirect(API_CONFIG.categories.forex.enabled);
      updateState('forex', { 
        isLoading: false, 
        error: null,
        lastUpdated: new Date(),
        retryCount: 0
      });
      return rates;
    } catch (error) {
      updateState('forex', { 
        error: 'Failed to fetch forex rates',
        isLoading: false 
      });
      throw error;
    }
  };



const createFetchGems = (updateState: (category: CategoryKey, updates: Partial<CategoryState>) => void) => 
  async () => {
    try {
      updateState('gems', { isLoading: true, error: null });
      const gems = await getDashboardAssets();
      updateState('gems', { 
        isLoading: false, 
        error: null,
        lastUpdated: new Date(),
        retryCount: 0
      });
      return gems;
    } catch (error) {
      updateState('gems', { 
        error: 'Failed to fetch gems',
        isLoading: false 
      });
      throw error;
    }
  };



// Main Dashboard Component
const Dashboard = () => {
  // State Management
  const [forexRates, setForexRates] = useState<ForexAsset[]>([]);
  const [gems, setGems] = useState<Gem[]>([]);
  const [isRefreshing, setIsRefreshing] = useState(false);
  
  const [categoryStates, setCategoryStates] = useState<Record<CategoryKey, CategoryState>>({
    gems: { isLoading: false, error: null, lastUpdated: null, retryCount: 0 },
    forex: { isLoading: false, error: null, lastUpdated: null, retryCount: 0 }
  });


  
  const updateCategoryState = useCallback((
    category: CategoryKey, 
    updates: Partial<CategoryState>
  ) => {
    setCategoryStates(prev => ({
      ...prev,
      [category]: { ...prev[category], ...updates }
    }));
  }, []);



  const fetchForexRates = useMemo(
    () => createFetchForexRates(updateCategoryState),
    [updateCategoryState]
  );



  const fetchGems = useMemo(
    () => createFetchGems(updateCategoryState),
    [updateCategoryState]
  );



  useEffect(() => {
    setIsRefreshing(true);
    Promise.all([
      fetchForexRates().then(setForexRates),
      fetchGems().then(setGems)
    ]).finally(() => setIsRefreshing(false));
  }, [fetchForexRates, fetchGems]);



  const fetchWithRetry = useCallback(async <T,>(
    fetchFn: () => Promise<T>,
    category: CategoryKey
  ): Promise<T> => {
    const state = categoryStates[category];
    
    try {
      return await fetchFn();
    } catch (error) {
      if (state.retryCount < API_CONFIG.retryAttempts) {
        await new Promise(resolve => 
          setTimeout(resolve, API_CONFIG.retryDelay * (state.retryCount + 1))
        );
        
        updateCategoryState(category, { 
          retryCount: state.retryCount + 1 
        });
        
        return fetchWithRetry(fetchFn, category);
      }
      throw error;
    }
  }, [categoryStates, updateCategoryState]);



  // Event Handlers
  const handleRefresh = useCallback((category: CategoryKey) => {
    if (isRefreshing) return;
    setIsRefreshing(true);
    
    const fetchFn = category === 'forex' ? fetchForexRates : fetchGems;
    fetchFn().finally(() => {
      setTimeout(() => setIsRefreshing(false), 500);
    });
  }, [fetchForexRates, fetchGems, isRefreshing]);



  // Render Functions
  const renderCategoryContent = useCallback((
    category: CategoryKey,
    assets: Asset[]
  ) => {
    const state = categoryStates[category];

    if (state.isLoading && assets.length === 0) {
      return <LoadingCard />;
    }

    if (state.error) {
      return (
        <ErrorCard 
          error={state.error} 
          onRetry={() => handleRefresh(category)} 
        />
      );
    }
    return (
      <AnimatePresence>
        {category === 'gems' ? (
          <Styled.GemsGrid>
            {assets.slice(0, 6).map(asset => (
              <AssetCard key={asset.symbol} asset={asset} />
            ))}
          </Styled.GemsGrid>
        ) : (
          <Styled.AssetGrid>
            {assets.map(asset => (
              <AssetCard key={asset.symbol} asset={asset} />
            ))}
          </Styled.AssetGrid>
        )}
      </AnimatePresence>
    );
  }, [categoryStates, handleRefresh]);



  return (
    <Styled.PageContainer>
      <SimpleHeader dashboard={true} extraMargin={false} />

      <Timer />
      <ScrollingBanner gems={gems} />

      <Styled.DashboardGrid>
        {(['gems', 'forex'] as const).map(category => (
          <Styled.CategorySection key={category}>
            <Styled.CategoryHeader>
              <Styled.CategoryTitle>
                {category.charAt(0).toUpperCase() + category.slice(1)}
                <Styled.CategoryUnderline />
              </Styled.CategoryTitle>
              <Styled.RefreshButton
                onClick={() => handleRefresh(category)}
                disabled={isRefreshing}
                className={isRefreshing ? 'spinning' : ''}
                title={categoryStates[category].lastUpdated?.toLocaleTimeString()}
              >
                <RefreshIcon />
              </Styled.RefreshButton>
            </Styled.CategoryHeader>
            
            {renderCategoryContent(
              category, 
              category === 'forex' ? forexRates : gems
            )}
          </Styled.CategorySection>
        ))}

        <NoteTaker />
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '2rem' }}>
          <Petto />
          <Pomodoro />
        </div>
      </Styled.DashboardGrid>
    </Styled.PageContainer>
  );
};



export default memo(Dashboard);