import { Link } from "react-router-dom";
import styled from "styled-components";

import { keyframes } from "styled-components";
import theme from "../../styles/theme";
import { FaCrown } from "react-icons/fa";

export const HeaderWrapper = styled.header<{ $setmargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 1000;
  margin-top: ${props => props.$setmargin ? '2rem' : '0'};
`;

export const FloatingLogo = styled(Link)<{ $extramargin: boolean }>`
  width: ${props => props.$extramargin ? '120px' : '100px'};
  height: auto;
  z-index: 1000;
  @media (max-width: 768px) {
    left: 1rem;
    width: '80px'};
  }
`;

export const LogoImage = styled.img`
  width: 100%;
  height: auto;
`;

export const DashboardButton = styled.button`
  background-color: ${theme.colors.primaryYellow};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 15px;
  z-index: 1000;
`;

export const twinkleAnimation = keyframes`
  0%, 100% { opacity: 0.2; transform: scale(0.8) rotate(${Math.random() * 5}deg); }
  25% { opacity: 0.6; transform: scale(0.9) rotate(${40 + Math.random() * 10}deg); }
  50% { opacity: 1; transform: scale(1) rotate(${85 + Math.random() * 10}deg); }
  75% { opacity: 0.6; transform: scale(0.9) rotate(${40 + Math.random() * 10}deg); }
`;

export const UpgradeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primaryYellow};
  color: #333;
  border: 1px solid black;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  padding: 0.6rem 1rem;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: ${theme.colors.primaryYellowHover};
  }
`;

export const ButtonContent = styled.span`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
`;

export const StarWrapper = styled.div<{ top: string; left: string; size: string; duration: number; delay: number }>`
  position: absolute;
  color: ${theme.colors.lightYellow};
  opacity: 0;
  pointer-events: none;
  top: ${props => props.top};
  left: ${props => props.left};
  font-size: ${props => props.size};
  animation: ${twinkleAnimation} ${props => props.duration}s ease-in-out infinite ${props => props.delay}s;
`;

export const CrownIcon = styled(FaCrown)`
  font-size: 2.5rem;
  color: gold;
  filter: drop-shadow(2px 2px 0 black) drop-shadow(-1px -1px 0 black);
`;

export const ProUserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BackButton = styled.button`
  margin-top: 0.5rem;
  vertical-align: middle;
  height: 30px;
  background-color: ${theme.colors.primaryYellow};
`;