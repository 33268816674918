import { ForexAsset, MarketAsset } from "./types";

// Sample data - oppdatert: 29.10.2024
export const STOCKS: MarketAsset[] = [
  { Symbol: 'TSLA', Last: 250.00 },
  { Symbol: 'MSTR', Last: 260.00 },
  { Symbol: 'CLSK', Last: 11.00 }
];

export const CRYPTO: MarketAsset[] = [
  { Symbol: 'BTC', Last: 71000.00 },
  { Symbol: 'ETH', Last: 2600.00 },
  { Symbol: 'SOL', Last: 180.00 },
];

export const FOREX: ForexAsset[] = [
  { symbol: 'USD/NOK', price: 11.00 },
  { symbol: 'USD/JPY', price: 153.00 },
  { symbol: 'USD/EUR', price: 0.90 },
];

export const formatPrice = (price: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price);
};