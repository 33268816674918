import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface PopupContainerProps {
  show: boolean;
  type: 'error' | 'info' | 'warning';
  position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const PopupContainer = styled.div<PopupContainerProps>`
  position: fixed;
  pointer-events: none;
  ${props => {
    switch (props.position) {
      case 'top-left':
        return 'top: 6rem; left: 1rem;';
      case 'top-right':
        return 'top: 6rem; right: 1rem;';
      case 'bottom-left':
        return 'bottom: 2rem; left: 1rem;';
      case 'bottom-right':
      default:
        return 'bottom: 2rem; right: 1rem;';
    }
  }}
  background-color: ${props => props.type === 'error' ? '#FFB3BA' : props.type === 'warning' ? '#FFD7BE' : '#BAFFC9'}; // Changed warning background to pastel
  color: ${props => props.type === 'error' ? '#8B0000' : props.type === 'warning' ? '#FF4500' : '#006400'};
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
  border: 2px solid ${props => props.type === 'error' ? '#FF69B4' : props.type === 'warning' ? '#FF6347' : '#77DD77'};
  animation: ${props => props.show ? fadeInUp : fadeOutDown} 0.3s ease-in-out;
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  z-index: 1000;
`;

export interface InfoPopupProps {
  message: string;
  type?: 'error' | 'info' | 'warning';
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  timeout? : number;
}

const InfoPopup: React.FC<InfoPopupProps> = ({
  message,
  type = 'info',
  position = 'bottom-right',
  timeout = 2500
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, timeout);

    return () => clearTimeout(timer);
  }, [timeout]);

  if (!show) return null;

  return (
    <PopupContainer show={show} type={type} position={position}>
      {message}
    </PopupContainer>
  );
};

export default InfoPopup;