import apiService from './baseApi';



export const fetchFollowing = async (userId: string) => {
    const response = await apiService.get(`/following/${userId}`);
    return response;
};



export const followUser = async (userId: string, usernameToFollow: string) => {
    const response = await apiService.post(`/following/${userId}`, { usernameToFollow });
    return response;
};



export const unfollowUser = async (userId: string, usernameToUnfollow: string) => {
    const response = await apiService.delete(`/following/${userId}/${usernameToUnfollow}`);
    return response;
};



export const unfollowAllUsers = async (userId: string) => {
    await apiService.delete(`/following/${userId}`);
};