import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../contexts/AuthContext';
import { useInfoPopup } from '../../../hooks/useInfoPopup';
import { Gem, PredictionSubmit, PricePrediction, PricePredictionItem } from '../types';
import { fetchGemById, voteGem } from '../../../api/gemApi';
import { getPricePredictions, addPricePrediction, addPredictionVote } from '../../../api/predictionApi';
import { Loader, Sparkles, DollarSign, ArrowLeft, Tag, LineChart, Info } from 'lucide-react';
import { PredictionGrid } from './PredictionGrid';



import InfoPopup from '../../../components/common/popup/InfoPopup';
import PredictionInputBox from '../gemPage/PredictionInputBox';
import GemHeader from '../gemPage/GemHeader';



import * as Styled from './gemPageStyles';
import PredictionHistory from '../predictionHistory/PredictionHistory';
import { formatDateSimple } from '../utils/util';
import theme from '../../../styles/theme';



const GemPage = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const { user, handleFollowChange, following } = useAuth();
  const { t } = useTranslation();
  const { popups, showErrorPopup, showInfoPopup, showWarningPopup } = useInfoPopup();
  const [gem, setGem] = useState<Gem | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pricePredictions, setPricePredictions] = useState<PricePredictionItem | null>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const hasScrolled = useRef(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);



  const isFollowing = (username: string) => {
    return following?.some((follow) => follow.username === username) || false;
  };
  


  // Scroll to top of page when component mounts
  useEffect(() => {
    if (!hasScrolled.current) {
      window.scrollTo(0, 0);
      hasScrolled.current = true;
    }
  }, [pageId]);



  useEffect(() => {
    const fetchGemData = async () => {
      setIsLoading(true);
      try {
        if (pageId) {
          const fetchedGem = await fetchGemById(pageId);
          if (fetchedGem) {
            setGem(fetchedGem);
            const fetchedPredictions = await getPricePredictions(fetchedGem.id);
            setPricePredictions(Array.isArray(fetchedPredictions) ? fetchedPredictions[0] : fetchedPredictions);
            
          } else {
            console.error('Fetched gem is null');
            throw new Error('Fetched gem is null');
          }
        }
      } catch (error) {
        showErrorPopup(t('discovery.gemPage.fetchError'));
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchGemData();
  }, [pageId, showErrorPopup, t]);



  const handleNewPricePrediction = async (prediction: PredictionSubmit) => {
    if (prediction.predictionValue < 0.000000001 || prediction.predictionValue > 1000000000) {
      showErrorPopup(t('discovery.gemPage.predictions.invalidPrediction'));
      return;
    }

    if (!user || !gem) {
      showErrorPopup(t('discovery.gemPage.userOrGemNotFound'));
      return;
    }
    // Check if the user has already made a prediction for this gem
    if (pricePredictions && pricePredictions.predictions.filter(p => p.username === user.username).length >= 3) {
      showErrorPopup(t('discovery.gemPage.predictions.maxPredictionsReached'));
      return;
    }

    try {
      const pricePrediction: PricePrediction = {
        gemId: gem.id,
        username: user.username,
        userId: user.id,
        value: prediction.predictionValue,
        startTime: new Date(prediction.startDate),
        endTime: new Date(prediction.endDate),
        credit: prediction.credit
      };

      await addPricePrediction(pricePrediction);

      // Refresh the price predictions
      const updatedPredictions = await getPricePredictions(gem.id);
      setPricePredictions(Array.isArray(updatedPredictions) ? updatedPredictions[0] : updatedPredictions);

      showInfoPopup(t('discovery.gemPage.predictions.addedSuccess'));
    } catch (error) {
      showErrorPopup(t('discovery.gemPage.predictions.addError'));
    }
  };



  const handlePredictionVote = async (predictionId: string, predictionUserId: string, predictionUser: string, voteType: 'up' | 'down') => {
    if (!user || !gem) {
      showErrorPopup(t('discovery.popup.loginToVote'));
      return;
    }
    try {
      // Find the prediction being voted on
      const votedPrediction = pricePredictions?.predictions.find(p => p._id === predictionId);
      if (!votedPrediction) {
        throw new Error('Prediction not found');
      }
  
      // Calculate the new score for the voted prediction
      const newScore = (votedPrediction.upvote + (voteType === 'up' ? 1 : 0)) - 
                        (votedPrediction.downvote + (voteType === 'down' ? 1 : 0));
  
      // Compare with the current top voted prediction
      const currentTopScore = gem.topVotedPrediction 
        ? gem.topVotedPrediction.upvote - gem.topVotedPrediction.downvote 
        : -Infinity;
      const willUpdateTopPrediction = newScore > currentTopScore;
  
      // Call the API with the update flag
      await addPredictionVote(predictionId, predictionUser, gem.id, voteType === 'up' ? 1 : -1, willUpdateTopPrediction, user.id);
      showInfoPopup(t('discovery.popup.voteRegistered'));
  
      // Optimistically update the UI
      setPricePredictions(prevPredictions => {
        if (!prevPredictions) return null;
        const updatedPredictions = prevPredictions.predictions.map(pred => 
          pred._id === predictionId 
            ? {
                ...pred, 
                upvote: voteType === 'up' ? pred.upvote + 1 : pred.upvote,
                downvote: voteType === 'down' ? pred.downvote + 1 : pred.downvote
              }
            : pred
        );
  
        // Update the average prediction
        const newAveragePrediction = calculateAveragePrediction(updatedPredictions);
  
        // Update the gem's top voted prediction if necessary
        if (willUpdateTopPrediction) {
          setGem(prevGem => {
            if (!prevGem) return null;
            return {
              ...prevGem,
              topVotedPrediction: {
                ...votedPrediction,
                upvote: voteType === 'up' ? votedPrediction.upvote + 1 : votedPrediction.upvote,
                downvote: voteType === 'down' ? votedPrediction.downvote + 1 : votedPrediction.downvote,
                startTime: new Date(votedPrediction.startTime),
                endTime: new Date(votedPrediction.endTime),
                createdAt: new Date(votedPrediction.createdAt)
              }
            };
          });
        }

        return {
          ...prevPredictions,
          predictions: updatedPredictions,
          averagePrediction: newAveragePrediction
        };
      });
  
    } catch (error) {
      if (error instanceof Error && error.message.includes('already voted')) {
        showErrorPopup(t('discovery.popup.alreadyVoted'));
      } else {
        showErrorPopup(t('discovery.popup.voteError'));
      }
  
      // Refresh the predictions from the server in case of an error
      const updatedPredictions = await getPricePredictions(gem.id);
      setPricePredictions(Array.isArray(updatedPredictions) ? updatedPredictions[0] : updatedPredictions);
    }
  };



  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);



  const calculateAveragePrediction = (predictions: any[]) => {
    if (!predictions || predictions.length === 0) return 0;
    const sum = predictions.reduce((acc, pred) => acc + pred.value, 0);
    return sum / predictions.length;
  };
  


  const LoadingSpinner = () => (
    <div className="flex items-center justify-center">
      <Loader className="animate-spin" />
    </div>
  );



  const SkeletonText = ({ width = 'w-full' }: { width?: string }) => (
    <div className={`h-4 bg-gray-200 rounded ${width} animate-pulse`}></div>
  );



  const handleVote = async (assetId: string, voteType: 'positive' | 'negative') => {
    if (!user) {
      showErrorPopup(t('discovery.loginToVote'));
      return;
    }
    try {
      const voteTypeValue = voteType === 'positive' ? 1 : voteType === 'negative' ? -1 : 0;
      await voteGem(user.id, assetId, voteTypeValue);
      showInfoPopup(t('discovery.popup.voteRegistered'));

    } catch (error) {
      if (error instanceof Error && error.message.includes('already voted')) {
        showErrorPopup(t('discovery.popup.alreadyVoted'));
      } else {
        showErrorPopup(t('portfolio.popup.refreshError'));
      }
    }
  };



  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setShowTooltip(false);
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  
  return (
    <Styled.PageContainer 
    isempty={(pricePredictions && pricePredictions.predictions.length === 0) ? 'true' : 'false'}
    >
      <Styled.ContentContainer>
        <Styled.BackButton to="/gems">
          <ArrowLeft size={16} />
          {t('discovery.gemPage.backToDiscovery')}
        </Styled.BackButton>
        
        <GemHeader
          gem={gem}
          handleVote={handleVote}
          SkeletonText={SkeletonText}
        />

        <Styled.GemSection>
          <Styled.PredictionSummaryContainer>
            {isLoading ? (
              <>
                <Styled.PredictionSummary>
                  <Styled.PredictionLabel>Average Prediction:</Styled.PredictionLabel>
                  <SkeletonText />
                </Styled.PredictionSummary>
                <Styled.PredictionSummary>
                  <Styled.PredictionLabel>Top Voted Prediction:</Styled.PredictionLabel>
                  <SkeletonText />
                </Styled.PredictionSummary>
              </>
            ) : pricePredictions && pricePredictions.predictions.length > 0 ? (
              <>
              {gem && gem.topVotedPrediction && gem.topVotedPrediction.upvote > 0 && (
                <Styled.TopVotedPredictionSummaryBox>
                  <Styled.TopVotedPredictionLabel>Top Voted Prediction</Styled.TopVotedPredictionLabel>
                    <Styled.TopVotedPredictionValue>
                      <DollarSign size={28} />
                      {gem.topVotedPrediction.value.toLocaleString(undefined, 
                        { minimumFractionDigits: 0, maximumFractionDigits: 0 })} <Sparkles size={16} style={{marginLeft: '0.5rem'}}/>
                    </Styled.TopVotedPredictionValue>
                    <Styled.TopVotedPredictionDate>
                      {formatDateSimple(new Date(gem.topVotedPrediction.startTime))}
                      {' - '}
                      {formatDateSimple(new Date(gem.topVotedPrediction.endTime))}
                    </Styled.TopVotedPredictionDate>
                    <Styled.TopVotedPredictionUser>
                      {gem.topVotedPrediction?.credit || gem.topVotedPrediction?.username}
                      {(gem?.topVotedPrediction?.username && !gem.topVotedPrediction?.credit && 
                      gem.topVotedPrediction?.username !== user?.username) ? (
                        <Styled.FollowUserButton 
                          onClick={() => handleFollowChange(
                            {userId: gem.topVotedPrediction?.userId || '', username: gem.topVotedPrediction?.username || ''}
                          )}
                        >
                          {isFollowing(gem.topVotedPrediction.username) ? t('following.remove') : t('following.add')}
                        </Styled.FollowUserButton>
                      ) 
                      : 
                      <>
                        <Styled.CreditedDisplay onClick={() => showWarningPopup(t('following.creditedError'))}>
                          {t('discovery.gemPage.predictions.credited')}
                        </Styled.CreditedDisplay>

                        <div 
                          ref={tooltipRef}
                          style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            position: 'relative',
                            padding: '5px',  // Add padding to increase hit area
                          }}
                          onMouseEnter={() => {
                            if (tooltipTimeoutRef.current) {
                              clearTimeout(tooltipTimeoutRef.current);
                            }
                            setShowTooltip(true);
                          }}
                          onMouseLeave={() => {
                            tooltipTimeoutRef.current = setTimeout(() => {
                              setShowTooltip(false);
                            }, 300);  // 300ms delay before hiding
                          }}
                        >
                          <Info 
                            size={18} 
                            style={{ 
                              cursor: 'help', 
                              color: theme.colors.primaryPinkHover 
                            }}
                          />
                          {showTooltip && (
                            <Styled.Tooltip
                              onMouseEnter={() => {
                                if (tooltipTimeoutRef.current) {
                                  clearTimeout(tooltipTimeoutRef.current);
                                }
                              }}
                              onMouseLeave={() => {
                                tooltipTimeoutRef.current = setTimeout(() => {
                                  setShowTooltip(false);
                                }, 300);
                              }}
                              onClick={() => {
                                setShowTooltip(!showTooltip);
                              }}
                            >
                              {t('discovery.gemPage.predictions.creditedExplanation')}
                            </Styled.Tooltip>
                          )}
                        </div>
                      </>
                      }
                    </Styled.TopVotedPredictionUser>
                </Styled.TopVotedPredictionSummaryBox>
              )}

                <Styled.AveragePredictionSummaryBox>
                  <Styled.AveragePredictionLabel>Average Prediction</Styled.AveragePredictionLabel>
                  <Styled.AveragePredictionValue>
                    <DollarSign size={28} />
                    {pricePredictions.averagePrediction.toLocaleString(undefined, 
                      { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                  </Styled.AveragePredictionValue>
                  <Styled.TotalPredictionsCounter>
                    {t('discovery.gemPage.predictions.totalPredictions')}: <b>{pricePredictions.predictions.length}</b>
                  </Styled.TotalPredictionsCounter>
                </Styled.AveragePredictionSummaryBox>
              </>
            ) : 
            <Styled.NoPredictionsContainer>
              {t('discovery.gemPage.predictions.noPredictionsContainer')}
            </Styled.NoPredictionsContainer>
            }

            <PredictionInputBox 
              onSubmit={(prediction: PredictionSubmit) => 
                handleNewPricePrediction(prediction)
              }
            />
          </Styled.PredictionSummaryContainer>

          {pricePredictions && pricePredictions.predictions.length > 0 && (
            <>
              <Styled.GemChartSection>
                <Styled.SectionTitle>
                  <LineChart size={20} />
                  {t('discovery.gemPage.predictions.futureChart')}
                </Styled.SectionTitle>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <PredictionHistory pricePredictions={pricePredictions} />
                )}
              </Styled.GemChartSection>

            <Styled.GemSection>
              <Styled.SectionTitle>
                <DollarSign size={20} />
                {t('discovery.gemPage.predictions.title')}
              </Styled.SectionTitle>
              {isLoading ? 
                <LoadingSpinner /> 
              : pricePredictions && 
                <PredictionGrid 
                  pricePredictions={pricePredictions}
                  handlePredictionVote={handlePredictionVote}
                />}
              </Styled.GemSection>
            </>
          )}
          
        </Styled.GemSection>

        <Styled.GemSection>
          <Styled.SectionTitle>
            <Tag size={20} />
            {t('discovery.gemPage.tags')}
          </Styled.SectionTitle>
          <Styled.TagContainer>
            {gem ? gem.tags.map((tag, index) => (
              <Styled.Tag key={index}>{tag}</Styled.Tag>
            )) : (
              <>
                <SkeletonText width="w-20" />
                <SkeletonText width="w-24" />
                <SkeletonText width="w-16" />
              </>
            )}
          </Styled.TagContainer>
        </Styled.GemSection>
        
        {popups.map((popup) => (
          <InfoPopup
            key={popup.id}
            message={popup.message}
            type={popup.type}
            position={popup.position}
            timeout={popup.duration}
          />
        ))}
      </Styled.ContentContainer>
    </Styled.PageContainer>
  );
};

export default GemPage;