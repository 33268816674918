import { motion } from "framer-motion";
import styled from "styled-components";
import theme from "../../../styles/theme";
import LandingNavItem from "../components/LandingNavItem";

export const IntroSectionStyle = styled(motion.section)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  background: #fff;
  border: 2px solid ${theme.colors.lightRed};
  border-radius: 6px;
  box-shadow: 0 4px 20px rgba(255, 51, 51, 0.15);
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;

  @media (min-width: 768px) {
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
  }
`;

export const IntroContent = styled.div`
  flex: 1;
  z-index: 1;
  margin-bottom: 1rem;
`;

export const IntroTitle = styled(motion.h2)`
  font-size: 2.8rem;
  color: #ff3333;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;

  @media (min-width: 1280px) {
    white-space: nowrap;
  }

  @media (max-width: 768px) {
    font-size: 2.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

export const IntroDescription = styled(motion.p)`
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #333;
  max-width: 700px;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

export const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

export const FeatureItem = styled(motion.li)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #333;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 5px;
  }
`;

export const FeatureIcon = styled.span`
  margin-right: 10px;
  color: #ff3333;
  font-size: 1.2rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const StyledNavButton = styled(LandingNavItem)`
  border: 2px solid #000;
  background-color: #ff3333;
  color: white;
  font-size: 1.2rem;
  padding: 15px 30px;
  border-radius: 6px;
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(255, 51, 51, 0.3);
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  
  &:hover {
    background-color: #e60000;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const StyledPortfolioButton = styled(LandingNavItem)`
  border: 2px solid #000;
  background-color: ${theme.colors.primaryPinkHover};
  color: white;
  font-size: 1.2rem;
  padding: 15px 30px;
  border-radius: 6px;
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(255, 51, 51, 0.3);
  transition: all 0.3s ease;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  
  &:hover {
    background-color: ${theme.colors.primaryPink};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const PandaContainer = styled(motion.div)`
  position: relative;
  width: 300px;
  height: 300px;

  @media (max-width: 768px) {
    align-self: flex-end;
    margin-top: 10px;
  }
`;

export const PandaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scaleX(-1);

  @media (max-width: 768px) {
    transform: scaleX(-1);
    margin: auto;
  }
`;

export const AdditionalInfo = styled(motion.p)`
  font-size: 1rem;
  color: #555;
  margin-top: 2rem;
  text-align: left;
  line-height: 1.5;
  max-width: 600px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-top: 1rem;
  }
`;

export const FOMOText = styled(motion.p)`
  font-size: 1rem;
  color: #333;
  margin-top: 1rem;
  text-align: left;
  font-weight: bold;
  max-width: 600px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const StyledMoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 120px; 
  color: white; 
  background-color: ${theme.colors.primaryPinkHover};
  text-decoration: none;
  border-radius: 6px;
  border: 2px dotted black;
  transition: all 0.3s ease;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
  white-space: nowrap;
  box-shadow: 0 4px 10px rgba(255, 51, 51, 0.3);
  font-family: 'Roboto', sans-serif;
  min-width: 200px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: ${theme.colors.primaryPink};
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 6px 24px;
    width: 100%;
    box-sizing: border-box;
  }
`;