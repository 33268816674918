import axios from "axios";
import { CRYPTO, FOREX, STOCKS } from "../pages/dashboard/util";
import { CryptoMarketAsset, CryptoMarketResponse, ForexAsset, MarketAsset, MarketResponse } from "../pages/dashboard/types";
import { fetchGemsForDashboard } from "./gemApi";
import { Gem } from "../pages/discovery/types";



export const getDashboardAssets = async (): Promise<Gem[]> => {
    const gems = await fetchGemsForDashboard();
    return gems;
};



export const getDashboardForexDirect = async (testing: boolean): Promise<ForexAsset[]> => {
  if (testing) {
    console.log('[TEST] Testing forex rates');
    return FOREX;
  }
  else {
    console.log('[PROD] Fetching forex rates');
    const key = '161085b89d13be6f85136272948e561d';
    const response = await axios.get(
      `https://api.forexrateapi.com/v1/latest?api_key=${key}&base=USD&currencies=NOK,JPY,EUR`);
    const data = response.data;
   
    if (!data.success) {
        throw new Error('Failed to fetch forex rates');
    }

    return Object.entries(data.rates).map(([currency, rate]) => ({
        symbol: `${currency}/USD`,
        price: Number(rate)
    }));
  }
};



export const getDashboardStocksDirect = async (testing: boolean): Promise<MarketAsset[]> => {
  if (testing) {
    console.log('[TEST] Testing stocks');
    return STOCKS;
  }
  else {
    console.log('[PROD] Fetching stocks');
    const response = await axios.get<MarketResponse>(`https://ql.stocktwits.com/batch?symbols=TSLA,MSTR,CLSK`);
    const data = response.data;
   
    return Object.values(data)
      .filter((item): item is MarketAsset => 'Last' in item)
      .map(item => ({
        Symbol: item.Symbol,
        Last: item.Last
      }));
  }
};



export const getDashboardCryptoDirect = async (testing: boolean): Promise<CryptoMarketAsset[]> => {
  if (testing) {
    console.log('[TEST] Testing crypto');
    return CRYPTO;
  }
  else {
    console.log('[PROD] Fetching crypto');
    const response = await axios.get<CryptoMarketResponse>(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC,ETH,SOL&tsyms=USD`);
    const data = response.data;
   
    return Object.entries(data).map(([symbol, price]) => ({
        Symbol: symbol,
        Last: price.USD
    }));
  }
};