// globalStyles.ts
import { createGlobalStyle } from 'styled-components';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', sans-serif;
    background-color: ${theme.colors.background.primary};
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.6;
    width: 100vw;
    overflow-x: hidden;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
`;

export default GlobalStyle;