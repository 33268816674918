import { PredictionResponse, PricePrediction } from '../pages/discovery/types';
import apiService from './baseApi';



// POST add price prediction
export const addPricePrediction = async (data: PricePrediction): Promise<{ success: boolean; error?: string }> => {
  try {
    await apiService.post<{ success: boolean; error?: string }>('/predictions/add', data);
    return { success: true };
  } catch (error) {
    if (error instanceof Error) {
      return { success: false, error: error.message || 'An error occurred while adding price prediction' };
    }
    return { success: false, error: 'An unexpected error occurred' };
  }
};



// GET price predictions for a gem
export const getPricePredictions = async (gemId: string): Promise<PredictionResponse> => {
  try {
    const response = await apiService.get<PredictionResponse>(`/predictions/${gemId}`);
    return response;
  } catch (error) {
    // No predictions available yet
    return { 
      gemId: gemId,
      averagePrediction: 0,
      lastUpdated: '',
      predictions: []
    };
  }
};



// GET votes per prediction
export const getPredictionVotes = async (gemId: string): Promise<any> => {
  try {
    const response = await apiService.get<any>(`/predictions/votes/${gemId}`);
    return response;
  } catch (error) {
    console.error('Error getting gem votes:', error);
    throw error;
  }
};



// POST add prediction vote
export const addPredictionVote = async (predictionId: string, username: string, gemId: string, vote: 1 | -1, willUpdateTopPrediction: boolean, userId: string): Promise<void> => {
  try {
    await apiService.post<void>('/predictions/add-vote', {
      predictionId,
      username,
      gemId,
      vote,
      willUpdateTopPrediction,
      userId
    });
    
  } catch (error) {
    console.error('Error casting vote:', error);
    throw error;
  }
};