import styled, { keyframes } from 'styled-components';
import theme from '../../styles/theme';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
`;

export const ModalContent = styled.div`
  background-color: ${theme.colors.background.paper};
  padding: 2rem;
  border: 1px solid black;
  border-radius: 6px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.3s ease-out;
`;

export const ModalTitle = styled.h2`
  text-align: center;
  color: ${theme.colors.text.primary};
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const StyledButton = styled.button`
  background-color: ${theme.colors.primaryRed};
  color: ${theme.colors.text.primary};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${theme.colors.background.dark};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${theme.colors.background.paper};
  }
`;

export const ErrorMessage = styled.p`
  color: ${theme.colors.primaryRed};
  background-color: ${theme.colors.lightRed};
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
`;

export const SuccessMessage = styled.p`
  color: ${theme.colors.primaryOrange};
  background-color: ${theme.colors.lightOrange};
  padding: 1rem;
  border-radius: 6px;
  margin-top: 1rem;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem auto;
`;

export const SpinnerRing = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid ${theme.colors.primaryPink};
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;