import { ThumbsDown, ThumbsUp } from "lucide-react";
import { PricePredictionItem } from "../types";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";



import { 
  PredictionGridContainer, 
  PredictionGridItem, 
  PredictionValueGrid, 
  PredictionDates, 
  PredictionUserId, 
  VoteCount, 
  VoteButtons,
  VoteButton
} from './gemPageStyles';
import { formatDateSimple } from "../utils/util";



interface PredictionGridProps {
  pricePredictions: PricePredictionItem;
  handlePredictionVote: (predictionId: string, predictionUserId: string, username: string, vote: 'up' | 'down') => void;
}
  


export const PredictionGrid = ({ pricePredictions, handlePredictionVote }: PredictionGridProps) => {
  const { user } = useAuth();
  const { t } = useTranslation();


  
  if (!pricePredictions || pricePredictions.predictions.length === 0) {
    return <div>{t('discovery.gemPage.predictions.noPredictions')}</div>;
  }



  const sortedPredictions = [...pricePredictions.predictions].sort((a, b) => {
    if (user && a.username === user.username) return -1;
    if (user && b.username === user.username) return 1;
    return 0;
  });



  const gridItems = sortedPredictions.slice(0, 24).map((prediction, index) => (
    <PredictionGridItem key={index} userowned={user && prediction.username === user.username ? 'true' : 'false'}>
      <PredictionValueGrid>${prediction.value.toLocaleString(undefined, { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: prediction.value.toString().split('.')[1]?.length || 0
       })}</PredictionValueGrid>
      <PredictionDates>
        {formatDateSimple(new Date(prediction.startTime))} - {formatDateSimple(new Date(prediction.endTime))}
      </PredictionDates>
      <PredictionUserId>
        {prediction.credit ? prediction.credit : prediction.username}
      </PredictionUserId>
      <VoteCount>
        <span>up: {prediction.upvote} / down: {prediction.downvote}</span>
      </VoteCount>
      <VoteButtons>
        <VoteButton onClick={() => handlePredictionVote(prediction._id, prediction.userId, prediction.username, 'up')} color="orange">
          <ThumbsUp size={16} />
        </VoteButton>
        <VoteButton onClick={() => handlePredictionVote(prediction._id, prediction.userId, prediction.username, 'down')} color="blue">
          <ThumbsDown size={16} />
        </VoteButton>
      </VoteButtons>
    </PredictionGridItem>
  ));

  return <PredictionGridContainer>{gridItems}</PredictionGridContainer>;
};