import { memo } from 'react';
import { motion } from 'framer-motion';

import { formatPrice } from "./util";
import { Asset } from './types';

import * as Styled from './dashboardStyles';



export const AssetCard = memo(({ asset }: { asset: Asset }) => {
  const { symbol, price } = asset;

  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      whileHover={{ scale: 1.02 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <Styled.AssetCard>
        <Styled.AssetGlow />
        <Styled.AssetHeader>
          <Styled.AssetSymbol>{symbol}</Styled.AssetSymbol>
        </Styled.AssetHeader>
        <Styled.AssetPrice>
          {formatPrice(price)}
        </Styled.AssetPrice>
      </Styled.AssetCard>
    </motion.div>
  );
});

AssetCard.displayName = 'AssetCard';