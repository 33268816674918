import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsUp, ThumbsDown, Sparkles, Users, DollarSign } from 'lucide-react';
import { Gem } from '../types';

import * as StyledHeader from './gemHeaderStyles';
import { formatDate } from '../utils';

interface SkeletonTextProps {
  width: string;
}

interface GemHeaderProps {
  gem: Gem | null;
  handleVote: (gemId: string, voteType: 'positive' | 'negative') => void;
  SkeletonText: React.FC<SkeletonTextProps>;
}

const GemHeader = ({ gem, handleVote, SkeletonText }: GemHeaderProps) => {
  const { t } = useTranslation();

  const decimalPoints = gem ? gem.price.toString().split('.')[1]?.length || 0 : 0;

  return (
    <StyledHeader.GemHeader>
      <StyledHeader.GemDetailsGrid>
        <StyledHeader.GemName>
          {gem ? gem.name : <SkeletonText width="w-full" />}
        </StyledHeader.GemName>
        <StyledHeader.GemPrice>
          {gem ? (
            <>
              <DollarSign size={28} />
              {gem.price.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: decimalPoints })}
            </>
          ) : (
            <SkeletonText width="w-1/3" />
          )}
        </StyledHeader.GemPrice>
        
        <StyledHeader.GemInfoContainerStart>
          <StyledHeader.GemSymbol>
            {gem ? gem.symbol : <SkeletonText width="w-1/2" />}
          </StyledHeader.GemSymbol>
          <StyledHeader.LastUpdated>
            {gem && `${t('discovery.gemPage.created')}: ${formatDate(gem.createdAt, 'createdAt')}`}
          </StyledHeader.LastUpdated>
        </StyledHeader.GemInfoContainerStart>

        <StyledHeader.GemInfoContainerEnd>
          <StyledHeader.GemStatLabel>
            <Users size={16} />
            {t('discovery.gemPage.watchers')}
            <StyledHeader.GemStatValue>
              {gem ? gem.watchers : <SkeletonText width="w-1/2" />}
            </StyledHeader.GemStatValue>
          </StyledHeader.GemStatLabel>
          {gem?.fromUsername && (
            <StyledHeader.GemCreator>
              {`${t('discovery.gemPage.madeBy')} ${gem.fromUsername}`}
            </StyledHeader.GemCreator>
          )}
        </StyledHeader.GemInfoContainerEnd>

      </StyledHeader.GemDetailsGrid>
      
      {gem && (
        <StyledHeader.VoteBox score={gem.score}>
          <StyledHeader.VotingSection>
            <StyledHeader.VoteButton onClick={() => handleVote(gem.id, 'positive')}>
              <ThumbsUp size={20} />
              <StyledHeader.VoteCount>{gem.votes?.positive}</StyledHeader.VoteCount>
            </StyledHeader.VoteButton>
            <StyledHeader.ScoreDisplay score={gem.score}>
              Score: {gem.score} {gem.score > 0 ? <Sparkles size={16} /> : null}
            </StyledHeader.ScoreDisplay>
            <StyledHeader.VoteButton onClick={() => handleVote(gem.id, 'negative')}>
              <ThumbsDown size={20} />
              <StyledHeader.VoteCount>{gem.votes?.negative}</StyledHeader.VoteCount>
            </StyledHeader.VoteButton>
          </StyledHeader.VotingSection>
        </StyledHeader.VoteBox>
      )}
    </StyledHeader.GemHeader>
  );
};

export default GemHeader;