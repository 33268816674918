import { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const TimerContainer = styled.div`
  background: ${theme.colors.background.paper};
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 6px;
  padding: 16px 24px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TimeSection = styled.div`
  display: flex;
  gap: 24px;
`;

const TimeBlock = styled.div`
  text-align: center;
`;

const TimeText = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #333;
`;

const Label = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 4px;
`;

const CountdownSection = styled.div`
  text-align: right;
`;

const Timer = () => {
  const [times, setTimes] = useState({
    oslo: '',
    tokyo: '',
  });
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const updateTimes = () => {
      const now = new Date();
      
      const osloTime = now.toLocaleTimeString('en-US', {
        timeZone: 'Europe/Oslo',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
      
      const tokyoTime = now.toLocaleTimeString('en-US', {
        timeZone: 'Asia/Tokyo',
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      // Calculate time until US market opens (9:30 AM EST) or closes (4:00 PM EST)
      const nyDate = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      const marketOpening = new Date(nyDate);
      marketOpening.setHours(9, 30, 0, 0);
      const marketClosing = new Date(nyDate);
      marketClosing.setHours(16, 0, 0, 0);
      
      let countdownText = '';
      if (nyDate >= marketOpening && nyDate < marketClosing) {
        // Market is open
        const diff = marketClosing.getTime() - nyDate.getTime();
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        countdownText = `Market Open - Closes in ${hours}h ${minutes}m ${seconds}s`;
      } else {
        // Market is closed
        if (nyDate >= marketClosing) {
          marketOpening.setDate(marketOpening.getDate() + 1);
        }
        const diff = marketOpening.getTime() - nyDate.getTime();
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
        countdownText = `Until US Market Opens: ${hours}h ${minutes}m ${seconds}s`;
      }
      
      setTimes({ oslo: osloTime, tokyo: tokyoTime });
      setCountdown(countdownText);
    };

    updateTimes();
    const interval = setInterval(updateTimes, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <TimerContainer>
      <TimeSection>
        <TimeBlock>
          <TimeText>{times.oslo}</TimeText>
          <Label>Oslo Time</Label>
        </TimeBlock>
        <TimeBlock>
          <TimeText>{times.tokyo}</TimeText>
          <Label>Tokyo Time</Label>
        </TimeBlock>
      </TimeSection>
      <CountdownSection>
        <TimeText>{countdown}</TimeText>
        <Label>{countdown.includes('Market Open') ? 'US Market Status' : 'Until US Market Opens'}</Label>
      </CountdownSection>
    </TimerContainer>
  );
};

export default Timer;
