import styled from 'styled-components';
import theme from '../../styles/theme';

export const PageWrapper = styled.div`
  max-width: 1300px;
  margin: auto 0 3rem 0;
  padding: 0 20px;
  background-color: ${theme.colors.background.primary};

  @media (min-width: 768px) {
    margin-top: 0rem;
  }

  @media (max-width: 768px) {
    margin-top: 4rem;
    padding: 0 10px;
  }
`;