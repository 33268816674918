import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const WatchlistContainer = styled.div`
  width: 300px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border: 1px solid #000;

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

const WatchlistHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const WatchlistTitle = styled.h3`
  font-size: 1.1rem;
  color: #4a4a4a;
  margin: 0 0 0 0.5rem;
`;

const ClearButton = styled.div`
  height: 1.5rem;
  width: 60px;
  border-radius: 4px;
`;

export const EmptyWatchlistMessage = styled.p`
  color: #888;
  font-size: 0.9rem;
  text-align: center;
  margin: 1rem 0;
`;

const WatchlistSkeleton = () => {
  const { t } = useTranslation();
  
  return (
    <WatchlistContainer>
      <WatchlistHeader>
        <WatchlistTitle>{t('discovery.watchlist.title')}</WatchlistTitle>
        <ClearButton />
      </WatchlistHeader>
      <EmptyWatchlistMessage>
        {t('discovery.watchlist.emptyMessage')}
      </EmptyWatchlistMessage>
    </WatchlistContainer>
  );
};

export default WatchlistSkeleton;