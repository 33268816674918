import React, { useMemo, useState } from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { TrendingUp, TrendingDown, Briefcase, RefreshCcw, EthernetPort } from 'lucide-react';
import { Asset } from './types';
import { formatCurrency, getPortfolioMetrics } from './portfolioData';
import { usePortfolioStorage } from '../../hooks/usePortfolioStorage';
import { TypeToggle } from './TypeToggle';

import * as Styled from './portfolioPageStyles';

const AssetInputForm: React.FC<{ onSubmit: (asset: Partial<Asset>) => void }> = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    symbol: '',
    price: '', 
    amount: 0,
    type: 'stock'
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    onSubmit({
      symbol: formData.symbol,
      purchasePrice: parseFloat(formData.price),
      currentPrice: parseFloat(formData.price) * 1.1,
      purchaseDate: new Date().toISOString(),
      quantity: formData.amount,
      priceHistory: [{ price: parseFloat(formData.price) }],
      type: formData.type as 'stock' | 'crypto'
    });

    setFormData({ symbol: '', price: '', amount: 0, type: 'stock' });
  };


  const handleTypeChange = (newType: string) => {
    setFormData(prev => ({ ...prev, type: newType }));
  };


  return (
    <Styled.InputContainer>
      <Styled.FormContainer onSubmit={handleSubmit}>
        <Styled.InputGroup>
          <Styled.StyledInput
            placeholder="Ticker symbol"
            value={formData.symbol}
            onChange={(e: { target: { value: any; }; }) => setFormData(prev => ({ ...prev, symbol: e.target.value }))}
            required
          />
        </Styled.InputGroup>

        <Styled.InputGroup>
          <Styled.StyledInput
            type="number"
            placeholder="Purchase price"
            value={formData.price}
            onChange={(e: { target: { value: any; }; }) => setFormData(prev => ({ ...prev, price: e.target.value }))}
            required
            min="0"
            step="0.01"
          />
        </Styled.InputGroup>

        <Styled.InputGroup>
          <Styled.StyledInput
            type="number"
            placeholder="Quantity"
            value={formData.amount}
            onChange={(e: { target: { value: any; }; }) => setFormData(prev => ({ ...prev, amount: parseInt(e.target.value) }))}
            required
            min="0"
            step="1"
          />
        </Styled.InputGroup>

        <TypeToggle type={formData.type} updateType={handleTypeChange} />

        <Styled.StyledButton type="submit" variant="default">Add Asset</Styled.StyledButton>
      </Styled.FormContainer>
    </Styled.InputContainer>
  );
};


const AssetRowComponent: React.FC<{ asset: Asset }> = React.memo(({ asset }) => {
  const roi = ((asset.currentPrice - asset.purchasePrice) / asset.purchasePrice * 100).toFixed(2);
  const isPositive = asset.currentPrice >= asset.purchasePrice;
  const profitLoss = (asset.currentPrice - asset.purchasePrice) * asset.quantity;
  const purchaseDate = new Date(asset.purchaseDate).toLocaleDateString();

  return (
    <Styled.AssetRow>
      <Styled.AssetInfo>
        <Styled.DetailValue>{asset.symbol}</Styled.DetailValue>
      </Styled.AssetInfo>

      <Styled.ChartContainer>
        <ResponsiveContainer>
          <LineChart data={asset.priceHistory}>
            <Line 
              type="monotone" 
              dataKey="price" 
              stroke={isPositive ? '#10B981' : '#EF4444'}
              dot={false}
              strokeWidth={1}
            />
          </LineChart>
        </ResponsiveContainer>
      </Styled.ChartContainer>

      <Styled.AssetDetails>
        <Styled.DetailColumn>
          <Styled.DetailLabel>Current</Styled.DetailLabel>
          <Styled.DetailValue>{formatCurrency(asset.currentPrice)}</Styled.DetailValue>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>Purchase ({purchaseDate})</Styled.DetailLabel>
          <Styled.DetailValue>{formatCurrency(asset.purchasePrice)}</Styled.DetailValue>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>Quantity</Styled.DetailLabel>
          <Styled.DetailValue>{asset.quantity}</Styled.DetailValue>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>P/L</Styled.DetailLabel>
          <Styled.TrendIndicator positive={isPositive}>
            {isPositive ? <TrendingUp size={14} /> : <TrendingDown size={14} />}
            {formatCurrency(profitLoss)}
          </Styled.TrendIndicator>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>ROI</Styled.DetailLabel>
          <Styled.TrendIndicator positive={isPositive}>
            {roi}%
          </Styled.TrendIndicator>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>Value</Styled.DetailLabel>
          <Styled.DetailValue>{formatCurrency(asset.totalValue)}</Styled.DetailValue>
        </Styled.DetailColumn>

        <Styled.DetailColumn>
          <Styled.DetailLabel>Allocation</Styled.DetailLabel>
          <Styled.DetailValue>{asset.allocation.toFixed(1)}%</Styled.DetailValue>
        </Styled.DetailColumn>
      </Styled.AssetDetails>
    </Styled.AssetRow>
  );
});

const PortfolioPage: React.FC = () => {
  const { portfolioData, addAsset, resetPortfolio } = usePortfolioStorage();

  
  const stockAssets = useMemo(() => 
    portfolioData
      .filter(asset => asset.type === 'stock')
      .sort((a, b) => b.totalValue - a.totalValue),
    [portfolioData]
  );

  const cryptoAssets = useMemo(() => 
    portfolioData
      .filter(asset => asset.type === 'crypto')
      .sort((a, b) => b.totalValue - a.totalValue),
    [portfolioData]
  );

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.HeaderTitle>Portfolio Dashboard</Styled.HeaderTitle>
        <Styled.StyledButton 
          variant="reset"
          onClick={resetPortfolio}
          className="flex items-center gap-2"
        >
          <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
            <RefreshCcw size={16} />
          </div>
          
          Reset Portfolio
        </Styled.StyledButton>
      </Styled.Header>

      <AssetInputForm onSubmit={addAsset} />

      <Styled.SummaryGrid>
        <Styled.SummaryCard>
          <Styled.Label>Portfolio Value</Styled.Label>
          <Styled.Value>{formatCurrency(getPortfolioMetrics().totalValue)}</Styled.Value>
          <Styled.SubValue>
            <Briefcase size={14} />
            Stocks: {portfolioData.filter(a => a.type === 'stock').reduce((sum, a) => sum + a.allocation, 0).toFixed(1)}% | 
            <EthernetPort size={14} />
            Crypto: {portfolioData.filter(a => a.type === 'crypto').reduce((sum, a) => sum + a.allocation, 0).toFixed(1)}%
          </Styled.SubValue>
        </Styled.SummaryCard>
      </Styled.SummaryGrid>

      {portfolioData.length > 0 && (
        <>
          <Styled.AssetSection>
            <Styled.SectionTitle>
              <Briefcase size={16} />
              Stocks
            </Styled.SectionTitle>
            {stockAssets.map(asset => 
              <AssetRowComponent key={asset.id} asset={asset} />
            )}
          </Styled.AssetSection>

          <Styled.AssetSection>
            <Styled.SectionTitle>
              <EthernetPort size={16} />
              Crypto
            </Styled.SectionTitle>
            {cryptoAssets.map(asset => 
              <AssetRowComponent key={asset.id} asset={asset} />
            )}
          </Styled.AssetSection>
        </>
      )}
    </Styled.Container>
  );
};

export default PortfolioPage;