import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import styled from 'styled-components';

const FlagButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 40px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.1s ease;
  padding: 0;
  margin-left: 1.25rem;
  margin-right: 1rem;
  overflow: hidden;
  background: none;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 1px 1px rgba(255, 0, 0, 0.4);
  }
  @media (max-width: 600px) {
    width: 35px;
    height: 35px;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
`;

const FlagWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
`;

const FlagIconWrapper = styled.div<{ isGB: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ${props => props.isGB ? 'scale(0.8)' : 'scale(1)'};
  width: 110%;
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language.startsWith('nb') ? 'en' : 'nb';
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  const countryCode = i18n.language.startsWith('nb') ? 'NO' : 'GB';
  const isGB = countryCode === 'GB';

  return (
    <FlagButton onClick={toggleLanguage} aria-label="Language">
      <FlagWrapper>
        <FlagIconWrapper isGB={isGB}>
          <FlagIcon code={countryCode} size={60} />
        </FlagIconWrapper>
      </FlagWrapper>
    </FlagButton>
  );
};

export default LanguageSwitcher;