import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import Footer from '../components/footer/Footer';
import theme from '../styles/theme';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${theme.colors.background.primary};

  @media (max-width: 768px) {
    min-height: 60vh;
  }
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10rem;
  }
`;

const ContentWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const FooterWrapper = styled.div`
  height: auto;
`;

const Layout: React.FC = () => {
  return (
    <LayoutWrapper>
      <MainContent>
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </MainContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </LayoutWrapper>
  );
};

export default Layout;