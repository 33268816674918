import { useState, useCallback, useRef } from 'react';

type PopupType = 'error' | 'info' | 'warning';
type PopupPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

interface PopupState {
  id: number;
  message: string;
  type: PopupType;
  position: PopupPosition;
  duration: number;
}

export const useInfoPopup = () => {
  const [popups, setPopups] = useState<PopupState[]>([]);
  const popupIdCounter = useRef(0);

  const showPopup = useCallback((
    message: string, 
    type: PopupType = 'info', 
    position: PopupPosition = 'bottom-right', 
    duration: number = 3000
  ) => {
    const id = popupIdCounter.current++;
    const newPopup: PopupState = { id, message, type, position, duration };
   
    setPopups(currentPopups => [...currentPopups, newPopup]);
   
    setTimeout(() => {
      setPopups(currentPopups => currentPopups.filter(popup => popup.id !== id));
    }, duration);
  }, []);

  const showErrorPopup = useCallback((
    message: string, 
    position: PopupPosition = 'bottom-right', 
    duration: number = 3000
  ) => {
    showPopup(message, 'error', position, duration);
  }, [showPopup]);

  const showInfoPopup = useCallback((
    message: string, 
    position: PopupPosition = 'bottom-right', 
    duration: number = 3000
  ) => {
    showPopup(message, 'info', position, duration);
  }, [showPopup]);

  const showWarningPopup = useCallback((
    message: string, 
    position: PopupPosition = 'bottom-right', 
    duration: number = 3000
  ) => {
    showPopup(message, 'warning', position, duration);
  }, [showPopup]);

  return { popups, showErrorPopup, showInfoPopup, showWarningPopup };
};