import React, { useState } from 'react';
import styled from 'styled-components';
import { DollarSign, Calendar, User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { PredictionSubmit } from '../types';

const CompactPredictionBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #ffffff;
  width: 250px;
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
  margin-right: 0.25rem;
`;

const InputIcon = styled.span`
  margin-right: 0.25rem;
  color: #666;
`;

const SubmitButton = styled.button`
  padding: 0.25rem 0.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;

  &:hover {
    background-color: #0056b3;
  }
`;

export const CompactLabel = styled.label`
  font-size: 0.875rem;
  color: #64748b;
  margin-bottom: 0.25rem;
  display: block;
  font-weight: 500;
`;

const PredictionInputBox = ({ onSubmit }: { onSubmit: (prediction: PredictionSubmit) => void }) => {
  const { t } = useTranslation();
  const [prediction, setPrediction] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [credit, setCredit] = useState<string>('');

  const handleSubmit = () => {
    const predictionValue = parseFloat(prediction);
    if (predictionValue >= 0.0000001 && predictionValue <= 100000000) {
      onSubmit({
        predictionValue,
        startDate,
        endDate,
        credit
      });
    }
  };

  return (
    <CompactPredictionBox>
      <CompactLabel>{t('discovery.gemPage.predictions.valueExplain')}</CompactLabel>
      <InputRow>
        <InputIcon><DollarSign size={16} /></InputIcon>
        <StyledInput
          type="number"
          value={prediction}
          onChange={(e) => setPrediction(e.target.value)}
          step="0.0000001"
          min="0.0000001"
          max="100000000"
          placeholder={t('discovery.gemPage.predictions.value')}
        />
      </InputRow>
      <CompactLabel>{t('discovery.gemPage.predictions.dateRange')}</CompactLabel>
      <InputRow>
        <InputIcon><Calendar size={16} /></InputIcon>
        <StyledInput
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          min={new Date().toISOString().split('T')[0]}
        />
      </InputRow>
      <InputRow>
        <InputIcon><Calendar size={16} /></InputIcon>
        <StyledInput
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          min={new Date().toISOString().split('T')[0]}
        />
      </InputRow>
      <CompactLabel>{t('discovery.gemPage.predictions.creditExplain')}</CompactLabel>
      <InputRow>
        <InputIcon><User size={16} /></InputIcon>
        <StyledInput
          type="text"
          placeholder={t('discovery.gemPage.predictions.credit')}
          value={credit}
          onChange={(e) => setCredit(e.target.value)}
        />
      </InputRow>
      <SubmitButton onClick={handleSubmit}>
        {t('discovery.gemPage.predictions.submit')}
      </SubmitButton>
    </CompactPredictionBox>
  );
};

export default PredictionInputBox;