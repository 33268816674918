import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { FaRocket, FaLightbulb, FaChartLine } from 'react-icons/fa';

const TransitionSectionStyle = styled.section`
  padding: 60px 20px;
  background: linear-gradient(135deg, #ff3333, #ff6666);
  color: white;
  text-align: center;
  border-radius: 12px;
  margin-top: 3rem;
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(255, 51, 51, 0.2);

  @media (max-width: 768px) {
    padding: 40px 15px;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }
`;

const TransitionTitle = styled(motion.h2)`
  font-size: 2.5rem;
  margin-bottom: 25px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`;

const TransitionText = styled(motion.p)`
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 30px;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 25px;
  }
`;

const IconContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 25px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const Icon = styled(motion.div)`
  font-size: 2.5rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 2rem;
    padding: 12px;
  }
`;

const FloatingShape = styled(motion.div)`
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;

  @media (max-width: 768px) {
    display: none;
  }
`;

const TransitionSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TransitionSectionStyle>
      <FloatingShape
        style={{ top: '10%', left: '5%', width: '80px', height: '80px' }}
        animate={{
          y: [0, 20, 0],
          rotate: [0, 180, 360],
        }}
        transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
      />
      <FloatingShape
        style={{ top: '70%', right: '10%', width: '120px', height: '120px' }}
        animate={{
          y: [0, -30, 0],
          rotate: [360, 180, 0],
        }}
        transition={{ duration: 25, repeat: Infinity, ease: "linear" }}
      />
      <TransitionTitle
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        {t('landingPage.transition.title')}
      </TransitionTitle>
      <TransitionText
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.1 }}
      >
        {t('landingPage.transition.text')}
      </TransitionText>
      <IconContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        <Icon
          whileHover={{ scale: 1.05, rotate: 5 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaRocket />
        </Icon>
        <Icon
          whileHover={{ scale: 1.05, rotate: -5 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaLightbulb />
        </Icon>
        <Icon
          whileHover={{ scale: 1.05, rotate: 5 }}
          whileTap={{ scale: 0.95 }}
        >
          <FaChartLine />
        </Icon>
      </IconContainer>
    </TransitionSectionStyle>
  );
};

export default TransitionSection;