import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvInput from '../../../components/common/input/SvInput';
import { useAuth } from '../../../contexts/AuthContext';
import styled from 'styled-components';
import { ErrorMessage } from '../../../components/auth/styles/authModalStyles';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

interface LoginFormProps {
  onLoginSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [infoPopup, setInfoPopup] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    setInfoPopup(null);
    e.preventDefault();

    try {
      await login(email, password);
      onLoginSuccess();
    } catch (error) {
      if (error instanceof Error) {
        setInfoPopup(t(`authenticate.modal.error.${error.message}`) || error.message);
      } else {
        setInfoPopup(t('authenticate.modal.error.unknown'));
      }
    }
  };

  return (
    <Form onSubmit={handleLogin} id="loginForm">
      <SvInput
        type="email"
        placeholder={t('auth.email')}
        value={email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
        required
        inputSize="medium"
        variant="outline"
      />
      <SvInput
        type="password"
        placeholder={t('auth.password')}
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
        required
        inputSize="medium"
        variant="outline"
      />
      {infoPopup && <ErrorMessage>{infoPopup}</ErrorMessage>}
    </Form>
  );
};

export default LoginForm;