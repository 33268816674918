import apiService from './baseApi';
import { WatchlistResponse } from '../pages/discovery/types';



export const fetchUserWatchlist = async (userId: string) => {
    const response = await apiService.get<WatchlistResponse>(`/watchlist/${userId}`);
    return response;
};



export const addToWatchlist = async (userId: string, gemId: string) => {
    const response = await apiService.post(`/watchlist/${userId}`, { gemId });
    return response;
};



export const removeFromWatchlist = async (userId: string, gemId: string) => {
    const response = await apiService.delete(`/watchlist/${userId}/${gemId}`);
    return response;
};



export const deleteWatchlist = async (userId: string) => {
    const response = await apiService.delete(`/watchlist/${userId}`);
    return response;
};