import styled, { keyframes } from 'styled-components';
import theme from '../../../styles/theme';

const fadeIn = keyframes`
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(3px);
  min-width: 250px;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const ModalContent = styled.div`
  border: 1px solid black;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  position: relative;
  z-index: 1001;
  animation: ${fadeIn} 0.1s ease-out;
  min-width: 250px;
`;

export const ModalTitle = styled.h2`
  color: #e74c3c;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(231, 76, 60, 0.1);
`;

export const ModalText = styled.p`
  margin-bottom: 1rem;
  color: black;
`;

export const ModalTextCentered = styled(ModalText)`
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  font-style: italic;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const PrimaryButton = styled.button`
  background-color: ${theme.colors.primaryYellow};
  color: #333;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s ease;
  flex: 1;
  margin: 0 0.5rem;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
  &:hover {
    background-color: ${theme.colors.primaryYellowHover};
  }

  &:disabled {
    background-color: rgba(255, 215, 0, 0.4);
    color: #777;
    cursor: not-allowed;
  }
`;

export const CancelButton = styled.button`
  background: none;
  border: none;
  color: #999999;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  text-decoration: underline;
  transition: all 0.2s ease;

  &:hover {
    color: #e74c3c;
  }
`;

export const Separator = styled.hr`
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #e0e0e0;
`;

export const AuthFormContainer = styled.div`
  margin-top: 1rem;
`;

export const LoginFormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const FormButton = styled.button`
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s ease;
  flex: 1;
  margin-right: 0.5rem;

  &:hover {
    background-color: #c0392b;
  }

  &:disabled {
    background-color: #e57373;
    cursor: not-allowed;
  }
`;

export const SwitchButton = styled.button`
  background: none;
  border: none;
  color: #e74c3c;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  transition: all 0.2s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #e74c3c;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }

  &:disabled {
    color: #e57373;
    cursor: not-allowed;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 14px;
  right: 14px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #999999;
  cursor: pointer;

  &:hover {
    color: #e74c3c;
  }
`;

export const BTCPrice = styled.div`
  font-size: 0.9rem;
  color: black;
  text-align: right;
  margin-top: 10px;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.2s ease;
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: #e74c3c;
    box-shadow: 0 0 0 1px rgba(231, 76, 60, 0.2);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ErrorMessage = styled.p`
  color: #e74c3c;
  margin-top: 1rem;
  font-size: 0.9rem;
  text-align: center;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 5px;
`;