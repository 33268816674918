// src/components/modal/UpgradeModal.tsx
import React, { useEffect, useState } from 'react';
import { FaDollarSign, FaRocket, FaTimes, FaUser } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { createCheckoutSession, redirectToCheckout } from '../../../api/stripeApi';
import { useAuth } from '../../../contexts/AuthContext';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import SvInput from '../../../components/common/input/SvInput';
import { ForgotPasswordLink, Spinner } from '../../../components/auth/styles/authModalStyles';

import * as Styled from './modalStyle';



interface UpgradeModalProps {
  isopen: boolean;
  onClose: () => void;
}



const UpgradeModal: React.FC<UpgradeModalProps> = ({ isopen, onClose }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const monthlyFeeUsd = 20;
  const [loading, setLoading] = useState(false);
  const [upgradeEmail, setUpgradeEmail] = useState(user?.email || '');
  const [inputEmail, setInputEmail] = useState('');



  useEffect(() => {
    if (isopen) {
      setUpgradeEmail(user?.email || '');
      setInputEmail('');
    }
  }, [isopen, user?.email]);


  
  const handlePayment = async () => {
    if (!user) {
      setShowAuthForm(true);
      return;
    }
    setLoading(true);
    try {
      const sessionId = await createCheckoutSession(upgradeEmail);
      await redirectToCheckout(sessionId);
    } catch (error) {
      console.error('Failed to initiate checkout:', error);
      setLoading(false);
    }
  };



  const handleAuthSuccess = () => {
    setShowAuthForm(false);
  };



  const handleUpgradeEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputEmail(e.target.value);
  };



  const handleUpgradeEmailSubmit = () => {
    if (inputEmail) {
      setUpgradeEmail(inputEmail);
    }
  };

  

  if (!isopen) return null;



  return (
    <Styled.ModalOverlay onClick={onClose}>
      <Styled.ModalContent onClick={(e: React.MouseEvent<HTMLDivElement>): void => e.stopPropagation()}>
        <Styled.ModalTitle><FaRocket />{t('upgrade.modal.title')}</Styled.ModalTitle>

        <Styled.CloseButton onClick={onClose}>
          <FaTimes />
        </Styled.CloseButton>
        <Styled.ModalText>
          {t('upgrade.modal.pricingInfoUsdOnly', { amount: monthlyFeeUsd })}
        </Styled.ModalText>

        <Styled.ModalText>
          {t('upgrade.modal.weeklyCost', { amount: (monthlyFeeUsd / 4).toFixed(0) })}
        </Styled.ModalText>
        <Styled.ModalText>
          {t('upgrade.modal.investmentExample')} 👀
        </Styled.ModalText>

        <Styled.ButtonContainer>
          <Styled.PrimaryButton 
            onClick={handlePayment} 
            disabled={showAuthForm && !user}
          >
            <FaDollarSign /> {
              loading ? <Spinner /> : t('upgrade.modal.payUsd') 
            }
          </Styled.PrimaryButton>
        </Styled.ButtonContainer>
        <Styled.Separator />
        {showAuthForm && !user && (
          <>
            <Styled.AuthFormContainer>
              {isLogin ? (
                <LoginForm onLoginSuccess={handleAuthSuccess} />
              ) : (
                <RegisterForm onRegisterSuccess={handleAuthSuccess} />
              )}
            </Styled.AuthFormContainer>
            <Styled.LoginFormFooter>
              
              {isLogin && (
                <ForgotPasswordLink onClick={() => console.log('Forgot password clicked')}>
                  {t('authenticate.modal.forgotPassword')}
                </ForgotPasswordLink>
              )}
              <Styled.SwitchButton onClick={() => setIsLogin(!isLogin)} style={{ marginRight: '10px' }}>
                {isLogin ? t('authenticate.modal.switch.to_register') : t('authenticate.modal.switch.to_login')}
              </Styled.SwitchButton>
              
              <Styled.FormButton
                type="submit"
                form={isLogin ? "loginForm" : "registerForm"}
              >
                <FaUser /> {t(isLogin ? 'auth.login' : 'auth.register')}
              </Styled.FormButton>

            </Styled.LoginFormFooter>
          </>
        )}
        {user && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Styled.ModalTextCentered>
              {t('upgrade.modal.userToUpgrade')}:
            </Styled.ModalTextCentered> 
            <strong>{upgradeEmail}</strong>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem' }}>
              <Styled.ModalTextCentered>
                {t('upgrade.modal.upgradeAnotherUser')}
              </Styled.ModalTextCentered>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.5rem' }}>
                <SvInput
                  type="email"
                  placeholder={t('upgrade.modal.upgradeAnotherInput')}
                  value={inputEmail}
                  onChange={handleUpgradeEmailChange}
                  inputSize="small"
                  variant="outline"
                />
                <Styled.SwitchButton onClick={handleUpgradeEmailSubmit}>
                  {t('upgrade.modal.changeEmail', 'Change')}
                </Styled.SwitchButton>
              </div>
            </div>
          </div>
        )}
      </Styled.ModalContent>
    </Styled.ModalOverlay>
  );
};



export default UpgradeModal;