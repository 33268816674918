import { Mail } from "lucide-react";
import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding: 2rem 1rem;
  box-sizing: border-box;
  margin-top: 60px;

  @media (max-width: 600px) {
    padding: 4rem 1rem;
  }
`;

export const ContentWrapper = styled.div`
  max-width: 500px;
  width: 100%;
  background-color: #e74c3c;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: 0 8px 32px rgba(231, 76, 60, 0.3);
  border: 2px solid #000;
  transition: all 0.3s ease;
  margin-bottom: 2rem;

  @media (max-width: 600px) {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.1rem;
  color: #fff;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.6;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Input = styled.input<{ isInvalid: boolean }>`
  width: 100%;
  padding: 1rem 1rem 1rem 3.5rem;
  background-color: #fff;
  border: 2px solid ${props => props.isInvalid ? '#c0392b' : '#000'};
  border-radius: 6px;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-color: #c0392b;
    box-shadow: 0 0 0 3px rgba(231, 76, 60, 0.3);
  }
`;

export const InputIcon = styled(Mail)`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #e74c3c;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  color: #e74c3c;
  border: 2px solid #000;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #f8d7da;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }
`;

export const SuccessMessage = styled.div`
  background-color: #e0f0e0;
  color: #006400;
  padding: 1.5rem;
  border-radius: 12px;
  text-align: center;
  margin-top: 2rem;
  font-size: 1.2rem;
  border: 2px solid #006400;
  box-shadow: 0 4px 6px rgba(0, 100, 0, 0.1);
  position: relative;
  overflow: hidden;
  animation: slideIn 0.5s ease, pulse 2s infinite;

  @keyframes slideIn {
    from { opacity: 0; transform: translateY(30px); }
    to { opacity: 1; transform: translateY(0); }
  }

  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(0, 100, 0, 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(0, 100, 0, 0); }
    100% { box-shadow: 0 0 0 0 rgba(0, 100, 0, 0); }
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
  background-color: transparent;

  @media (max-width: 600px) {
    padding-bottom: 1rem;
  }
`;

export const FloatingLogo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 130px;
  height: auto;

  @media (max-width: 600px) {
    width: 120px;
  }
`;

export const LogoImage = styled.img`
  margin-top: 1rem;
  margin-left: 1rem;
  width: 80%;
  height: auto;
`;

export const LanguageSwitcherWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LanguageText = styled.span`
  font-size: 0.8rem;
  color: #e74c3c;
  margin-top: 0.5rem;
  text-align: center;
`;

export const LimitedSpotsBanner = styled.div`
  background-color: #c0392b;
  color: #fff;
  text-align: center;
  padding: 0.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

export const FAQSection = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #fff;
  font-size: 0.9rem;
  width: 100%;
  max-width: 500px;
`;

export const FAQItem = styled.div`
  margin-bottom: 1rem;
`;

export const FAQQuestion = styled.p`
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: red;
`;

export const FAQAnswer = styled.p`
  margin-left: 1rem;
  font-size: 0.9rem;
  color: red;
`;

export const ContactInfo = styled.div`
  background-color: #FFC0CB;
  color: #000;
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
  max-width: 500px;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;