import React, { useMemo } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Line } from 'recharts';


interface Prediction {
  _id: string;
  userId: string;
  username: string;
  value: number;
  startTime: string;
  endTime: string;
  upvote: number;
  downvote: number;
  credit?: string;
}



interface PricePredictionItem {
  gemId: string;
  averagePrediction: number;
  lastUpdated: string;
  predictions: Prediction[];
}



interface PredictionHistoryProps {
  pricePredictions: PricePredictionItem | null;
}



const PredictionHistory: React.FC<PredictionHistoryProps> = ({ pricePredictions }) => {
  const today = useMemo(() => new Date(), []);
  const oneYearFromNow = useMemo(() => new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()), [today]);



  const { nearTermData, longTermData } = useMemo(() => {
    if (!pricePredictions) return { nearTermData: [], longTermData: [] };
    
    const allData = pricePredictions.predictions.flatMap(prediction => [
      {
        x: new Date(prediction.startTime).getTime(),
        y: prediction.value,
        username: prediction.credit || prediction.username,
        votes: prediction.upvote - prediction.downvote,
      },
      {
        x: new Date(prediction.endTime).getTime(),
        y: prediction.value,
        username: prediction.credit || prediction.username,
        votes: prediction.upvote - prediction.downvote,
      }
    ]);

    const sortedData = allData.sort((a, b) => a.x - b.x);

    const windowSize = 5;
    const nearTermWithMA = sortedData
      .filter(point => point.x <= oneYearFromNow.getTime())
      .map((point, index, array) => {
        const startIndex = Math.max(0, index - windowSize + 1);
        const windowSlice = array.slice(startIndex, index + 1);
        const movingAverage = windowSlice.reduce((sum, p) => sum + p.y, 0) / windowSlice.length;
        return { ...point, movingAverage };
      });

    return {
      nearTermData: nearTermWithMA,
      longTermData: sortedData.filter(point => point.x > oneYearFromNow.getTime()),
    };
  }, [pricePredictions, oneYearFromNow]);



  const { minYNear, maxYNear, minYLong, maxYLong } = useMemo(() => {
    const calcMinMax = (data: any[]) => data.reduce(
      (acc, point) => ({
        min: Math.min(acc.min, point.y),
        max: Math.max(acc.max, point.y),
      }),
      { min: Infinity, max: -Infinity }
    );

    const nearTermMinMax = calcMinMax(nearTermData);
    const longTermMinMax = calcMinMax(longTermData);

    return {
      minYNear: nearTermMinMax.min,
      maxYNear: nearTermMinMax.max,
      minYLong: longTermMinMax.min,
      maxYLong: longTermMinMax.max,
    };
  }, [nearTermData, longTermData]);



  const formatXAxis = (tickItem: number) => {
    const date = new Date(tickItem);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)}`;
  };



  const formatYAxis = (value: number) => {
    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    }
    return `$${value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  };



  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip" style={{ 
          backgroundColor: 'white', 
          padding: '10px', 
          border: '1px solid #ccc',
          borderRadius: '5px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
          fontSize: '14px',
          fontWeight: 'bold',
          textAlign: 'center',
          lineHeight: '1.5',
          color: '#333',
          }}>
          <p>{`${new Date(data.x).toLocaleDateString()}`}</p>
          <p>{`${data.username}`}</p>
          <p>{`${formatYAxis(data.y)}`}</p>
          <p>{`Score: ${data.votes}`}</p>
        </div>
      );
    }
    return null;
  };



  return (
    <div style={{ display: 'flex', width: '100%', height: 300 }}>
      <div style={{ flex: 2 }}>
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 0, bottom: 20, left: 60 }}>
            <CartesianGrid />
            <XAxis
              dataKey="x"
              type="number"
              domain={[today.getTime(), oneYearFromNow.getTime()]}
              tickFormatter={formatXAxis}
              name="Date"
            />
            <YAxis
              dataKey="y"
              name="Value"
              tickFormatter={formatYAxis}
              domain={[minYNear * 0.9, maxYNear * 1.1]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Scatter name={`Within 1 year (${oneYearFromNow.getFullYear()})`} data={nearTermData} fill="#f97316" />
            <Line
              type="monotone"
              dataKey="movingAverage"
              stroke="#ff7300"
              dot={false}
              name="Moving Average"
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
      <div style={{ flex: 1, marginLeft: 10 }}>
        <ResponsiveContainer>
          <ScatterChart margin={{ top: 20, right: 60, bottom: 20, left: 0 }}>
            <CartesianGrid />
            <XAxis
              dataKey="x"
              type="number"
              domain={['dataMin', 'dataMax']}
              tickFormatter={formatXAxis}
              name="Date"
            />
            <YAxis
              dataKey="y"
              name="Value"
              orientation="right"
              tickFormatter={formatYAxis}
              domain={[minYLong * 0.9, maxYLong * 1.1]}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Scatter name={`Long term (${oneYearFromNow.getFullYear() + 1}+)`} data={longTermData} fill="#22c55e" />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};



export default PredictionHistory;