import axios, { AxiosProgressEvent } from "axios";
import { useState, useCallback } from "react";
import { Gem } from "../pages/discovery/types";



const processChunk = (chunk: string, setGems: React.Dispatch<React.SetStateAction<Gem[]>>) => {
  try {
    const parsedData = JSON.parse(chunk);
    if (Array.isArray(parsedData.gems)) {
      setGems(prevGems => {
        const newGems = [...prevGems];
        parsedData.gems.forEach((gem: Gem) => {
          const existingIndex = newGems.findIndex(g => g.id === gem.id);
          if (existingIndex !== -1) {
            newGems[existingIndex] = { ...newGems[existingIndex], ...gem };
          } else {
            newGems.push(gem);
          }
        });
        return newGems;
      });
    } else {
      console.error('Unexpected data structure.');
    }
  } catch (error) {
    console.error('Error parsing data chunk.');
  }
};



export const useGemDataStream = () => {
  const [gems, setGems] = useState<Gem[]>([]);

  const getDataFromStream = useCallback(() => {
    let accumulatedData = '';
    const API_URL = process.env.REACT_APP_NODE_ENV === 'production' ?
      process.env.REACT_APP_API_BASE_URL : process.env.REACT_APP_API_LOCAL_URL;

    return axios({
      url: `${API_URL}/gems/all`,
      method: 'GET',
      responseType: 'text',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY
      },
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        // Assuming the response is text-based (e.g., newline-delimited JSON)
        const newData = progressEvent.event.target as XMLHttpRequest;
        const chunk = newData.responseText.slice(accumulatedData.length);
        accumulatedData = newData.responseText;
        // Process the new chunk
        processChunk(chunk, setGems);
      }
    });
  }, []);

  return { gems, setGems, getDataFromStream };
};