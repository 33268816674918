import { styled } from "styled-components";
import theme from "../../styles/theme";

export const CloseButton = styled.button`
  background-color: ${theme.colors.primaryPink};
  color: white;
  border: 1px solid black;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 6px;
  padding: 0.2rem 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.colors.primaryPinkHover};
  }
`;

export const UserActionsContainer = styled.div`
  width: 300px;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  height: fit-content;
  border: 1px solid #000;
  margin-top: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const UserActionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const HeaderControls = styled.div`
  display: flex;
  align-items: center;
`;

export const UserActionsTitle = styled.h3`
  font-size: 1.1rem;
  color: #4a4a4a;
  margin: 0 0 0 0.5rem;
`;

export const CollapseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a4a4a;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

export const UserActionUnit = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #f8f8f8;
  }
  &:last-child {
    border-bottom: none;
  }
`;

export const ActionInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const EmptyActionsMessage = styled.p`
  color: #888;
  font-size: 0.9rem;
  text-align: center;
  margin: 1rem 0;
`;

export const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
`;

export const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const RefreshButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem 0.5rem 0;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;