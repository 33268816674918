import styled, { keyframes } from 'styled-components';
import theme from '../../../styles/theme';


const bounce = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
`;


const PettoContainer = styled.div`
  background: ${theme.colors.background.paper};
  border: 1px solid rgba(255, 0, 0, 0.1);
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const PixelPet = styled.div`
  height: 220px;
  position: relative;
  animation: ${bounce} 2.5s ease-in-out infinite;
  
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    box-shadow:
      /* Row 1 - Top */
      150px 60px 0 #FF1493,
      160px 60px 0 #FF1493,
      170px 60px 0 #FF1493,
      
      /* Row 2 */
      140px 70px 0 #FF1493,
      150px 70px 0 #FF69B4,
      160px 70px 0 #FF69B4,
      170px 70px 0 #FF69B4,
      180px 70px 0 #FF1493,
      
      /* Row 3 */
      130px 80px 0 #FF1493,
      140px 80px 0 #FF69B4,
      150px 80px 0 #FF69B4,
      160px 80px 0 #FFB6C1,
      170px 80px 0 #FF69B4,
      180px 80px 0 #FF69B4,
      190px 80px 0 #FF1493,
      
      /* Row 4 */
      120px 90px 0 #FF1493,
      130px 90px 0 #FF69B4,
      140px 90px 0 #FF69B4,
      150px 90px 0 #FFB6C1,
      160px 90px 0 #FFB6C1,
      170px 90px 0 #FFB6C1,
      180px 90px 0 #FF69B4,
      190px 90px 0 #FF69B4,
      200px 90px 0 #FF1493,
      
      /* Row 5 - Left eye level */
      110px 100px 0 #FF1493,
      120px 100px 0 #FF69B4,
      130px 100px 0 #FF69B4,
      140px 100px 0 white,
      150px 100px 0 white,
      160px 100px 0 #FFB6C1,
      170px 100px 0 #FFB6C1,
      180px 100px 0 #FF69B4,
      190px 100px 0 #FF69B4,
      200px 100px 0 #FF69B4,
      210px 100px 0 #FF1493,
      
      /* Row 6 - Left pupil level */
      110px 110px 0 #FF1493,
      120px 110px 0 #FF69B4,
      130px 110px 0 #FF69B4,
      140px 110px 0 white,
      150px 110px 0 black,
      160px 110px 0 #FFB6C1,
      170px 110px 0 white,
      180px 110px 0 white,
      190px 110px 0 #FF69B4,
      200px 110px 0 #FF69B4,
      210px 110px 0 #FF1493,
      
      /* Row 7 - Right pupil level */
      110px 120px 0 #FF1493,
      120px 120px 0 #FF69B4,
      130px 120px 0 #FF69B4,
      140px 120px 0 white,
      150px 120px 0 white,
      160px 120px 0 #FFB6C1,
      170px 120px 0 white,
      180px 120px 0 black,
      190px 120px 0 #FF69B4,
      200px 120px 0 #FF69B4,
      210px 120px 0 #FF1493,
      
      /* Row 8 */
      120px 130px 0 #FF1493,
      130px 130px 0 #FF69B4,
      140px 130px 0 #FF69B4,
      150px 130px 0 #FFB6C1,
      160px 130px 0 #FFB6C1,
      170px 130px 0 #FFB6C1,
      180px 130px 0 #FF69B4,
      190px 130px 0 #FF69B4,
      200px 130px 0 #FF1493,
      
      /* Row 9 */
      130px 140px 0 #FF1493,
      140px 140px 0 #FF69B4,
      150px 140px 0 #FF69B4,
      160px 140px 0 #FFB6C1,
      170px 140px 0 #FF69B4,
      180px 140px 0 #FF69B4,
      190px 140px 0 #FF1493,
      
      /* Row 10 */
      140px 150px 0 #FF1493,
      150px 150px 0 #FF69B4,
      160px 150px 0 #FF69B4,
      170px 150px 0 #FF69B4,
      180px 150px 0 #FF1493,
      
      /* Row 11 - Bottom edge, slightly asymmetric */
      145px 160px 0 #FF1493,
      155px 160px 0 #FF1493,
      165px 160px 0 #FF1493,
      175px 160px 0 #FF1493;
  }
`;


const Petto = () => {
  return (
    <PettoContainer>
      <PixelPet />
    </PettoContainer>
  );
};


export default Petto;