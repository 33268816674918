import { Gem } from "../types";

import { ExpectedReturn, ExpectedReturnValue } from '../discoveryPageStyles';

export const renderExpectedReturn = (t: any, asset: Gem) => {
  if (asset.averagePrediction !== undefined && asset.averagePrediction !== 0 && 
    asset.topVotedPrediction && asset.topVotedPrediction.value !== 0) {
    
    return (
      <ExpectedReturn>
        {asset.topVotedPrediction?.value !== undefined && (
          <>
            {t('discovery.expectedReturn') + ': '}
            <ExpectedReturnValue>
              {(asset.topVotedPrediction.value / asset.price).toFixed(2) + 'x'}
            </ExpectedReturnValue>
          </>
        )}
      </ExpectedReturn>
    );
  }

  return <div style={{ height: '2rem' }} aria-hidden="true"></div>;
};