import { loadStripe } from '@stripe/stripe-js';
import apiService from './baseApi';
import axios from 'axios';



const API_URL = process.env.NODE_ENV === 'development' ? 
process.env.REACT_APP_API_LOCAL_URL + '/stripe' : process.env.REACT_APP_API_BASE_URL + '/stripe';



export const createCheckoutSession = async (userEmail: string): Promise<string> => {
  const response = await axios.post(`${API_URL}/create-checkout-session`, { userEmail }, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-API-Key': process.env.REACT_APP_API_KEY
    },
  });
  return response.data.sessionId;
};



export const redirectToCheckout = async (sessionId: string): Promise<void> => {
  const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
  if (!stripe) {
    throw new Error('Failed to load Stripe');
  }
  const { error } = await stripe.redirectToCheckout({ sessionId });
  if (error) {
    console.error('Error redirecting to checkout:', error.message);
    throw new Error(error.message);
  }
};



export const checkPaymentStatus = async (sessionId: string): Promise<{ success: boolean; email: string }> => {
  const response = await apiService.post<{ success: boolean; email: string }>('/stripe/check-payment-status', { sessionId });
  return response;
};