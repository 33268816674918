import { styled } from 'styled-components';
import { useTranslation } from 'react-i18next';
import GemSection from '../landing/sections/GemSection';
import theme from '../../styles/theme';


const NoProContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 2rem;
  border-radius: 12px;
`;

const EyeEmoji = styled.div`
  font-size: 52px;
  margin-bottom: 2rem;
`;

const MessageText = styled.p`
  font-size: 1.2rem;
  color: ${theme.colors.text.primary};
  text-align: center;
  margin-bottom: 4rem;
  max-width: 600px;
  line-height: 1.6;
  font-style: italic;
`;

const UpgradeText = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${theme.colors.primaryPink};
  margin-bottom: 4rem;
`;

const PreviewText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 2rem;
  font-style: italic;
`;

const NoProUserView = () => {
  const { t } = useTranslation();

  return (
    <NoProContainer>
      <EyeEmoji>👀</EyeEmoji>
      <MessageText>{t('discovery.noProUser.text')}</MessageText>
      <UpgradeText>{t('discovery.noProUser.upgrade')}</UpgradeText>
      <PreviewText>{t('discovery.noProUser.filler')}</PreviewText>
      <PreviewText>{t('discovery.noProUser.preview')}</PreviewText>
      <GemSection registered={false} />
    </NoProContainer>
  );
};

export default NoProUserView;
