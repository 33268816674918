import styled from 'styled-components';
import theme from '../../../styles/theme';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SidebarButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
  white-space: nowrap;
`;

export const ExtendSidebarButton = styled.button`
  background-color: ${theme.colors.darkRed};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.8rem;
  width: 50%;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }

  &:hover {
    background-color: ${theme.colors.darkRedHover};
  }
`;