import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsUp, ThumbsDown, Eye, Award, Tag } from 'lucide-react';
import { fakeGems } from './util';



import { 
  Section, GemGrid, GemCard, GemCardHeader, GemRank, GemName, 
  GemSymbol, GemCardBody, VotingSection, VoteButton, VoteCount, 
  PredictionSection, PredictionLabel, PredictionValue, TagSection, 
  GemCardFooter, WatchButton, CreatedAt, FooterContentWrapper, Title
} from './gemSectionStyles';



const GemSection = ({ registered = true }: { registered?: boolean }) => {
  const { t } = useTranslation();
  const [watchlist, setWatchlist] = useState<string[]>([]);



  const handleWatch = (gemId: string) => {
    setWatchlist(prev => 
      prev.includes(gemId) ? prev.filter(id => id !== gemId) : [...prev, gemId]
    );
  };



  return (
    <Section>
      {registered && (
        <Title>
          {t('landingPage.gemSection.shortExplanation')}
        </Title>
      )}
      <GemGrid setwidth={registered}>
        {fakeGems.map((gem, index) => (
          <GemCard key={gem.id} iswatched={watchlist.includes(gem.id)}>
            <GemCardHeader>
              <GemRank>#{index + 1}</GemRank>
              <GemName>{gem.name}</GemName>
              <GemSymbol>{gem.symbol}</GemSymbol>
            </GemCardHeader>
            <GemCardBody>
              <VotingSection>
                <VoteButton>
                  <ThumbsUp size={20} />
                  <VoteCount>{gem.votes.positive}</VoteCount>
                </VoteButton>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: gem.score > 10 ? '#e6ffe6' : gem.score > 0 ? '#fff6e6' : '#ffe6e6',
                  padding: '4px 10px',
                  borderRadius: '8px',
                }}>
                  <Award size={16} style={{ marginRight: '4px' }} />
                  <span style={{ 
                    fontWeight: 'bold', 
                    color: gem.score > 10 ? '#008000' : gem.score > 0 ? '#ffa500' : gem.score === 0 ? '#ffa500' : '#ff0000'
                  }}>
                    Score: {gem.score}
                  </span>
                </div>
                <VoteButton>
                  <ThumbsDown size={20} />
                  <VoteCount>{gem.votes.negative}</VoteCount>
                </VoteButton>
              </VotingSection>
              <PredictionSection>
                <PredictionLabel>{t('gemSection.topPrediction', 'Top Prediction')}:</PredictionLabel>
                <PredictionValue>
                  ${gem.topPrediction.value.toLocaleString()}
                  <small> by {gem.topPrediction.username}</small>
                </PredictionValue>
              </PredictionSection>
              <PredictionSection>
                <PredictionLabel>{t('gemSection.avgPrediction', 'Avg Prediction')}:</PredictionLabel>
                <PredictionValue>${gem.averagePrediction.toLocaleString()}</PredictionValue>
              </PredictionSection>
              <TagSection>
                {gem.tags.map(tag => (
                  <span key={tag} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f0f0f0', padding: '2px 4px', borderRadius: '4px', marginRight: '4px' }}>
                    <Tag size={10} style={{ marginRight: '4px' }} />
                    {tag}
                  </span>
                ))}
              </TagSection>
            </GemCardBody>
            <GemCardFooter>
              <FooterContentWrapper>
                <CreatedAt>
                  {t('gemSection.created', 'Created')}: {new Date(gem.createdAt).toLocaleDateString()}
                </CreatedAt>
                <WatchButton 
                  onClick={() => handleWatch(gem.id)}
                  iswatched={watchlist.includes(gem.id).toString()}
                >
                  <Eye size={16} style={{ marginRight: '4px' }} />
                  {watchlist.includes(gem.id) ? t('gemSection.unwatch', 'Unwatch') : t('gemSection.watch', 'Watch')} ({gem.watchers})
                </WatchButton>
              </FooterContentWrapper>
            </GemCardFooter>
          </GemCard>
        ))}
      </GemGrid>
    </Section>
  );
};



export default GemSection;