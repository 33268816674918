export function formatDate(date: Date | string | number | null | undefined, source: 'createdAt' | 'prediction' = 'createdAt'): string {
  if (!date) return 'N/A';

  const dateObject = new Date(date);
  
  if (isNaN(dateObject.getTime())) return 'Invalid Date';

  const day = dateObject.getDate().toString().padStart(2, '0');
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // +1 because months are 0-indexed
  const year = dateObject.getFullYear().toString().slice(-2);
  
  let hours = dateObject.getHours();
  const minutes = dateObject.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';
  
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const formattedHours = hours.toString().padStart(2, '0');

  
  return source === 'createdAt' ? `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}` : `${day}/${month}/${year}`;
}