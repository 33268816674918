import { addToWatchlist, removeFromWatchlist } from '../../../api/watchlistApi';
import { Gem, WatchlistItem } from '../types';



// Update the watched gem - unwatch if already watched, watch if not watched
export const updateWatchedGem = async (
  userId: string, 
  watchlist: WatchlistItem[], 
  setWatchlist: React.Dispatch<React.SetStateAction<WatchlistItem[]>>,
  setGems: React.Dispatch<React.SetStateAction<Gem[]>>, 
  gem: Gem, 
  t: any,
  showInfoPopup: (message: string) => void,
  showErrorPopup: (message: string) => void
) => {

  try {
    const isWatched = watchlist.some(item => item.id === gem.id);
    if (isWatched) {
      await removeFromWatchlist(userId, gem.id);
      setWatchlist(prevWatchlist => prevWatchlist.filter(item => item.id !== gem.id));
      setGems(prevGems => prevGems.map(g => 
        g.id === gem.id ? { ...g, watchers: (g.watchers || 0) - 1 } : g
      ));
    } else {
      await addToWatchlist(userId, gem.id);
      setWatchlist(prevWatchlist => [...prevWatchlist, { id: gem.id, symbol: gem.symbol }]);
      setGems(prevGems => prevGems.map(g => 
        g.id === gem.id ? { ...g, watchers: (g.watchers || 0) + 1 } : g
      ));
    }
    showInfoPopup(t('discovery.popup.watchlist.updated'));
  } catch (error) {
    console.error('Error updating watchlist.');
    showErrorPopup(t('discovery.popup.errorUpdatingWatchlist'));
  }
};