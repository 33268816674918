import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ContactWrapper = styled.div`
  max-width: 600px;
  margin: 8rem auto;
  padding: 0 20px;
  text-align: center;
`;

const Header = styled.h1`
  color: #e60000;
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const ContactInfo = styled.p`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
`;

const ContactLink = styled.a`
  color: #e60000;
  text-decoration: none;
  font-weight: bold;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ContactUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ContactWrapper>
      <Header>{t('contactUs.title')}</Header>
      <ContactInfo>{t('contactUs.description')}</ContactInfo>
      <ContactInfo>
        {t('contactUs.email')}: <ContactLink href="mailto:team.trailvis@gmail.com">team.trailvis@gmail.com</ContactLink>
      </ContactInfo>
    </ContactWrapper>
  );
};

export default ContactUs;