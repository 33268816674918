import React from 'react';

import SimpleHeader from '../../components/simpleHeader/SimpleHeader';
import IntroSection from './sections/IntroSection';
import TransitionSection from './sections/TransitionSection';
import GemSection from './sections/GemSection';

import { PageWrapper } from './landingStyle';

const LandingPage: React.FC = () => {
  return (
    <PageWrapper>
      <SimpleHeader dashboard={false} extraMargin={true} />
      <IntroSection />
      <TransitionSection />
      <GemSection />
    </PageWrapper>
  );
};

export default LandingPage;