import axios, { AxiosRequestConfig, Method } from 'axios';

const API_BASE_URL = process.env.REACT_APP_NODE_ENV === 'development' ? 
  process.env.REACT_APP_API_LOCAL_URL : process.env.REACT_APP_API_BASE_URL;

const apiService = {
  request: async <T>(method: Method, url: string, data?: any): Promise<T> => {
    const config: AxiosRequestConfig = {
      method,
      url: `${API_BASE_URL}${url}`,
      data,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-API-Key': process.env.REACT_APP_API_KEY
      },
    };

    const response = await axios(config);
    return response.data;
  },

  get: <T>(url: string): Promise<T> => apiService.request<T>('GET', url),

  post: <T>(url: string, data: any): Promise<T> => apiService.request<T>('POST', url, data),

  put: <T>(url: string, data: any): Promise<T> => apiService.request<T>('PUT', url, data),

  patch: <T>(url: string, data: any): Promise<T> => apiService.request<T>('PATCH', url, data),

  delete: <T>(url: string): Promise<T> => apiService.request<T>('DELETE', url),
};

export default apiService;