import React, { useState } from 'react';
import styled from 'styled-components';
import { RefreshCw } from 'lucide-react';
import apiService from '../../../api/baseApi';



const UpdateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.bitcoinOrange};
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bitcoinOrangeHover};
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;



const ButtonText = styled.span`
  margin-left: 0.5rem;
`;



const UpdatePricesButton: React.FC<{ type: 'crypto' | 'stocks' }> = ({ type }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const url = `/gems/update-prices${type === 'stocks' ? '-stocks' : ''}`;

  const handleUpdatePrices = async () => {
    setIsUpdating(true);
    try {
      await apiService.put(url, {});
    } catch (error) {
      console.error('Failed to update prices:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <UpdateButton onClick={handleUpdatePrices} disabled={isUpdating}>
      <RefreshCw size={16} />
      <ButtonText>
        {isUpdating ? 'Updating...' : `Update ${type}` }
      </ButtonText>
    </UpdateButton>
  );
};



export default UpdatePricesButton;