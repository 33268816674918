import React, { useEffect, useState } from 'react';
import { Button, Text, VStack } from '@chakra-ui/react';
import { PaymentStatusResponse, SuccessModalProps } from './types';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import apiService from '../../api/baseApi';
import * as Styled from './successStyles';



export const SuccessModal: React.FC<SuccessModalProps> = ({ isOpen, onClose, sessionId, onPaymentConfirmed }) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');



  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const response = await apiService.post<PaymentStatusResponse>('/stripe/check-payment-status', { sessionId });
        if (response && response.success) {
          setStatus('success');
          response.email && onPaymentConfirmed(response.email);
        } else {
          setStatus('error');
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setStatus('error');
      }
    };

    isOpen && sessionId && user && !user.isPro && checkPaymentStatus();
  }, [isOpen, sessionId, onPaymentConfirmed, user]);



  if (!isOpen) return null;



  return (
    <Styled.ModalOverlay onClick={onClose}>
      <Styled.ModalContent onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
        <VStack spacing={4} align="stretch">
          {status === 'loading' && (
            <>
              <Styled.SpinnerContainer>
                <Styled.SpinnerRing />
              </Styled.SpinnerContainer>
              <Text textAlign="center">{t('paymentModal.verifying')}</Text>
            </>
          )}
          {status === 'success' && (
            <>
              <Text fontSize="2rem" fontWeight="bold" textAlign="center">
                {t('paymentModal.success')}
                <span style={{ color: 'green', marginLeft: '8px' }}>✓</span>
              </Text>
              <Text fontSize="1.1rem" textAlign="center">{t('paymentModal.thankYou')}</Text>
              <Text fontSize="1.1rem" textAlign="center">{t('paymentModal.getStarted')} 🚀</Text>
            </>
          )}
          {status === 'error' && (
            <>
              <Text fontWeight="bold">{t('paymentModal.failed')}</Text>
              <Text>{t('paymentModal.failedMessage')}</Text>
            </>
          )}
        </VStack>
        <Styled.ButtonContainer>
          <Button onClick={onClose}>{t('paymentModal.close')}</Button>
        </Styled.ButtonContainer>
      </Styled.ModalContent>
    </Styled.ModalOverlay>
  );
};