import styled from 'styled-components';
import theme from '../../../styles/theme';

export const Section = styled.section`
  padding: 3rem;
  background-color: #f8f9fa;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border: 2px solid ${theme.colors.lightRed};

  @media (max-width: 768px) {
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
`;

export const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-align: center;
  color: ${theme.colors.lightRed};
  border: 1px dotted ${theme.colors.lightRed};
  border-radius: 6px;
  padding: 0.5rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const GemGrid = styled.div<{ setwidth?: boolean }>`
  display: grid;
  grid-template-columns: ${props => props.setwidth === false ? 'repeat(3, 1fr)' : 'repeat(auto-fill, minmax(300px, 1fr))'};
  gap: 2rem;
  align-items: stretch;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 768px) {
    margin-top: 1.5rem;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const GemCard = styled.div<{ iswatched?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: ${props => props.iswatched ? '2px solid #FFD700' : '1px solid #E0E0E0'};
  min-width: 350px;
  max-width: 400px;
  max-height: 450px;
  height: 100%;

  ${props => props.iswatched && `
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.4);
  `}

  &:hover {
    box-shadow: ${props => props.iswatched 
      ? '0 10px 20px rgba(255, 215, 0, 0.3)' 
      : '0 10px 20px rgba(0, 0, 0, 0.1)'};
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const GemCardHeader = styled.div`
  background-color: ${props => props.theme.colors.primaryPinkHover};
  color: 4a4a4a;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const GemRank = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
`;

export const GemName = styled.h3`
  margin: 0;
  font-size: 1.1rem;
  text-align: center;
  flex: 1;
  font-weight: 600;
`;

export const GemSymbol = styled.span`
  font-size: 1rem;
  font-weight: 600;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
`;

export const GemCardBody = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
`;

export const VotingSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const VoteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7f8c8d;
  transition: color 0.3s ease;

  &:hover {
    color: #34495e;
  }
`;

export const VoteCount = styled.span`
  font-size: 0.9rem;
  margin-top: 0.2rem;
`;

export const PredictionSection = styled.div`
  margin-bottom: 1rem;
`;

export const PredictionLabel = styled.span`
  font-size: 0.9rem;
  color: #7f8c8d;
`;

export const PredictionValue = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: #2c3e50;

  small {
    font-size: 0.8rem;
    font-weight: normal;
    color: #7f8c8d;
  }
`;

export const TagSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const GemCardFooter = styled.div`
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e0e0e0;
  margin-top: auto;
`;

export const WatchButton = styled.button<{ iswatched: string }>`
  background-color: ${props => props.iswatched === 'true' ? '#ff6b6b' : '#7bed9f'};
  color: #2c3e50;
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  height: 28px;
  white-space: nowrap;

  &:hover {
    background-color: ${props => props.iswatched === 'true' ? '#ff4757' : '#2ed573'};
  }

  svg {
    margin-right: 4px;
  }
`;

export const CreatedAt = styled.span`
  font-size: 0.8rem;
  color: #7f8c8d;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
`;

export const FooterContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
`;