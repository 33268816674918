import React, { useState } from 'react';
import styled from 'styled-components';
import { FaUser } from 'react-icons/fa';
import AuthModal from './AuthModal';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { Spinner } from './styles/authModalStyles';
import theme from '../../styles/theme';



const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;



export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.darkRed};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  &:hover {
    background-color: ${theme.colors.darkRedHover};
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  }

  svg {
    padding: 0.15rem;
  }
`;



const IconWrapper = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;



interface AuthButtonProps {
  source?: string;
}



const AuthButton: React.FC<AuthButtonProps> = ({ source }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, loading } = useAuth();



  const handleAuthClick = () => {
    setIsModalOpen(true);
  };



  return (
    <ButtonGroup>
      <StyledButton onClick={handleAuthClick}>
        <IconWrapper>
          <FaUser />
        </IconWrapper>
        {!user && !loading && t('auth.login')}
        {loading && <Spinner />}
      </StyledButton>
      {isModalOpen && 
        <AuthModal 
        source={source} 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        />
      }
    </ButtonGroup>
  );
};



export default AuthButton;