import { Gem } from "../types";
import { formatDate } from "../utils";

import { LoadingPrediction, 
  LoadingSpinner, 
  TopPredictionSection, 
  TopPredictionLabel, 
  TopPredictionValue, 
  PredictionPrice, 
  PredictionUsername,
  PredictionSection,
  PredictionLabel,
  PredictionValue,
 } from '../discoveryPageStyles';
import { PredictionDate } from '../gemPage/gemPageStyles';

export const renderTopPrediction = (t: any, isLoading: boolean, gem: Gem) => {
  if (isLoading) {
    return (
      <LoadingPrediction>
        <LoadingSpinner />
      </LoadingPrediction>
    );
  }

  if (gem.topVotedPrediction && gem.averagePrediction !== 0) {
    return (
      <TopPredictionSection style={{ marginTop: '0.5rem' }}>
        <TopPredictionLabel>{t('discovery.topPrediction')}:</TopPredictionLabel>
        <TopPredictionValue>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <PredictionPrice>
                ${gem.topVotedPrediction?.value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}
              </PredictionPrice>
              <PredictionUsername style={{ marginLeft: '0.5rem' }}>
                {gem.topVotedPrediction?.credit ? `@ ${gem.topVotedPrediction.credit}` : `${gem.topVotedPrediction?.username}`}
              </PredictionUsername>
            </div>
            <PredictionDate>
              {formatDate(new Date(gem.topVotedPrediction.startTime), 'prediction')}
              {' - '}
              {formatDate(new Date(gem.topVotedPrediction.endTime), 'prediction')}
            </PredictionDate>
          </div>

        </TopPredictionValue>
      </TopPredictionSection>
    );
  }

  return (
    <PredictionSection style={{ marginBottom: '0.5rem' }}>
      <PredictionLabel>
        {t('discovery.topPrediction')}
      </PredictionLabel>
      <PredictionValue style={{ fontSize: '0.95rem' }}>
        {t('discovery.topPredictionEmpty')}
      </PredictionValue>
  </PredictionSection>
  );
};