import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { followUser, unfollowUser } from '../api/followingApi';
import SHA256 from 'crypto-js/sha256';
import { FollowType } from '../pages/discovery/types';
import apiService from '../api/baseApi';

interface User {
  id: string;
  email: string;
  isPro: boolean;
  isAdmin: boolean;
  username: string;
  points?: number;
}

interface AuthContextType {
  user: User | null;
  loading: boolean;
  authState: AuthState;
  following: FollowType[];
  setFollowing: React.Dispatch<React.SetStateAction<FollowType[]>>;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, username: string) => Promise<void>;
  logout: () => void;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  checkAuth: () => Promise<void>;
  handleFollowChange: (followedUser: FollowType) => Promise<void>;
  updateUser: () => Promise<void>;
}

interface LoginResponse {
  headers: {
    authorization: string;
  };
  userId: string;
  isPro: boolean;
  isAdmin: boolean;
  username: string;
  followingObject: {
    following: FollowType[];
  };
}

interface RegisterResponse {
  message: string;
  user: User;
}

interface AuthState {
  isAuthenticated: boolean;
  user: any | null;
  loading: boolean;
}



const AuthContext = createContext<AuthContextType | undefined>(undefined);
const API_BASE_URL = process.env.NODE_ENV === 'development' ? 
  process.env.REACT_APP_API_LOCAL_URL + '/auth' : process.env.REACT_APP_API_BASE_URL + '/auth';

    

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [following, setFollowing] = useState<FollowType[]>([]);
  const logoutRef = useRef<() => void>(() => {});
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    user: null,
    loading: true,
  });



  const encryptPassword = (password: string): string => {
    //const salt = process.env.REACT_APP_PASSWORD_SALT || 'default-salt';
    const salt = 'default-salt';
    return SHA256(password + salt).toString();
  };



  // CHECK AUTH STATE
  const checkAuth = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/verify-token`, {
        headers: {
          'Content-Type': 'application/json',
          'X-API-Key': process.env.REACT_APP_API_KEY
        },
        withCredentials: true
      });
      const newUser = response.data.user || null;
      const newFollowing = response.data.followingObject.following;

      setAuthState({
        isAuthenticated: response.data.isAuthenticated,
        user: response.data.user || null,
        loading: false,
      });

      // Updating objects
      setUser(newUser);
      setFollowing(newFollowing);

    } catch (error) {
      console.error('[checkAuth] Error during authentication check.');
      setAuthState({
        isAuthenticated: false,
        user: null,
        loading: false,
      });
      setUser(null);
      setFollowing([]);

    } finally {
      setAuthState(prev => ({ ...prev, loading: false }));
      setLoading(false);
    }
  }, []);



  // LOGOUT
  const logout = useCallback(async () => {
    setLoading(true);

    try {
      await apiService.post('/auth/logout', { userId: user?.id });
    } catch (error) {
      console.error('Error during logout.');
    }
    localStorage.removeItem('userEmailLoggedIn');
    localStorage.removeItem('userPwLoggedIn');
    setUser(null);
    setAuthState({
      isAuthenticated: false,
      user: null,
      loading: false,
    });
    setFollowing([]);
    setLoading(false);
  }, [user]);



  // LOGIN
  const login = useCallback(async (email: string, password: string): Promise<void> => {
    setLoading(true);
    try {
      const encryptedPassword = localStorage.getItem('userPwLoggedIn') ? 
      localStorage.getItem('userPwLoggedIn') : encryptPassword(password);
      
      const response = await axios.post<LoginResponse>(`${API_BASE_URL}/login`, 
        { 
          email, 
          password: encryptedPassword 
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-API-Key': process.env.REACT_APP_API_KEY
          },
          withCredentials: true
        }
      );

      const { userId, isPro, isAdmin, username } = response.data;

      const user: User = {
        id: userId,
        email: email,
        isPro: isPro,
        isAdmin: isAdmin,
        username: username
      };

      if (!(user && user.id && user.email && user.isPro !== undefined && user.isAdmin !== undefined && user.username !== '')) { 
        console.warn('[login] User object is incomplete, logging out');
        logout();
        throw new Error('Incomplete user data');
      }

      // Save user email and password to local storage
      if (user.email && encryptedPassword) {
        localStorage.setItem('userEmailLoggedIn', user.email);
        localStorage.setItem('userPwLoggedIn', encryptedPassword);
      }

      setUser(user);
      setAuthState({
        isAuthenticated: true,
        user: { id: response.data.userId },
        loading: false,
      });
      
      if (response.data.followingObject && Array.isArray(response.data.followingObject.following)) {
        setFollowing(response.data.followingObject.following);
      } else {
        console.warn('[login] Following-data is missing or invalid');
        setFollowing([]);
      }

    } catch (error) {
      console.error('[login] Login failed:', error);
      console.log('[login] Resetting user to null');
      setUser(null);
      throw error;
    } finally {
      console.log('[login] Setting loading to false');
      setLoading(false);
    }
  }, [logout]);



  // REGISTER
  const register = async (email: string, password: string, username: string): Promise<void> => {
    setLoading(true);
    try {
      const encryptedPassword = encryptPassword(password);
      const response = await apiService.post<RegisterResponse>('/auth/register', {
        email,
        password: encryptedPassword,
        username
      });
      if (response.user) {
        const { user } = response;
        setUser(user);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          switch (error.response.status) {
            case 409:
              throw new Error('USER_EXISTS');
            case 400:
              throw new Error('MISSING_FIELDS');
            case 500:
              throw new Error('DB_ERROR');
            default:
              throw new Error('REGISTRATION_FAILED');
          }
        } else if (error.request) {
          throw new Error('NETWORK_ERROR');
        } else {
          throw new Error('UNKNOWN_ERROR');
        }
      } else {
        throw new Error('UNKNOWN_ERROR');
      }
    } finally {
      setLoading(false);
    }
  };



  // CHANGE PASSWORD
  const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    setLoading(true);
    try {
      const encryptedOldPassword = encryptPassword(oldPassword);
      const encryptedNewPassword = encryptPassword(newPassword);
      await axios.post<{ message: string }>(
        `${API_BASE_URL}/change-password`,
        {
          email: user?.email,
          oldPassword: encryptedOldPassword,
          newPassword: encryptedNewPassword
        }
      );
    } catch (error) {
      if (error instanceof Error) {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    logoutRef.current = logout;
  }, [logout]);



  // HANDLE FOLLOW USER CHANGE
  const handleFollowChange = async (followedUser: FollowType): Promise<void> => {
    if (followedUser.userId === '' || !user) {
      return;
    }

    try {
      const isFollowing = following.some((follow) => follow.userId === followedUser.userId);
      if (isFollowing) {
        await unfollowUser(user.id, followedUser.username);
        setUser((prevUser) => {
          if (prevUser) {
            const newFollowing = following.filter((follow) => follow.userId !== followedUser.userId);
            setFollowing(newFollowing);
            return {
              ...prevUser,
              following: newFollowing,
            };
          }
          return null;
        });
      } else {
        await followUser(user.id, followedUser.username);
        setUser((prevUser) => {
          if (prevUser) {
            const newFollowing = [...following, followedUser];
            setFollowing(newFollowing);
            return {
              ...prevUser,
              following: newFollowing,
            };
          }
          return null;
        });
      }
    } catch (error) {
      console.error('Error following/unfollowing user.');
    }
  };



  // UPDATE USER
  const updateUser = useCallback(async () => {
    try {
      const userEmail = localStorage.getItem('userEmailLoggedIn');
      const pw = localStorage.getItem('userPwLoggedIn');
      logout();
      if (userEmail && pw) {
        login(userEmail, pw).then(() => checkAuth());
      }
    } catch (error) {
      console.error('Error fetching user data.');
    }
  }, [checkAuth, login, logout]);



  useEffect(() => {
    checkAuth();
  }, [checkAuth]);



  const value = {
    user,
    loading,
    authState,
    following,
    setFollowing,
    setUser,
    setLoading,
    login,
    register,
    logout,
    changePassword,
    checkAuth,
    handleFollowChange,
    updateUser
  };



  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};