import React from 'react';
import { useTranslation } from 'react-i18next';

import { FooterWrapper, 
  FooterContent, 
  CompanyInfoSection, 
  LinksSection, 
  LanguageSection, 
  LanguageWrapper, 
  LanguageSelect, 
  FooterLink} from './footerStyles';

const Footer: React.FC = React.memo(() => {
  const { t, i18n } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContent>
        <CompanyInfoSection>
          <span>{t('footer.companyInfo.copyright')}</span>
        </CompanyInfoSection>
        <LinksSection>
          <FooterLink href="/contact">{t('footer.links.contact')}</FooterLink>
        </LinksSection>
        <LanguageSection>
          <LanguageWrapper>
            <span>{t('footer.language')}</span>
            <LanguageSelect
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
            >
              <option value="en">English</option>
              <option value="nb">Norsk</option>
            </LanguageSelect>
          </LanguageWrapper>
        </LanguageSection>
      </FooterContent>
    </FooterWrapper>
  );
});

Footer.displayName = 'Footer';

export default Footer;