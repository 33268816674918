import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const PremiumFeature: React.FC = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPremiumContent = async () => {
      try {
        // Simulate loading premium content
        await new Promise(resolve => setTimeout(resolve, 100));
        setLoading(false);
      } catch (err) {
        setError('Failed to load premium content');
        setLoading(false);
      }
    };

    loadPremiumContent();
  }, []);

  if (loading) {
    return <div>Loading premium content...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (user && !user.isPro) {
    return <div>User not pro</div>;
  }

  return (
    <div style={{margin: "2rem 0 0 1rem", paddingTop: "40px"}}>
      <h1>Premium Feature</h1>
      {user && <p>Welcome, {user.email}!</p>}
      {/* Rest of your premium content */}
    </div>
  );
};

export default PremiumFeature;