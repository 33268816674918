import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { useAuth } from '../../contexts/AuthContext';
import apiService from '../../api/baseApi';



const PointsWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.primaryYellow};
  color: #333;
  border: 1px solid black;
  border-radius: 6px;
  padding: 0.3rem 1rem;
`;



const PointsText = styled.span`
  font-family: 'Courier New', Courier, monospace;
  font-size: 1.1rem;
  gap: 0.5rem;
  font-weight: 900;
`;



const PointsDisplay: React.FC = () => {
  const { user } = useAuth();
  const [points, setPoints] = useState(0);



  useEffect(() => {
    const fetchPoints = async () => {
      try {
        const response = await apiService.get<{ points: number }>(`/points/${user?.id}`);
        setPoints(response.points);
      } catch (error) {
        console.error('Error fetching user points:', error);
      }
    };
    fetchPoints();
  }, [user?.id]);


  
  return (
    <PointsWrapper>
      <PointsText>
        {points.toString().padStart(3, '0')} PTS
      </PointsText>
    </PointsWrapper>
  );
};



export default PointsDisplay;