import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import AppRouter from './routes/router';
import { AuthProvider } from './contexts/AuthContext';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/config';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

function App() {
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <Router>
          <AppWrapper>
            <ContentWrapper>
            <AppRouter />
          </ContentWrapper>
        </AppWrapper>
        </Router>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;