import * as Styled from '../discoveryPageStyles';

export const GemCardSkeleton = () => (
  <Styled.GemCard>
    <Styled.GemCardHeader>
      <Styled.GemRank>#--</Styled.GemRank>
      <Styled.GemName>Loading...</Styled.GemName>
      <Styled.GemSymbol>---</Styled.GemSymbol>
    </Styled.GemCardHeader>
    <Styled.GemCardBody>
      <div style={{ height: '100px', background: '#f0f0f0' }}></div>
    </Styled.GemCardBody>
    <Styled.GemCardFooter>
      <Styled.CreatedAt>Loading...</Styled.CreatedAt>
      <Styled.WatchButton disabled iswatched='false'>Watch (--)</Styled.WatchButton>
    </Styled.GemCardFooter>
  </Styled.GemCard>
);